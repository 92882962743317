import React, { Component } from 'react'
import styled from 'styled-components'
import ic_add_blue from './../../../../../images/ic_add_blue.png'
/// data location
import {location} from '../../../../../data/singleChoiseData' 
const Bound = styled.button`
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    cursor: pointer;
    outline: none;
`

export default class ButtonAdd extends Component {
    constructor(props) {
        super(props);
        this.state={
            data: location
        }
    }
    
    render() {
        // console.log(this.state.data);
        
        return (
            <Bound onClick={()=>this.props.showAddTransactionRoomPopup(this.state.data)}>
                <img src={ic_add_blue} alt="ic_add_blue" />
            </Bound>
        )
    }
}
