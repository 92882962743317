import React, { Component } from 'react';
import styled from 'styled-components';

const Bound =styled.div`
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
    }
`

export default class ActionControlCheckBio extends Component {


    acceptActions = () => {
        this.props.onSubmit()
    }

    render() {
        return (
            <Bound>
                <img 
                onClick={()=>this.acceptActions}
                src={this.props.icon} alt='icon'></img>
            </Bound>
        )
    }
}
