import React, { Component } from 'react'
import styled from 'styled-components'
import { dataBiometric, dataType } from '../../../../data/dataBiometric'
import PagningControl from '../Control/PagningControl'
import { async } from 'q';
const Bound = styled.div`
     margin-top: 15px;
    height: 100%;
    .block{
        display: grid;
        grid-template-columns: repeat(8,1fr);
        align-items: center;
    }
    .item-row{
        height:48px;
        background:#ffffff;
        border-radius:4px;
        margin-bottom:4px;

    }
    .bio-head{
        height: 48px;
        font-family: SVN-Gilroy;
        font-size: 11px;
        font-weight: bold;
        line-height: 1.26;
        color: #005993;
    }
    .bio-body{
        overflow: auto;
        height: 100%;
        &::-webkit-scrollbar {
            width: 0px;
        }
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color: #222222;
    }

    .first{
        padding-left: 20px;
    }
    .last{
        text-align: right;
    }
    .item-last{
        text-align:center;
        
    }
    .isLookFalse{
        color: #005993;
        text-decoration: underline;
        cursor: pointer;
    }
    .isLookTrue{
        color: #c8c8c8;
    }
    .name-custom{
        font-weight: bold;
    }
`
const limitPerPage = 8
export default class BiometricTable extends Component {
    onPageChange = (page) => {
        let currentPage = page

    }
  showInfo = (item) => {
      let objUser
      dataType.forEach(d=>{
        if(d.status===item.dataStatus){
            objUser={...item,status:d.type}
        }
    })
    //   console.log(item,objUser);

     this.props.showPopupCheckBiometric();
  }
    render() {
        return (
            <Bound>
                <div className="bio-head block">
                    <div className="cif-code first">MÃ SỐ CIF</div>
                    <div className="name-head">TÊN KHÁCH HÀNG</div>
                    <div className="type-custom">LOẠI KHÁCH HÀNG</div>
                    <div className="registor-gdv">GDV ĐĂNG KÝ</div>
                    <div className="type-data">LOẠI DỮ LIỆU</div>
                    <div className="time-update">THỜI GIAN ĐĂNG KÝ</div>
                    <div className="time-approve">THỜI HẠN PHÊ DUYỆT</div>
                    <div className="control-page last">
                        <PagningControl currentPage={1}
                            onChangePage={this.onPageChange}
                            limitPerPage={limitPerPage}
                            totalItem={dataBiometric.length} />
                    </div>
                </div>
                <div className="bio-body">
                    {dataBiometric.map((item, index) => {
                        let elmDataStatus, colorStatus, elmIsLook,colorTimeApprove
                        dataType.forEach(d => {
                            if (d.type === item.dataStatus) {
                                elmDataStatus = d.status
                                colorStatus = d.color
                            }
                        })
                        if(item.isLook){
                            elmIsLook='Đã phê duyệt'
                        }else{
                            elmIsLook='Xem thông tin'
                        }
                        let pup = item.timeApprove.slice(0,2)
                        if(parseInt(pup)===0){
                            colorTimeApprove='#f31919'
                        }else{
                            colorTimeApprove='unset'
                        }
                        return (
                            <div className='item-row block' key={index}>
                                <div className="cif-code first">{item.CIFcode}</div>
                                <div className="name-custom">{item.name}</div>
                                <div className="type-custom">{item.typeCustom}</div>
                                <div className="registor-gdv">{item.registorGDV}</div>
                                <div className="satus-data" style={{ color: colorStatus?colorStatus: 'unset' }}>{elmDataStatus}</div>
                                <div className="time-update">{item.timeUpdate}</div>
                                <div style={{color:colorTimeApprove}}>{item.timeApprove}</div>
                                <div className={item.isLook?"item-last isLookTrue":"item-last isLookFalse"}
                                onClick = {item.isLook?null:() =>this.showInfo(item)}
                                >{elmIsLook}</div>
                            </div>
                        )
                    })}
                </div>
              

            </Bound>
        )
    }
}
