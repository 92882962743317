import React from 'react'
import Lottie from 'react-lottie'

// import animation
import loadingAnimation from "../../../../helper/loadingAnimation.json";

/**
 * 
 * @param {string="250px"} size size of icon loading(width === height === size)
 * @param {boolean=true} loadingPage true if loading fullscreen, else loading element
 */
export default function LoadingControl({size = "250px", loadingPage = true}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    loadingPage?
      <div 
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 999999999,
          background: "rgb(0,0,0,0.4)"
        }}
      >
        <Lottie
          width={size}
          height={size}
          options={defaultOptions}
          isClickToPauseDisabled={true}
          style={{
            position: "absolute", 
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        />
      </div>
      :
      <Lottie
        width={size}
        height={size}
        options={defaultOptions}
        isClickToPauseDisabled={true}
        style={{
          display: "inline-block",
          margin: 0
        }}
      />
  )
}
