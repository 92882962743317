import Axios from 'axios';

import * as types from './types';
import { host } from "../host";
// import { getInfoImageOrVideo } from '../tools';

// api
const apiCreateCounter = host + "admin/counter/create";
const apiUpdateCounter = host + "admin/counter/update";
const apiGetCounterByOffice = host + "admin/counter/get-counter-by-office";
const apiDeleteCounter = host + "admin/counter/delete";
const apiGenDesktopKeys = host + "admin/counter/generate-desktop-keys";
const apiGenTabletKeys = host + "admin/counter/generate-tablet-keys";
const apiGetListTypeCounterServices = host + "admin/counter-services/getAll"

/**
 * create counter
 * @param {{
 * OfficeID*: string,
 * CounterNum*: string,
 * ServAllService*: boolean, - check serve all service, if this field is false, it's must be removed in object
 * IsVIP: boolean, - set priority counters
 * ServiceList*: array[string], - list id of services, if ServAllService === true, this field was remove in object
 * CounterName*: string
 * }} dataCreate data used to create counter
 */
export const createCounter = (dataCreate) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    ...dataCreate
  })

  try {
    const res = await Axios.post(apiCreateCounter, queryDataFormat);

    console.log(res);

    // const {data} = res;
    dispatch({
      type: types.CREATE_COUNTER      
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.CREATE_COUNTER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {{
 * CounterID*: string,
 * OfficeID*: string,
 * CounterNum: string,
 * ServAllService: boolean,
 * ServiceList: array[string],
 * CounterName: string
 * }} dataUpdate 
 */
export const updateCounter = (dataUpdate) => async dispatch => {
  const queryFormatData = JSON.stringify({
    ...dataUpdate
  })

  try {
    const res = await Axios.post(apiUpdateCounter, queryFormatData);

    console.log(res);
    dispatch({
      type: types.UPDATE_COUNTER
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.UPDATE_COUNTER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} officeId id of office
 * @param {number*} pageSize size of a page
 * @param {number*} pageNumber number of page need load
 * @param {boolean} isLoad re-render if true 
 */
export const getCounterByOffice = (officeId, pageSize, pageNumber, isLoad=true) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    OfficeID: officeId,
    PageSize: pageSize,
    PageNumber: pageNumber
  })
  
  // check listCounter is null
  if(!isLoad && !pageSize){
    dispatch({
      type: types.GET_LIST_COUNTER_NUMBER,
      listCounterNumber: []
    })
    return;
  }

  try {
    const res = await Axios.post(apiGetCounterByOffice, queryDataFormat);

    console.log(res);
    const {data} = res;
    if(isLoad)
      dispatch({
        type: types.GET_COUNTER_BY_OFFICE,
        listCounter: data.ListCounter ? data.ListCounter : [],
        total: data.Total,
      })
    else
      dispatch({
        type: types.GET_LIST_COUNTER_NUMBER,
        listCounterNumber: data.ListCounter.map(i => i.CounterNumber)
      })
  }
  catch(err){
    console.log(err);
    if (isLoad)
      dispatch({
        type: types.GET_COUNTER_BY_OFFICE_ERR,
        err
      })
    else
      dispatch({
        type: types.GET_LIST_COUNTER_NUMBER_ERR,
        err
      })
  }
}

/**
 * 
 * @param {string*} counterId id of counter
 */
export const deleteCounter = (counterId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    CounterID: counterId
  })

  try {
    const res = await Axios.post(apiDeleteCounter, queryDataFormat);
    console.log(res);

    dispatch({
      type: types.DELETE_COUNTER
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.DELETE_COUNTER_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} counterId id of counter need generate key
 * @param {string*} officeId if of office which have counter need generate key
 */
export const genDesktopKey = (counterId, officeId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    CounterID: counterId,
    OfficeID: officeId
  })

  try {
    const res = await Axios.post(apiGenDesktopKeys, queryDataFormat);

    console.log(res);

    const {data} = res;
    dispatch({
      type: types.GEN_DESKTOP_KEY,
      data
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.GEN_DESKTOP_KEY_ERR,
      err
    })
  }
}

/**
 *
 * @param {string*} counterId id of counter need generate key
 * @param {string*} officeId if of office which have counter need generate key
 */
export const genTabletKey = (counterId, officeId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    CounterID: counterId,
    OfficeID: officeId
  })

  try {
    const res = await Axios.post(apiGenTabletKeys, queryDataFormat);

    console.log(res);

    const { data } = res;
    dispatch({
      type: types.GEN_TABLET_KEY,
      data
    })
  }
  catch (err) {
    console.log(err);
    dispatch({
      type: types.GEN_TABLET_KEY_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} userId id of user was loged in to web
 */
export const getListTypeCounterServices = (userId) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    UID: userId
  })

  try {
    const res = await Axios.post(apiGetListTypeCounterServices, queryDataFormat);

    console.log(res);
    const {data} = res;

    dispatch({
      type: types.GET_LIST_TYPE_COUNTER_SERVICES,
      listService: data.ListService ? data.ListService: []
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.GET_LIST_TYPE_COUNTER_SERVICES_ERR,
      err
    })
  }
}