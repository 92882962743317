import React, { Component } from 'react'
import styled from 'styled-components'
const Bound = styled.div`
    display:flex;
    /* flex: 1; */
    margin: 24px 0 0 0;
    .core-name{
        h2{
            font-family: "SVN-Gilroy";
            font-size: 24px;
            font-weight: bold;
            line-height: 1.08;
            color: #005993;
        }
        p{
            font-family: "SVN-Gilroy";
            font-size: 12px;
            font-weight: 500;
            line-height: 1.42;
            color: #222222;
            margin-top:5px;
            text-transform: inherit;
        }
    }
`

export default class BlockNameControl extends Component {
    render() {
        return (
            <Bound>
                <div className="core-name">
                    <h2>{this.props.mainname}</h2>
                    <p>{this.props.subname}</p>
                </div>
            </Bound>
        )
    }
}
