import React, { Component } from 'react';
import styled from "styled-components";
import { dataCustomerAccurate, dataType } from '../../../../data/dataBiometric'
import PagningControl from '../Control/PagningControl'
const Bound = styled.div`
    font-family: SVN-Gilroy;
    font-size: 14px;
    height: 100%;
   .block{
        display: grid;
        grid-template-columns: repeat(8,1fr);
        align-items: center;
   }
   .table-head{
    color: #005993;
    font-size: 11px;
    font-weight: bold;
    height: 48px;
   }
   .table-body{
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
            width: 0px;
        }
   }
   .item-row{
        height: 48px;
        border-radius: 4px;
        background-color: #ffffff;
        margin-bottom: 4px;
   }
   .first{
    padding-left: 20px;
   }
   .name-custom{
        font-weight: bold;
    }
    .isLookFalse{
        color: #005993;
        text-decoration: underline;
        cursor: pointer;
    }
    .isLookTrue{
        color: #c8c8c8;
    }
    .item-last{
        padding-right: 14px;
        text-align: right;
    }
    .last{
        text-align: right;
    }
    .time-approve{
       color: #c8c8c8;
    }
`
const limitPerPage = 8
class CustomerTable extends Component {
    onPageChange = (page) => {
        let currentPage = page

    }
    showInfo = item =>{
        this.props.showPopupCheckCustomer();
    }
    render() {
        return (
            <Bound>
                <div className="table-head block">
                    <div className="cif-code first">MÃ SỐ CIF</div>
                    <div className="name-head">TÊN KHÁCH HÀNG</div>
                    <div className="type-custom">LOẠI KHÁCH HÀNG</div>
                    <div className="registor-gdv">GDV XÁC THỰC</div>
                    <div className="type-data">LOẠI DỮ LIỆU</div>
                    <div className="time-update">THỜI GIAN XÁC THỰC</div>
                    <div >THỜI HẠN PHÊ DUYỆT</div>
                    <div className="control-page last">
                        <PagningControl currentPage={1}
                        onChangePage={this.onPageChange}
                        limitPerPage={limitPerPage}
                        totalItem={dataCustomerAccurate.length}
                        />
                    </div>
                </div>
                <div className="table-body">
                    {dataCustomerAccurate.map((item, index) => {
                        let elmDataStatus, colorStatus, elmIsLook, colorTimeApprove
                        dataType.forEach(d => {
                            if (d.type === item.dataType) {
                                elmDataStatus = d.status
                                colorStatus = d.color
                            }
                        })
                        if (item.isLook) {
                            elmIsLook = 'Đã xem'
                        } else {
                            elmIsLook = 'Xem thông tin'
                        }
                        let pup = item.timeApprove.slice(0, 2)
                        if (parseInt(pup) === 0) {
                            colorTimeApprove = '#f31919'
                        } else {
                            colorTimeApprove = 'unset'
                        }
                        return (
                            <div className='item-row block' key={index}>
                                <div className="cif-code first">{item.CIFcode}</div>
                                <div className="name-custom">{item.name}</div>
                                <div className="type-custom">{item.typeCustom}</div>
                                <div className="registor-gdv">{item.registorGDV}</div>
                                <div className="satus-data" style={{ color: colorStatus ? colorStatus : 'unset' }}>{elmDataStatus}</div>
                                <div className="time-approve">{item.timeAuth}</div>
                                <div style={{ color: colorTimeApprove }}>{item.timeApprove}</div>
                                <div className={item.isLook ? "item-last isLookTrue" : "item-last isLookFalse"}
                                    onClick={item.isLook ? null : () => this.showInfo(item)}
                                >{elmIsLook}</div>
                            </div>
                        )
                    })}
                </div>
            </Bound>
        );
    }
}

export default CustomerTable;