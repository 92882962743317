import React, { Component } from 'react'
import styled from "styled-components";

// import image
import icClose from "../../../../images/ic_close_gray.svg";
import bgFinger from "../../../../images/bg_finger.svg";

const StylePopupShowBiometricHistory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 528px;
  height: 340px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 10px;

  & > * {
    width: 100%;
  }
  
  & > img {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .popup-header{
    cursor: move;
    box-sizing: border-box;
    background: #005993;
    font-family: 'SVN-Gilroy';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
    color: #ffffff;
    display: flex;
    padding: 11px 10px 9px 20px;
    justify-content: space-between;
    z-index: 0;
    img {
      z-index: 1;
      cursor: pointer;
    }
  }

  .popup-main {
    position: relative;
    flex-grow: 1;

    &-item {
      position: absolute;
      font-family: 'SVN-Gilroy';
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.3;
      color: #ffffff;
      /* opacity: 0.5; */
    }

    .last-modify {
      color: #979797;
      top: 6px;
      left: 20px;
      span {
        color: #ffffff;
      }
    }

    .left-hand {
      bottom: 12px;
      left: 12px;
      opacity: 0.5;
    }

    .right-hand {
      bottom: 12px;
      right: 20px;
      opacity: 0.5;
    }
  }
`;


class PopupShowBiometricHistory extends Component {
  render() {
    return (
      <StylePopupShowBiometricHistory ref={ref => this.popupShowBiometricHistory = ref}>
        <img src={bgFinger} alt="finger"/>
        <div className="popup-header">
          <div className="popup-header-title">Lịch sử dữ liệu</div>
          <img src={icClose} alt="close-popup"/>
        </div>
        <div className="popup-main">
          <div className="popup-main-item last-modify">
            Lần thay đổi gần nhất: <span>16:45 12/06/19</span>
          </div>
          <div className="popup-main-item left-hand">Bàn tay trái</div>
          <div className="popup-main-item right-hand">Bàn tay phải</div>
        </div>
      </StylePopupShowBiometricHistory>
    )
  }

  componentDidMount() {
    this.dragElement(this.popupShowBiometricHistory, ".popup-header")
  }
  

  dragElement = (elmnt, headerClass) => {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    const header = elmnt.querySelector(headerClass)
    if (header) {
      /* if present, the header is where you move the DIV from:*/
      header.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
}

export default PopupShowBiometricHistory;
