import React, { Component } from "react";
import styled from "styled-components";

import { formatDayAndTime } from "../../../tools";

const StyledHeader = styled.div`
  height: 80px;
  background: #fafafa;
  padding: 0 40px 0 20px;
  flex-shrink: 0;
  &,
  & > .right-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  * {
    font-family: "SVN-Gilroy";
    font-weight: bold;
  }

  .title {
    font-size: 32px;
    line-height: 1.29;
    color: #222222;
    margin-left: 20px;
  }

  .right-header > * {
    /* margin-right: 8px; */
  }

  .right-header__title {
    font-size: 12px;
    line-height: 1.42;
    color: #005993;
  }

  .time {
    font-weight: 500;
    width: 100px;
    padding-left: 15px;
  }

  button {
    line-height: 36px;
    font-size: 14px;
    color: #005993;
    height: 36px;
    min-width: 128px;
    text-transform: uppercase;
    border: none;
    outline: none;
    border-radius: 18px;
    background-color: #7ed3f7;
    cursor: pointer;
  }
`;

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: "",
      title: this.props.title
    };

    // get time
    this.getTimeNow = {};
  }

  componentWillMount() {
    this.getTimeNow = setInterval(() => {
      this.setState({ time: formatDayAndTime().getFullTime });
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({ title: nextProps.title });
    }
  }

  render() {
    return (
      <StyledHeader className = 'header'>
        <div className="title">{this.state.title}</div>
        <div className="right-header">
          <div className="right-header__title">Thời gian hệ thống:</div>
          <div className="time">{this.state.time}</div>
          <button>Lưu hệ thống</button>
        </div>
      </StyledHeader>
    );
  }

  componentWillUnmount() {
    clearInterval(this.getTimeNow);
  }
}
