import React, { Component } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';

// Component
import SubHeaderControl from '../Control/SubHeaderControl';
import PagingControl from '../Control/PagningControl';
import EmptyControl from "../Control/EmptyControl";
import DeviceKios from "../Advertisement/DeviceKios";
import DeviceTablet from '../Advertisement/DeviceTablet';
import ShowTicket from '../Advertisement/ShowTicket';
// Data
import { getAds, resetAdsReducer } from '../../../../actions/adsAction';
import { advertisementTabArr, emptyData } from '../../../../data/data'

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:hidden;
    min-width:1260px;
    .title {
        background-color:#fafafa;
        height: 48px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;            
        .choise {
            display:flex;
            align-items:center;
            justify-content:center;
            width:120px;
            height:calc(100% - 3px);
            cursor: pointer;
        }
    }
    .ad-container{
        display: flex;
        flex: 1;
        overflow: hidden;  
        padding: 0 40px;
        flex-direction: column; 
        .title-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                color: #005993;
                font-size: 11px;
                text-transform: uppercase;
                margin: 26px 0 17px 0;
            }
        }
        .item {
            display: flex;
            flex-direction: column;
            overflow:hidden;
            p{
                text-transform: uppercase;
            }
            .scroll{
                overflow:auto;   
                .item-child {
                    display: grid;
                    grid-template-columns: 280px 280px 280px 280px;
                    justify-content: space-evenly;
                    grid-gap: 20px;       
                         
                    ::-webkit-scrollbar { 
                    display: none; 
                    }
                }    
            }
        }
        
    }
`
const limitPerPage = 8
class AdvertisePGDContainer extends Component {
  _isMounted = false;
  state = {
    typeSelected: advertisementTabArr[0].key,
    subHeaderData: advertisementTabArr[0].subHeaderData,
    data: [],
    currentPage: 1,
    startIndex: 0,
    endIndex: 10,
    isAdd: true,
    isCustom: true,
  }
  componentDidMount() {
    this._isMounted = true;
    const { typeSelected } = this.state
    this._isMounted && this.pagnationPage();
    this._isMounted && this.props.getAds(typeSelected, 10, 1)
  }

  setTypeSelected = (tab) => {
    if (tab) {
      const { typeSelected } = this.state
      if (typeSelected !== tab.key) {
        // this.props.resetAdsReducer()
        this.props.getAds(tab.key, 10, 1)
      }
      this.setState({
        typeSelected: tab.key,
        subHeaderData: tab.subHeaderData
      })
    }
  }
  pagnationPage() {
    let endIndex = this.state.currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ startIndex, endIndex })
  }
  onPageChange = (e) => {
    let currentPage = e
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
  }
//   showPopupAddAdvert = () => {
//     const { typeSelected } = this.state
//     switch (typeSelected) {
//       case advertisementTabArr[0].key: {
//         this.props.showAddAdvertisementKioskPopup()
//         break;
//       }
//       case advertisementTabArr[1].key: {
//         this.props.showAddAdvertisementTablePopup()
//         break;
//       }
//       case advertisementTabArr[2].key: {
//         this.props.showAddAdvertisementShowTicketPopup()
//         break;
//       }
//       default:
//         break;
//     }
//   }
  renderTab() {
    return advertisementTabArr.map((tab, i) => {
      return (
        <div className='choise' key={i}
          style={this.state.typeSelected === tab.key ?
            { color: tab.activeColor, borderBottom: tab.avtiveBorderBottom }
            :
            { color: tab.disActiveColor }}
          onClick={() => this.setTypeSelected(tab)}>
          {tab.label}
        </div>
      )
    })
  }


  componentWillUnmount() {
    this._isMounted = false;
  }



  render() {
    const { listAds, totalAds, alert } = this.props.getAdsReducer;
    // console.log('test', alert);        
    let { subHeaderData, startIndex, endIndex, typeSelected } = this.state
    let dataSlice = listAds.slice(startIndex, endIndex)
    return (
      <Bound>
        <div className='title'>
          {this.renderTab()}
        </div>
        <div className='ad-container'>
          <SubHeaderControl
            data={subHeaderData} 
            isAdd={this.state.isAdd}
            searchFilter={(textSearch) => this.props.getAds(this.state.typeSelected, 10, 1, textSearch)}
            />  

          {this._isMounted && listAds && listAds.length > 0
            ? (
              <div className="item">
                <div className='title-wrapper'>
                  <p>danh sách quảng cáo</p>
                  {
                    (totalAds > limitPerPage) ?
                      <PagingControl
                        currentPage={this.state.currentPage}
                        totalItem={totalAds}
                        limitPerPage={limitPerPage}
                        onChangePage={page => this.onPageChange(page)} /> : null
                  }
                </div>
                <div className='scroll'>
                  <div className="item-child">
                    {
                      this._isMounted && dataSlice.map((item, i) => {
                        switch (typeSelected) {
                          case advertisementTabArr[0].key: {
                            return (
                              <DeviceKios
                                isCustom={this.state.isCustom}
                                key={i}
                                keyValue={i}
                                data={item}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          case advertisementTabArr[1].key: {
                            return (
                              <DeviceTablet
                                isCustom={this.state.isCustom}
                                key={i}
                                keyValue={i}
                                data={item}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          case advertisementTabArr[2].key: {
                            return (
                              <ShowTicket
                                isCustom={this.state.isCustom}
                                key={i}
                                keyValue={i}
                                data={item}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }} />
                            )
                          }
                          default:
                            break;
                        }
                        return null;
                      })
                    }
                  </div>
                </div>
              </div>
            )
            : (<EmptyControl
              alertContent={alert}
              content={emptyData.advertisement} />)
          }
        </div>
      </Bound>
    );
  }
}
const mapStateToProps = (state) => ({
  getAdsReducer: state.getAdsReducer
})

const mapDispatchToProps = dispatch => ({
  getAds: (typeAds, pageSize, pageNumber, searchText = undefined) => dispatch(getAds(typeAds, pageSize, pageNumber, searchText)),
  resetAdsReducer: () => dispatch(resetAdsReducer())
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AdvertisePGDContainer);