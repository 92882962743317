
import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
// Img
import ic_close from '../../../images/ic_close_gray.svg';
// Component
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl'
// Data
import {setPermissionSingleChoise} from '../../../data/singleChoiseData'
//Office
import {filterOffice, alertFillterOffice} from '../../../actions/officeAction';
import { getAllOffice } from '../../../actions/officeAction';
//User
import { filterUser  } from '../../../actions/userAction';

const Bound = styled.div `
    width:100%;
    min-height:100vh;
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    .popup-filter-bound{
        display:flex;
        flex-direction:column;
        width:200px;
        padding: 30px 20px 0 20px;
        height: calc(100vh - 60px - 80px - 30px);
        background-color:white;
        position:absolute;
        bottom:0;
        right:-240px;
        transition:right 0.5s;
        align-items:center;
        .div_check{
            width:200px;
            margin: 0 0 20px 0;
        }
        .div_header{
            width:100%;
            margin: 0 0 20px 0;
            display:flex;
            justify-content:space-between;
            align-items: center;
        .title{
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            width: 64px;
            height: 36px;
            border-radius: 18px;
            background-color: #005993;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#ffffff;
            cursor: pointer;
        }
        .close-btn{
            width:20px;
            height:20px;
            cursor: pointer;
        }
        }
    }
`
class PopupPermissionFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idShow: "",
            data: setPermissionSingleChoise,
            AtOffice: '',
            Role: '',
            listOffice: '',
            listRole: ''
        }
    }

    isMouseEnter = false;

    getText = (type) => text => {
        this.setState({[type]: text})
    }
    
    getAtOffice = (type) => text => {
        this.setState({[type]: text})
    }
    // getText = (e) => {
    //     // get Text singleChoiseDropDown
    //     console.log('e', e);
    //     this.setState({

    //     });
    //   }
    
    setIdShow = (id) => {
        this.setState({idShow: id})
    }

    onSubmit = () => {
        const { Role, AtOffice } = this.state
        let dataFilter = {}
        if(Role){
            dataFilter['Role'] = Role.type
            if(Role === 'Giao dịch viên'){
                dataFilter.Role = 'TELLER'
            }else if(Role === 'Quản trị viên PGD'){
                dataFilter.Role = 'OFFADMIN'
            }
        }
        if(AtOffice) {
            dataFilter['AtOffice'] = AtOffice.id
            dataFilter.AtOffice = AtOffice.id
        }
        if(Object.keys(dataFilter).length !== 0) {
            this.props.filterUser(dataFilter, 10, 1)
            this.props.alertContent('')
            this.props.onClose()
        }else{
                this.props.onClose()
                return
        }
    }
    
    componentDidMount() {
        this.props.getAllOffice()
    }

    render() {
        let {listOffice} = this.props.filterOfficeReducer;
        listOffice = listOffice.map((item) => ({
            id: item.ID,
            name: item.Name
        }))
        
        let listRole = this.state.data.rank
        listRole = listRole.map(item => ({
            type: item.type,
            name: item.name
        }))

        const Role = this.getText("Role");
        const atOffice = this.getAtOffice("AtOffice");
        return (
            <Bound
                onMouseDown={() => {
                    if (!this.isMouseEnter) {
                        this.props.onClose()
                    }
                }}>
                <div
                    className='popup-filter-bound'
                    id='filter_permission_id'
                    onMouseEnter={() => {
                    this.isMouseEnter = true
                }}
                    onMouseLeave={() => {
                    this.isMouseEnter = false
                }}>
                    <div className="div_header">
                        <img
                            src={ic_close}
                            alt='close'
                            className='close-btn'
                            onClick={() => {
                            this.props.onClose()
                        }}/>
                        <div onClick={() => this.onSubmit()} className='title'>FILTER</div>
                    </div>
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectRole"
                            getText={Role}
                            data={listRole}
                            title='tài khoản'
                            titleChoise='Chọn loại tài khoản'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div>
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectAtOffice"
                            // getText={this.getAtOffice}
                            getText={atOffice}
                            data={listOffice}
                            title='điểm giao dịch'
                            titleChoise='Chọn điểm giao dịch'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div>                    
                </div>
            </Bound>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filterOfficeReducer: state.filterOfficeReducer
    }
}
const mapDispatchToProps = dispatch => ({
    filterOffice: (dataFilter, pageSize, pageNumber) => dispatch(filterOffice(dataFilter, pageSize, pageNumber)),
    getAllOffice: () => dispatch(getAllOffice()),
    filterUser: (dataFilter, pageSize, pageNumber) => dispatch(filterUser(dataFilter, pageSize, pageNumber)),
    alertContent: () => dispatch(alertFillterOffice()),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PopupPermissionFilter)
