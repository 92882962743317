import React, {Component} from 'react';
import styled from 'styled-components';

const StyleBiometric = styled.div `
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 14px 0 0 0;
  .title {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.26;
    color: #005993;
    text-transform:uppercase;
    margin: 26px 0 14px 0;
  }
  .content {
    font-size: 14px;
    font-weight: 500;
    color: #979797;
    span {
      color:#222222;
    }
    ~.dataNeed {
      margin: 14px 0 0 0;
      span {
        color:#005993;
      }
    }
  }
  .dataType{
      span{
        color: #0fb11f;
      }
    }
    .last {
      margin: 14px 0 0 0;
    }
`

export default class DataInfoCheckBiometric extends Component {
    render() {
        return (
            <StyleBiometric>
                <p className='title'>thông tin dữ liệu</p>
                <div className="container">
                    <div className="row">
                        <div className="col-7">
                            <div className="row content dataType">
                                <p>Loại dữ liệu:&nbsp;
                                    <span>Đăng ký mới dữ liệu sinh trắc học</span>
                                </p>
                                {/* <p>Loại dữ liệu: <span>{this.props.dataType}</span></p> */}
                            </div>
                            <div className="row content dataNeed">
                                <p>Dữ liệu yêu cầu:&nbsp;
                                    <span>
                                        Khuôn mặt & vân tay
                                    </span>
                                </p>
                                {/* <p>Dữ liệu yêu cầu: <span> {this.props.dataBiometric} </span></p> */}
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="row content">
                                <p>GDV yêu cầu:&nbsp;
                                    <span>Võ Thị Lan Hương
                                    </span>
                                </p>
                                {/* <p>GDV yêu cầu: <span>{this.props.registorGDV} </span></p> */}
                            </div>
                            <div className="row content last">
                                <p>Thời gian:&nbsp;
                                    <span>08:45 18/06/19
                                    </span>
                                </p>
                                {/* <p>Thời gian: <span>{this.props.timeApprove} </span></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </StyleBiometric>
        )
    }
}
