import React, { Component } from 'react';
import styled from 'styled-components';
// Data
import { BiometricTabArr, subHeaderData } from '../../../../data/data';
// Component
import SubHeaderControl from '../Control/SubHeaderControl';
import BiometricTable from './BiometricTable'
const Bound=styled.div`   
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:hidden;
    /* min-width:1260px; */
    .title {
        background-color:#fafafa;
        height: 52px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;            
        .choise {
            display:flex;
            align-items:center;
            justify-content:center;
            width:120px;
            height:calc(100% - 3px);
            cursor: pointer;
        }
    }
    .ad-container {
        display: flex;
        flex: 1;
        overflow: hidden;  
        padding: 0 40px;
        flex-direction: column; 
    }
`

export default class BiometricDataContainer extends Component {
    _isMounted = false;
    state = {        
        typeSelected: BiometricTabArr[0].key,
        subHeaderData: BiometricTabArr[0].subHeaderData,
        isAdd: false
    }
    
    setTypeSelected = async (tab) => {
        if (tab) {
          const { typeSelected } = this.state
          if (typeSelected === BiometricTabArr[0].key) {
        //   await  this._isMounted && this.props.getAllKiosk(
        //       this.props.userReducer.dataLogin.OfficeID, 8, 1
        //     )
          }else {
            // await  this.props.getAllLCD(
            //   this.props.userReducer.dataLogin.OfficeID, 8, 1
            // )
          }
          this.setState({
            typeSelected: tab.key,
            subHeaderData: tab.subHeaderData,            
          })
        }
    }
    renderTab() {
        return BiometricTabArr.map((tab, i) => {
            return (
                <div className='choise' key={i}
                    style={this.state.typeSelected === tab.key ?
                        { color: tab.activeColor, borderBottom: tab.avtiveBorderBottom }
                        :
                        { color: tab.disActiveColor }}
                    onClick={() => this.setTypeSelected(tab)}>
                    {tab.label}
                </div>
            )
        })
    }  
    render() {
        return (
            <Bound>                
                <div className='title'>
                    {this.renderTab()}                    
                </div>
                <div className='ad-container'>
                    <SubHeaderControl
                        data={subHeaderData.Biometric}
                        toggleFilter={() => { this.props.toggleBiometricFilter() }}
                    />                   
                    <BiometricTable
                    dataCheckCustomer = {this.props.dataCheckCustomer}
                    showPopupCheckBiometric = {this.props.showPopupCheckBiometric}
                    />
                </div>
            </Bound>
        )
    }
}
