import React, { Component } from 'react'
import styled from 'styled-components'
import ic_on_white from '../../../../../images/ic_on_white.png'
// import ic_arrow_off from '../../../../../images/ic_arrow_off.svg'
const Bound = styled.button`
        border: none;
        background: transparent;
        cursor: pointer;
        transition: all 0.2s;
        outline: none;
`

export default class ButtonOn extends Component {
    state={
        isShowRoom:this.props.isShowRoom
    }
    
    componentWillReceiveProps(n){
        this.setState({isShowRoom:n.isShowRoom})
    }
   
    render() {
        // console.log(this.props.id)
        return (
            <Bound 
                onClick={()=>this.props.getButtonOn()} 
                style={this.state.isShowRoom? {transform:'rotate(0deg)'} : {transform:'rotate(-90deg)'} }>
                <img src={ic_on_white} alt="ic_on_white" />
            </Bound>
        )
    }
}
