import React, { Component } from 'react'
import styled from 'styled-components'
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
import { color } from '../../../data/store';
import ic_question from '../../../images/Icons_Notification_Question.svg'

const Bound = styled.div`
    padding: 20px 50px 15px 50px;
    background-color: #fafafa;
    width: ${props => props.width || '220px'};
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .row_question{
        display:flex;
        align-items: center;
        justify-content: center;
        .img_question{
            background-image: url(${ic_question});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width: 52px;
            height: 52px;
            object-fit: contain;
        }
    }
    .row_title{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;
        flex-direction:column;
        font-size: 16px;
        .title{
            line-height: 1.13;
            color: #222222;
            text-align: center;
        }
        b {
            width: 100%;
            overflow:hidden;
            display: flex;
            justify-content: center;
        }
        .contentAlert{
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            color: #f31919;
            text-align: center;
            margin: 12px 0 0 0;
    }
    }
    .row_btn_confirm{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 0 0;
        .btn_exit{
            margin: 0 20px 0 0;
        }
    }
`;
export default class PopupConfirm extends Component {
    render() {
        console.log('test',this.props);        
        return (
            <Bound width = {this.props.width}>
                <div className="row_question">
                    <span className='img_question'></span>
                </div>
                <div className="row_title">
                    <p className='title'>{this.props.title}</p>
                    <b>
                        {
                            `"${this.props.nameData}"`
                            
                            // this.props.nameData ? 
                            // `"${this.props.nameData}"`:
                            // (this.props.nameKiosk?
                            // `"${this.props.nameKiosk}"`:
                            // `"${this.props.nameLCD}"`
                            // )
                        }
                    </b>
                    <p className='contentAlert'>
                        {this.props.contentDelete}
                    </p>
                </div>
                <p></p>
                <div className="row_btn_confirm">
                    <div className="btn_exit">
                        <ButtonConfirmPopup
                        titleConfirm='HỦY BỎ'
                        bg_color='#f31919'
                        onClick={()=>{this.props.cancelAction()}}
                         />
                    </div>
                    <div className="btn_accept">
                        <ButtonConfirmPopup
                        titleConfirm='XÁC NHẬN'
                        bg_color={color.blue93}
                        onClick={()=>{this.props.acceptAction(this.props.dataNeedDelete)}}
                         />
                    </div>
                </div>
            </Bound>
        )
    }
}
