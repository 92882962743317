import React, { Component } from 'react';
import styled from "styled-components";

//Component
import TransactionCounterItem from './TransactionCounterItem';
// import SingleChoiseDropDownControl from '../Control/SingleChoiseDropDownControl';
import PagingControl from '../Control/PagningControl'
//Data
// import {emptyData} from '../../../../data/data'
import { color } from "../../../../data/store";
// import { transactionTypes } from '../../../../data/dataTransaction';

const Bound = styled.div`
    display: flex;
    flex-direction:column;
    overflow-y: auto;
    flex:1;
    margin-top: 10px;
    font-family: "SVN-Gilroy";
    .transaction-pgd-title {
      display: flex;
      height:50px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: ${color.blue93};
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      :first-child {
          font-size: 11px;
          font-weight: bold;
          line-height: 1.26;
          text-transform: uppercase;
      } 
    }
    .block-transaction{
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
.scroll{
  display: grid;
  grid-template-columns: 280px 280px 280px 280px;
  grid-gap: 15px;
  justify-content: space-evenly;
  overflow: auto;
  margin-top: 20px;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
`
const limitPerPage = 8
class TransactionCounter extends Component {
  state = {
    datalistCounter: this.props.data.listCounter,
    currentPage: 1,
    startIndex: 0,
    endIndex: 10,
  }
  componentWillMount() {
    this.pagnationPage();
  }

  componentWillReceiveProps(n) {
    let datalistCounter = n.data.listCounter
    if (n && n.data) {
      let desktopKey = n.data.desktopKey ? n.data.desktopKey : null
      let tabletKey = n.data.tabletKey ? n.data.tabletKey : null
      let counterDesktop = datalistCounter.find(item => item.ID === desktopKey.CounterID)
      let counterTablet = datalistCounter.find(item => item.ID === tabletKey.CounterID)
      if (counterDesktop) {
        counterDesktop.desktopKey = desktopKey
      }
      if (counterTablet) {
        counterTablet.tabletKey = tabletKey
      }
      // console.log('datalistCounter',datalistCounter)
    }
    this.setState({ datalistCounter })
  }
  pagnationPage() {
    let endIndex = this.state.currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({
      startIndex,
      endIndex
    })
  }
  onPageChange = (e) => {
    let currentPage = e
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
    this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID, 8, currentPage)
  }
  render() {
    const { datalistCounter,currentPage } = this.state
    let listTypeCounterServices = this.props.data.listTypeCounterServices.length
    // console.log('currentPage',this.state.currentPage)
    return (
      <Bound>
        <div className="transaction-pgd-title">
          <div>Danh sách quầy giao dịch</div>
          {
            (this.props.data.total > limitPerPage) ?
            <PagingControl
              currentPage={this.state.currentPage}
              totalItem={this.props.data.total}
              limitPerPage={limitPerPage}
              onChangePage={page => this.onPageChange(page)}
            />
            : null
          }
        </div>
        <div className="block-transaction">
          <div className='scroll'>
            {
              datalistCounter.map((item, index) => {
                return <TransactionCounterItem
                  currentPage={currentPage}
                  onShowControl={this.props.onShowControl}
                  listTypeCounterServices={listTypeCounterServices}
                  genDesktopKey={this.props.genDesktopKey}
                  genTabletKey={this.props.genTabletKey}
                  item={item} key={index} index={index} />
              })
            }
          </div>
        </div>

      </Bound>

    );
  }

}

export default TransactionCounter;