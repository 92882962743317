import * as types from "../../actions/types";

const initialState = {
  file: {},
  err: ''
}

export default function getFileAdsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_FILE_ADS:
      return {
        ...state,
        file: action.file
      }
    case types.GET_FILE_ADS_ERR:
      return {
        ...state,
        err: action.err
      }
    default:
      return state
  }
}