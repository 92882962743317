// type of alert
export const ALERT_CONTENT = 'ALERT_CONTENT';
// type of ads
export const GET_ADS = "GET_ADS";
export const GET_ADS_ERR = "GET_ADS_ERR";
export const RESET_ADS_REDUCER = "RESET_ADS_REDUCER";

export const CREATE_NEW_ADS = "CREATE_NEW_ADS";
export const CREATE_NEW_ADS_ERR = "CREATE_NEW_ADS_ERR";

export const UPDATE_ADS = "UPDATE_ADS";
export const UPDATE_ADS_ERR = "UPDATE_ADS_ERR";

export const DELETE_ADS = "DELETE_ADS";
export const DELETE_ADS_ERR = "DELETE_ADS_ERR";

export const SET_STATUS_ADS = "SET_STATUS_ADS";
export const SET_STATUS_ADS_ERR = "SET_STATUS_ADS_ERR";

export const GET_FILE_ADS = "GET_FILE_ADS";
export const GET_FILE_ADS_ERR = "GET_FILE_ADS_ERR";

// type of office
export const CREATE_NEW_OFFICE = "CREATE_NEW_OFFICE";
export const CREATE_NEW_OFFICE_ERR = "CREATE_NEW_OFFICE_ERR";

export const FILTER_OFFICE = "FILTER_OFFICE";
export const FILTER_OFFICE_ERR = "FILTER_OFFICE_ERR";

export const UPDATE_OFFICE = "UPDATE_OFFICE";
export const UPDATE_OFFICE_ERR = "UPDATE_OFFICE_ERR";

export const DELETE_OFFICE = "DELETE_OFFICE";
export const DELETE_OFFICE_ERR = "DELETE_OFFICE_ERR";

export const RESET_STATE_OFFICE = "RESET_STATE_OFFICE";

// type of user
export const ADD_USER = "ADD_USER";
export const ADD_USER_ERR = "ADD_USER_ERR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_ERR = "UPDATE_USER_ERR";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_ERR = "DELETE_USER_ERR";

export const FILTER_USER = "FILTER_USER";
export const FILTER_USER_ERR = "FILTER_USER_ERR";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_ERR = "LOGIN_USER_ERR";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_ERR = "LOGOUT_USER_ERR";

//type of counter
export const CREATE_COUNTER = "CREATE_COUNTER";
export const CREATE_COUNTER_ERR = "CREATE_COUNTER_ERR";

export const UPDATE_COUNTER = "UPDATE_COUNTER";
export const UPDATE_COUNTER_ERR = "UPDATE_COUNTER_ERR";

export const GET_COUNTER_BY_OFFICE = "GET_COUNTER_BY_OFFICE";
export const GET_COUNTER_BY_OFFICE_ERR = "GET_COUNTER_BY_OFFICE_ERR";

export const DELETE_COUNTER = "DELETE_COUNTER";
export const DELETE_COUNTER_ERR = "DELETE_COUNTER_ERR";

export const GEN_DESKTOP_KEY = "GEN_DESKTOP_KEY";
export const GEN_DESKTOP_KEY_ERR = "GEN_DESKTOP_KEY_ERR";

export const GEN_TABLET_KEY = "GEN_TABLET_KEY";
export const GEN_TABLET_KEY_ERR = "GEN_TABLET_KEY_ERR";

export const GET_LIST_TYPE_COUNTER_SERVICES = "GET_LIST_TYPE_COUNTER_SERVICES";
export const GET_LIST_TYPE_COUNTER_SERVICES_ERR = "GET_LIST_TYPE_COUNTER_SERVICES_ERR";

export const GET_LIST_COUNTER_NUMBER = "GET_LIST_COUNTER_NUMBER";
export const GET_LIST_COUNTER_NUMBER_ERR = "GET_LIST_COUNTER_NUMBER_ERR";

// type of kiosk
export const CREATE_KIOSK = "CREATE_KIOSK";
export const CREATE_KIOSK_ERR = "CREATE_KIOSK_ERR";

export const UPDATE_KIOSK = "UPDATE_KIOSK";
export const UPDATE_KIOSK_ERR = "UPDATE_KIOSK_ERR";

export const GET_ALL_KIOSK = "GET_ALL_KIOSK";
export const GET_ALL_KIOSK_ERR = "GET_ALL_KIOSK_ERR";

export const CONNECT_KIOSK = "CONNECT_KIOSK";
export const CONNECT_KIOSK_ERR = "CONNECT_KIOSK_ERR";

export const DISCONNECT_KIOSK = "DISCONNECT_KIOSK";
export const DISCONNECT_KIOSK_ERR = "DISCONNECT_KIOSK_ERR";

export const GENERATE_KEY_KIOSK = "GENERATE_KEY_KIOSK";
export const GENERATE_KEY_KIOSK_ERR = "GENERATE_KEY_KIOSK_ERR";

export const DELETE_KIOSK = "DELETE_KIOSK";
export const DELETE_KIOSK_ERR = "DELETE_KIOSK_ERR";

// type of LCD
export const CREATE_LCD = "CREATE_LCD";
export const CREATE_LCD_ERR = "CREATE_LCD_ERR";

export const UPDATE_LCD = "UPDATE_LCD"
export const UPDATE_LCD_ERR = "UPDATE_LCD_ERR"

export const GET_ALL_LCD = "GET_ALL_LCD"
export const GET_ALL_LCD_ERR = "GET_ALL_LCD_ERR"

export const DELETE_LCD = "DELETE_LCD"
export const DELETE_LCD_ERR = "DELETE_LCD_ERR"

export const GENERATE_KEY_LCD = "GENERATE_KEY_LCD";
export const GENERATE_KEY_LCD_ERR = "GENERATE_KEY_LCD_ERR";
