import React, { Component } from "react";
import styled from "styled-components";

// Images
import ic_close from '../../../images/ic_close_gray.svg'
import ic_loading from '../../../images/ic_loading.svg'
// import PopupAdvertiseRoot from './PopupAdvertiseRoot';

// import data
// import { popupType } from "../../../data/data";
const Bound = styled.div`
  padding: 15px;
  background-color: #fafafa;
  width: 555px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  @keyframes spin {
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
  }
  .popupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
    }
    .ic_close {
      background-image: url(${ic_close});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }
  .popupMainBody {
    padding: 25px 15px 0 15px;
    .addImage {
      width: 520px;
      height: 292.2px;
      border: dashed 1.5px #7878786b;
      background-color: #e9e9e9;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 80px 0 0 0;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: none;
        }
        .rotate{
          animation: spin 2s linear infinite;
          display: block;
        }
        p {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.68;
          color: #979797;
          span {
            color: #005993;
            cursor: pointer;
          }
        }
      }
      .warning {
        height: 34px;
        padding: 70px 0 0 0;
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        line-height: 1.42;
        color: #979797;
        margin: 0 auto;
      }

      .warning-video {
        width: 204px;
      }

      .warning-img {
        width: 256px;
      }
    }
    .error {
      width:100%;
      height: 16px;
      margin: 15px 0 0 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.42;
      color: #f31919;
    }
  }
`;

const MAX_WIDTH = 1920;
const MAX_HEIGHT = 1080;

export default class PopupNewImage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileNotMatch: false

    }

    this.imgLoading = React.createRef();
  }


  dragOver = e => {
    e.preventDefault();
    this.setState({ fileNotMatch: false });
  };

  dragEnter = e => {
    e.preventDefault();
  };

  drop = e => {
    e.preventDefault();
    this.imgLoading.current.classList.add("rotate")
    const dt = e.dataTransfer;
    const files = dt.files;
    this.handleFiles(files);
  };
  nextStep() {
    this.props.nextStep()
  }

  handleChooseFile = (e) => {
    e.preventDefault();
    this.inputChooseFile.click();
  }
  

  handleFiles = files => {
    const thatComponent = this;
    if (files && files[0]) {
      const file = files[0];
      // console.log(file)

      const imageType = /image\/(png|jpg|jpeg)/;
      const videoType = /video\/mp4/;

      var reader = new FileReader();

      // check image
      if (this.props.typeAdvert === "KIOSK" || this.props.typeAdvert === "LCD") {
        // validate type image
        if (!file.type.match(imageType)) {
          this.imgLoading.current.classList.remove("rotate")
          this.setState({ fileNotMatch: true });
          return;
        }
        const ImgFormat = file.type.split("/")[1];

        reader.onload = function (e) {
          const img = new Image();
          img.src = e.target.result;
          img.onload = function () {

            // validate width and height image
            if (this.width > MAX_WIDTH || this.height > MAX_HEIGHT) {
              thatComponent.imgLoading.current.classList.remove("rotate")
              thatComponent.setState({ fileNotMatch: true });
              return;
            }

            thatComponent.props.setImgInfo({
              url: e.target.result,
              name: file.name,
              width: this.width,
              height: this.height,
              size: file.size,
              format: ImgFormat
            }, file)
            thatComponent.imgLoading.current.classList.remove("rotate")
            thatComponent.nextStep();
          }
        };
        reader.readAsDataURL(file);
      } else {//check video
        // console.log("name:", file.name)
        // console.log("size:", file.size)
        // validate type video
        if (!file.type.match(videoType)) {
          this.imgLoading.current.classList.remove("rotate")
          this.setState({ fileNotMatch: true });
          return;
        }
        reader.onload = function (e) {
          const blob = new Blob([reader.result], { type: file.type });
          const url = URL.createObjectURL(blob);
          const video = document.createElement('video');
          const timeupdate = function () {
            // console.log("this.duration", this.duration)
            if (this.width > MAX_WIDTH || this.height > MAX_HEIGHT) {
              thatComponent.imgLoading.current.classList.remove("rotate")
              thatComponent.setState({ fileNotMatch: true });
              return true;
            }
            const canvas = document.createElement('canvas');
            canvas.width = this.videoWidth;
            canvas.height = this.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();//url preview
            // console.log("urlpreview:", image);

            thatComponent.props.setVideoInfo({
              urlImgPreview: image,
              name: file.name,
              duration: this.duration,
              size: file.size
            }, file)
            URL.revokeObjectURL(url);
            this.removeEventListener('timeupdate', timeupdate);
            this.pause();

            thatComponent.imgLoading.current.classList.remove("rotate")
            thatComponent.nextStep();
          };


          video.addEventListener('timeupdate', timeupdate);
          video.preload = 'metadata';
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        }

        reader.readAsArrayBuffer(file);
      }
    }
  };
  render() {
    // console.log(this.props.typeAdvert);
    return (
      <Bound>
        <div className="popupHeader">
          <h4 className='title'>{this.props.title}</h4>
          <span onClick={() => this.props.outPopup()} className='ic_close'></span>
        </div>
        <div className="popupMainBody">
          <div className="addImage">
            <div className="content">
              <img src={ic_loading} alt="loading" ref={this.imgLoading} />
              <p>Drag file or select
                  <span onClick={this.handleChooseFile} className="choose-file"> browser</span>
              </p>
            </div>
            { 
              (() => {
                if (this.props.typeAdvert === "KIOSK" || this.props.typeAdvert === "LCD"){
                  return <div className="warning warning-img">
                    <p>
                      Yêu cầu định dạng .JPG, .PNG, kích thước cho phép hiển thị trên thiết bị 1920 x 1080 px
                    </p>
                  </div>
                } else if (this.props.typeAdvert === "GDV"){
                  return <div className="warning warning-video">
                    <p>
                      Yêu cầu định dạng .MP4, kích thước cho phép chuẩn HD (1920 x 1080 px)
                    </p>
                  </div>
                }
              })()
            }
          </div>
          <div className="error">
          {this.state.fileNotMatch && (
            <div >Định dạng file không phù hợp</div>
            )}
            </div>
        </div>
      </Bound>
    )
  }
  componentDidMount() {
    const dropZone = document.querySelector(".popupMainBody .addImage");
    if (dropZone) {
      dropZone.addEventListener("dragenter", this.dragEnter, false);
      dropZone.addEventListener("dragover", this.dragOver, false);
      dropZone.addEventListener("drop", this.drop, false);

    }
    // console.log(this.imgLoading)
    // setTimeout(() => {
    //   this.nextStep()
    // }, 3000);
    this.inputChooseFile = document.createElement('input');
    this.inputChooseFile.setAttribute('type', 'file');
    const that  = this;
    this.inputChooseFile.onchange = function(e){
      that.handleFiles(this.files)
    }
  }

  componentWillUnmount() {
    const dropZone = document.querySelector(".popupMainBody .addImage");
    if (dropZone && dropZone.removeEventListener) {
      // For all major browsers, except IE 8 and earlier
      dropZone.removeEventListener("dragenter", this.dragEnter);
      dropZone.removeEventListener("dragover", this.dragOver);
      dropZone.removeEventListener("drop", this.drop);
    } else if (dropZone && dropZone.detachEvent) {
      // For IE 8 and earlier versions
      dropZone.detachEvent("dragenter", this.dragEnter);
      dropZone.detachEvent("dragover", this.dragOver);
      dropZone.detachEvent("drop", this.drop);
    }
  }
}
