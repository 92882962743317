import Axios from "axios";

const setAuth = (isAuth) => {
  if(isAuth){
    console.log("authe");
    Axios.defaults.withCredentials = true;
    Axios.defaults.headers.post["CSRF-TOKEN"] = isAuth.CsrfToken;
    return;
  }
  Axios.defaults.withCredentials = false;
  delete Axios.defaults.headers.common["CSRF-TOKEN"];
}

export default setAuth;