import moment from "moment";
import jwt from "jsonwebtoken";
import {dataCity} from './data/VietNamData/DataCity'
import {dataDistrict} from './data/VietNamData/DataDistrict'
import {dataWard} from './data/VietNamData/DataWard'

const RANGE_BYTE = 1024;

/**
 * format day and time
 * @param {object} data: data is data format from moment()
 * @return {object} contain attribute format time
 */
export const formatDayAndTime = (data = moment()) => ({
    /**
   * format to: HH:mm:ss
   * example: 14:26:00
   */
    getFullTime: data.format("HH:mm:ss"),

    getFullDate: data.format("DD-MM-YY")
});

/**
 * Authentication login
 * Generate token
 * Decode token
 */

/**
 * Secret key to generate token
 */
const SECRET_KEY = "SECRET_KEY";

/**
 * Algorithm is used to generate token
 */
const ALG = "HS256";

/**
 * Expire time of token
 */
const EXP = "1h";

/**
 * Use tranfer in genToken function
 * @param {string} userName
 * @param {string} fullName
 * @param {string} email
 * @param {string} permission
 * @param {string} avatar
 * @return {Object}
 */
export const configPayLoad = (userName, fullName, email, permission, avatar) => ({userName: userName, fullName: fullName, email: email, permission: permission, avatar: avatar});

/**
 * Generate token user jsonwebtoken
 * @param {Object} payload contain some info of account to generate token
 * @return {string} token
 */
export const genToken = payload => jwt.sign(payload, SECRET_KEY, {
    algorithm: ALG,
    expiresIn: EXP
});

/**
 * Get data of account from token
 * @param {string} token token
 * @return {Object} object contain some data of account
 */
export const decodeToken = token => jwt.verify(token, SECRET_KEY);

/**
 * used to format size of image or video before show in UI
 * @param {{
 *  size: int
 * }} info
 */
export const formatSize = (info) => {
    const sizeToKb = Math.round(info.size / RANGE_BYTE);
    return sizeToKb >= RANGE_BYTE
        ? Math.round(sizeToKb / RANGE_BYTE) + " MBs"
        : sizeToKb + " KBs";
}

/**
 * format number to 000....0000x
 * @param {number} num number need format
 * @param {number} quantityNumOut quantity number in output
 */
export const formatNumber = (num, quantityNumOut) => {
    const numToStr = String(num);
    const zero = "0";
    const numOfZero = zero.repeat(quantityNumOut);
    return numOfZero.substring(0, numOfZero.length - numToStr.length) + numToStr;
}

export const getArrayCity = () => {
    let obj = dataCity
    let array = []
    for (let i in obj) {
        array.push(obj[i])
    }
    return array
}

export const getDistrictArrayByCityCode = (code) => {
    let nextArray = []
    let obj = dataDistrict
    for (let i in obj) {
        nextArray.push(obj[i])
    }
    nextArray = nextArray.filter(item => item.parent_code === code.toString())
    return nextArray
}

export const getWardArrayByDistrictCode = (code) => {
    let nextArray = []
    let obj = dataWard
    for (let i in obj) {
        nextArray.push(obj[i])
    }
    nextArray = nextArray.filter(item => item.parent_code === code.toString())
    return nextArray
}

/////// get name dataVietName

export const getNameCity = (code) => {
  let nextArray = []
  let obj = dataCity
  for (let i in obj) {
      nextArray.push(obj[i])
  }
  nextArray = nextArray.filter(item => item.code === code.toString())
  return nextArray
}
export const getNameDistrict = (code) => {
  let nextArray = []
  let obj = dataDistrict
  for (let i in obj) {
      nextArray.push(obj[i])
  }
  nextArray = nextArray.filter(item => item.code === code.toString())
  return nextArray
}
export const getNameWard = (code) => {
  let nextArray = []
  let obj = dataWard
  for (let i in obj) {
      nextArray.push(obj[i])
  }
  nextArray = nextArray.filter(item => item.code === code.toString())
  return nextArray
}
