import React, { Component } from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
// Component
import TransactionContainer from "./Transaction/TransactionContainer";
import Header from "./Header";
import AdvertisementContainerV2 from './Advertisement/AdvertisementContainerV2';
import PermissionContainer from "./Permission/PermissionContainer";
import BranchContainer from "./Branch/BranchContainer";
import AdvertisePGDContainer from "./AdvertisementPGD/AdvertisePGDContainer";
import TrandingRoomDevice from "./DeviceKioskPGD/TrandingRoomDevice";
import TransactionPGDContainer from "./TransactionPGD/TransactionPGDContainer";
import BiometricDataContainer from './Biometric/BiometricDataContainer'
import PopupContainer from "../Popup/PopupContainer";
import PopupAlert from '../Popup/PopupAlert';
import PopupBranchFilter from "../Popup/PopupBranchFilter";
import PopupPermissionFilter from "../Popup/PopupPermissionFilter";
//Data
import { users } from "../../../data/store";
import { branchs } from '../../../data/dataBranch'
import { dataTrandingRoom } from "../../../data/dataDeviceKioskTransactionBranch";
import { popupType, mainMenu } from "../../../data/data";
import * as userRole from "../../../data/userRole"
import CustomerVertifyContainer from "./CustomerVertify/CustomerVertifyContainer";
import HeaderControl from "./Control/HeaderControl";
import PopupBiometricFilter from "../Popup/PopupBiometricFilter";
// import PopupAdvertise from "../Popup/PopupAdvertise";

const Bound = styled.div`
  display: flex;
  flex: 1;
  background-color: #e9e9e9;
  /* margin-left:48px; */
  .biometric-data{
    .right-header {
      .time{
          width: 48px;
          padding-left: 4px;
          padding-right: 10px;
      }
      button {
          display: none;
      }
    } 
  }
  .main-bound {
    display: flex;
    flex: 1;
    flex-direction: column;
    .second-bar{
      display:flex;
      margin-bottom:15px;
      height:70px;
      .block-left{
          display:flex;
          align-items:center;
      }
    }
  }
`
export default class MainScreenContainer extends Component {
  constructor(props) {
    super(props);
    //fix type: -1 after test
    this.popupObj = {
      type: -1,
      data: {}
    };
    this.dataCheckCustomer = {
      
    };
    this.popupAlert = {
      content: ''
    }
    this.state = {
      isShowPopup: false,
      isShowFilter: false,
      isShowPermissionFilter: false,
      isShowBiometricFilter: false,
      isShowAlert: false,
      checkNumber: false
    };
    // this.permission = JSON.parse(localStorage.getItem("checkLogin")).permission;

  }


  dataCheckCustomer = (dataCheckCustomer) => {
    this.dataCheckCustomer = dataCheckCustomer
    alert(123)
  }

  checkMatchPermission = (keyCheck, propertyCheck) => {
    // return object contain key
    const checkIncludeKey = key => property => object =>
      object[property].includes(key);

    const getKey = checkIncludeKey(keyCheck);

    const getProperty = getKey(propertyCheck);

    const getMenuItem = mainMenu.find(getProperty);

    return getMenuItem.permissions.includes(this.props.dataLogin.Role);
  };

  showPopup(type, data) {
    // console.log('test',data);    
    this.popupObj.type = type;
    this.popupObj.data = data;
    // console.log(this.popupObj)
    this.setState({
      isShowPopup: true
    });
  }
  onClosePopup() {
    this.popupObj = {};
    this.setState({
      isShowPopup: false
    });
  }
  alertPopup = (content) => {
    this.popupAlert.content = content
    this.setState({ isShowAlert: true })
  }
  onClosePopupAlert = () => {
    this.popupAlert = {}
    this.setState({ isShowAlert: false })
  }
  getData(data) {
    this.popupObj.type = data.type;
    Object.assign(this.popupObj.data, data.data)
    this.setState({
      data: this.popupObj
    })
    // console.log('this.popupObj',this.popupObj) 
  }

  toggleFilter() {
    // console.log("filter");
    const { isShowFilter } = this.state;
    if (isShowFilter) {
      //hide filter
      let filter = document.getElementById("filter_branch_id");
      if (filter) {
        filter.style.right = "-240px";
      }
      setTimeout(() => {
        this.setState({ isShowFilter: !isShowFilter });
      }, 500);
    } else {
      //show filter
      this.setState({ isShowFilter: !isShowFilter }, () => {
        let filter = document.getElementById("filter_branch_id");
        if (filter) {
          setTimeout(() => {
            filter.style.right = "0px";
          }, 50);
        }
      });
    }
  }
  togglePermissionFilter() {
    // console.log("filter");
    const { isShowPermissionFilter } = this.state;
    if (isShowPermissionFilter) {
      //hide filter
      let filter = document.getElementById("filter_permission_id");
      if (filter) {
        filter.style.right = "-240px";
      }
      setTimeout(() => {
        this.setState({ isShowPermissionFilter: !isShowPermissionFilter });
      }, 500);
    } else {
      //show filter
      this.setState({ isShowPermissionFilter: !isShowPermissionFilter }, () => {
        let filter = document.getElementById("filter_permission_id");
        if (filter) {
          setTimeout(() => {
            filter.style.right = "0px";
          }, 50);
        }
      });
    }
  }
  toggleBiometricFilter() {
    // console.log("filter");
    const { isShowBiometricFilter } = this.state;
    if (isShowBiometricFilter) {
      //hide filter
      let filter = document.getElementById("filter_biometric_id");
      if (filter) {
        filter.style.right = "-240px";
      }
      setTimeout(() => {
        this.setState({ isShowBiometricFilter: !isShowBiometricFilter });
      }, 500);
    } else {
      //show filter
      this.setState({ isShowBiometricFilter: !isShowBiometricFilter }, () => {
        let filter = document.getElementById("filter_biometric_id");
        if (filter) {
          setTimeout(() => {
            filter.style.right = "0px";
          }, 50);
        }
      });
    }
  }
  onModify = (from, dataNeedModify) => {
    // console.log(from)
    console.log(dataNeedModify)
    if (from === "Branch") {
      this.showPopup(
        popupType.configTransaction,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          titleConfirm: 'Cập nhật',
          dataNeedModify
        }
      )
    } else if (from === "Permission") {
      this.showPopup(
        popupType.configAccountPermission,
        {
          title: 'Thông tin tài khoản ',
          dataNeedModify
        }
      )
    }
    else if (from === "deviceKiosk") {
      this.showPopup(
        popupType.configAdvertiseKiosk,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          titleConfirm: 'Cập nhật',
          dataNeedModify,
        }
      )
    } else if (from === "deviceTablet") {
      this.showPopup(
        popupType.configAdvertiseTable,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          dataNeedModify,
        }
      )
    }
    else if (from === "showTicket") {
      this.showPopup(
        popupType.configAdvertiseKiosk,
        {
          title: 'Tùy chỉnh chi nhánh/PGD',
          titleConfirm: 'Cập nhật',
          dataNeedModify,
        }
      )
    }
    else if (from === "DeviceKioskPGD") {
      this.showPopup(
        popupType.configAddBranch,
        {
          titleHeader: "Tùy chỉnh thiết bị Kiosk",
          title: "Tên thiết bị kiosk",
          placeHolder: "Nhập tên thiết bị Kiosk",
          dataNeedModify,
          type: 'DeviceKiosk'
        }
      )
    }
    else if (from === "DeviceLCDPGD") {
      this.showPopup(
        popupType.configAddBranch,
        {
          titleHeader: "Tùy chỉnh màn hình LCD",
          title: "Tên màn hình LCD",
          placeHolder: "Nhập tên thiết bị LCD",
          dataNeedModify,
          type: 'DeviceLcd'
        }
      )
    }
    else if (from === 'CounterTrans') {
      this.showPopup(
        popupType.configAddCounter,
        {
          titleHeader: "Tùy chỉnh quầy giao dịch",
          // title:"Tên màn hình LCD", 
          // placeHolder:"Nhập tên thiết bị Kiosk",
          dataNeedModify,
        }
      )
    }
    else {
      console.log("from other")
    }
  }

  onRemoveConfirm = (from, dataNeedDelete) => {
    // console.log(from)
    console.log(dataNeedDelete)
    if (from === "Branch") {
      this.showPopup(popupType.configConfirm,
        {
          title: 'Xác nhận xóa chi nhánh/PGD',
          widthPopup: '280px',
          // contentDelete: 'Điểm giao dịch bị xóa sẽ đứt kết nối đến các thiết bị đã cấu hình vào hệ thống',
          dataNeedDelete
        }
      )
    }
    else if (from === "Permission") {
      this.showPopup(
        popupType.configDeletePermission, {
          title: 'Xác nhận xóa tài khoản ',
          dataNeedDelete
        }
      )
    }
    else if (from === "deviceKiosk") {
      this.showPopup(
        popupType.configConfirm, {
          title: 'Xác nhận xóa quảng cáo ',
          dataNeedDelete
        }
      )
    }
    else if (from === "deviceTablet") {
      this.showPopup(
        popupType.configConfirm, {
          title: 'Xác nhận xóa quảng cáo ',
          dataNeedDelete
        }
      )
    }
    else if (from === "showTicket") {
      this.showPopup(
        popupType.configConfirm, {
          title: 'Xác nhận xóa quảng cáo ',
          dataNeedDelete
        }
      )
    }
    else if (from === "DeviceKioskPGD") {
      this.showPopup(
        popupType.configConfirm, {
          title: 'Xác nhận xóa thiết bị Kiosk',
          contentDelete: 'Mất kết nối với thiết bị Kiosk đã liên kết',
          dataNeedDelete,
          widthPopup: '280px',
        }
      )
    }
    else if (from === "DeviceLCDPGD") {
      this.showPopup(
        popupType.configConfirm, {
          title: 'Xác nhận màn hình LCD',
          contentDelete: 'Mất kết nối với màn hình LCD đã liên kết',
          dataNeedDelete,
          widthPopup: '280px',
        }
      )
    }
    else if (from === 'CounterTrans') {
      this.showPopup(
        popupType.configConfirm, {
          title: 'Xác nhận xóa quầy giao dịch',
          contentDelete: 'Quầy bị xóa sẽ đứt kết nối với các thiết bị đã liên kết',
          dataNeedDelete,
          widthPopup: '280px',
        }
      )
    }
    else {
      console.log("from other")
    }
  }
  render() {
    // console.log(' data - render', this.state.data)
    // console.log({dataTrandingRoom});  
    return (
      <Bound>
        <Switch>
          <Route
            exact
            path="/danh-sach-giao-dich"
            render={() => {
              if (this.checkMatchPermission("/danh-sach-giao-dich", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Danh sách giao dịch" />
                    <TransactionContainer />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/chi-nhanh-pgd"
            render={() => {
              if (this.checkMatchPermission("/chi-nhanh-pgd", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Chi nhánh/PGD" />
                    <BranchContainer
                      // dataNewBranch={this.state.data}
                      currentPage={this.currentPage}
                      data={branchs}
                      showAddBranchPopup={() => {
                        this.showPopup(
                          popupType.configTransaction,
                          { title: 'Thêm mới chi nhánh' }
                        )
                      }}
                      // showAddTransactionRoomPopup={() => {
                      //   this.showPopup(popupType.configTransaction,{})
                      // }}
                      toggleFilter={() => {
                        this.toggleFilter();
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/quang-cao"
            render={() => {
              if (this.checkMatchPermission("/quang-cao", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Quảng cáo PGD" />
                    <AdvertisementContainerV2
                      showAddAdvertisementKioskPopup={() => {
                        this.showPopup(popupType.configAdvertisementPopup, { typeAdvert: "KIOSK" })
                      }}
                      showAddAdvertisementTablePopup={() => {
                        this.showPopup(popupType.configAdvertisementPopup, { typeAdvert: "GDV" })
                      }}
                      showAddAdvertisementShowTicketPopup={() => {
                        this.showPopup(popupType.configAdvertisementPopup, { typeAdvert: "LCD" })
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/phan-quyen"
            render={() => {
              if (this.checkMatchPermission("/phan-quyen", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Phân quyền" />
                    <PermissionContainer
                      users={users}
                      // onShowAlert={(offset,data) => {
                      //   this.showPopup(popupType.popupControlType, {
                      //     offsetPopup: offset,
                      //     data
                      //   });
                      // }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }}
                      togglePermissionFilter={() => {
                        this.togglePermissionFilter();
                      }}
                      showAddUserPopup={() => { this.showPopup(popupType.configAddPermission, {}) }}
                    />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/thiet-bi-kiosk"
            render={() => {
              if (this.checkMatchPermission("/thiet-bi-kiosk", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Thiết bị PGD" />
                    <TrandingRoomDevice
                      data={dataTrandingRoom}
                      showAddTrandingRoomKioskPopup={() => {
                        this.showPopup(popupType.configAddBranch, {
                          titleHeader: "Tùy chỉnh thiết bị Kiosk",
                          title: "tên thiết bị kiosk",
                          placeHolder: "Nhập tên thiết bị Kiosk",
                          type: 'DeviceKiosk'
                        })
                      }}
                      showAddTrandingRoomLCDPopup={() => {
                        this.showPopup(popupType.configAddBranch, {
                          titleHeader: "Thêm mới màn hình LCD",
                          title: "tên màn hình LCD",
                          placeHolder: "Nhập tên thiết bị LCD",
                          type: 'DeviceLcd'
                        })
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }} />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/quay-giao-dich"
            render={() => {
              if (this.checkMatchPermission("/quay-giao-dich", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Quầy giao dịch" />
                    <TransactionPGDContainer
                      onShowOutOfLimit={() => {
                        this.showPopup(popupType.configOutOfLimit, {
                          title: 'Số quầy vượt quá số lượng cho phép'
                        })
                      }}
                      showPopupAddCounter={() => {
                        this.showPopup(popupType.configAddCounter, {
                          titleHeader: "Thêm mới quầy giao dịch"
                          , title: "tên màn hình LCD",
                          placeHolder: "Nhập tên thiết bị LCD"
                        })
                      }}
                      onShowControl={(offset, from, dataReceive) => {
                        this.showPopup(popupType.popupControlType, {
                          offsetPopup: offset,
                          from,
                          dataReceive
                        });
                      }} />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/quang-cao-pgd"
            render={() => {
              if (this.checkMatchPermission("/quang-cao-pgd", "key"))
                return (
                  <div className="main-bound">
                    <Header title="Quảng cáo PGD" />
                    <AdvertisePGDContainer />
                  </div>
                );
              return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/biometric-data"
            render={() => {
              if (this.checkMatchPermission("/biometric-data", "key"))
                return (
                  <div className="main-bound biometric-data">
                    <HeaderControl title="Dữ liệu Biometric" />
                    <BiometricDataContainer
                      dataCheckCustomer = {()=>this.dataCheckCustomer}

                      showPopupCheckBiometric={() => {
                        this.showPopup(popupType.configCheckBiometricCustomer, {
                          dataUser: {
                            a: 'aaa',
                            b: 'tunganh test',
                            CIF: '132a1s32d1131',
                            status: 'hello'
                          },
                          dataBiometric: {
                            a: 'aaa',
                            b: 'tunganh test',
                            CIF: '132a1s32d1131',
                            status: 'hello'
                          }
                        })
                      }}
                      toggleBiometricFilter={() => {
                        this.toggleBiometricFilter();
                      }}                    
                    />
                  </div>
                );
            return <Redirect to="/" />;
          }
          }/>
          <Route 
            exact 
            path="/customer-vertify" 
            render={()=>{
              if (this.checkMatchPermission("/customer-vertify", "key"))
                return (
                  <div className="main-bound customer-vertify">
                    <HeaderControl title="Xác thực khách hàng" />
                    <CustomerVertifyContainer
                    showPopupCheckCustomer = {()=>this.showPopup(popupType.configCheckCustomer, {

                    })}
                    
                      toggleBiometricFilter={() => {
                        this.toggleBiometricFilter();
                      }}
                    />
                  </div>              
                );
            return <Redirect to="/" />;
          }
          }/>
          {/* <Route
                exact
                path="/setting"
                render={() => <PermissionContainer/>}
            /> */}
          {
            (() => {
              switch (this.props.dataLogin.Role) {
                case userRole.ADMIN_ROLE:
                  return <Redirect from="/" to="/danh-sach-giao-dich" />
                case userRole.ADMIN_OFFICE_ROLE:
                  return <Redirect from="/" to="/thiet-bi-kiosk" />
                case userRole.OFFEXSUPERVISOR:
                case userRole.OFFINSUPERVISOR:
                  return <Redirect from="/" to="/biometric-data" />
                default:
                  break;
              }
            })()
          }
        </Switch>
        {
          this.state.isShowPopup && (
            <PopupContainer
              alertPopup={this.alertPopup}
              popupRef={this.popupObj}
              closePopup={() => {
                this.onClosePopup();
              }}
              onModify={(from, dataNeedModify) => {
                this.onClosePopup();
                // this.onModify(from, dataNeedModify);
                setTimeout(() => {
                  this.onModify(from, dataNeedModify)
                }, 300);
              }}
              onRemoveConfirm={(from, dataNeedDelete) => {
                this.onClosePopup();
                setTimeout(() => {
                  this.onRemoveConfirm(from, dataNeedDelete)
                }, 300);
              }}
              onShowRemoveBranchError={(dataShow) => {
                this.onClosePopup();
                setTimeout(() => {
                  this.showPopup(popupType.configFlowCheckOffice, {
                    dataShow,
                    contentDelete: 'Bạn vui lòng thay đổi liên kết cho tài khoản và thiết bị trước khi xóa',
                  })
                }, 300);
              }}
              getData={data => this.getData(data)}
            />
          )
        }
        {
          this.state.isShowFilter && (
            <PopupBranchFilter
              getPopupBranchFilter={this.getPopupBranchFilter}
              onClose={() => {
                this.toggleFilter();
              }}
              onSubmit={() => {
                this.toggleFilter();
              }}
              alertPopup={this.alertPopup}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
            />
          )
        }
        {
          this.state.isShowPermissionFilter && (
            <PopupPermissionFilter
              onClose={() => {
                this.togglePermissionFilter();
              }}
              onSubmit={() => {
                this.togglePermissionFilter();
              }}
              alertPopup={this.alertPopup}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
            />
          )
        }
        {
          this.state.isShowBiometricFilter && (
            <PopupBiometricFilter
              onClose={() => {
                this.toggleBiometricFilter();
              }}
              onSubmit={() => {
                this.toggleBiometricFilter();
              }}
              alertPopup={this.alertPopup}
              outPopupAlert={() => {
                this.onClosePopupAlert()
              }}
            />
          )
        }
        {
          this.state.isShowAlert &&
          <PopupAlert
            data={this.popupAlert}
            outPopup={() => {
              setTimeout(() => {
                this.onClosePopupAlert()
              }, 300)
            }}
          />
        }
      </Bound >
    );
  }
}
