import React, { Component } from 'react';
import styled from "styled-components";

//Image
// import ic_star_gray from '../../../../images/ic_star_gray.svg';
import ic_star_yellow from '../../../../images/starKHUT.svg';
import ic_more_gray from '../../../../images/ic_more_gray.svg';
import ic_refresh from '../../../../images/ic_refresh.svg';


const Bound = styled.div`
   box-sizing:border-box;
    width: 280px;
    height: 295px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 14px 20px;
    font-family:'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .counter-top{
    position: relative;
    display: flex;
    margin-top: 5px;
    /* height: 44px; */
    img{
      margin-right: 4px;
    }
    .title_counter{
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      padding: 0 10px 0 0;
      height: 60px;
    }
    .ic_more_gray{
      width: 24px;
      height: 24px;
      background-image: url(${ic_more_gray});
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: -7px;
      cursor: pointer;
    }
  }
  .block_star{
    height: 38px;
    width: 100%;
    .div_star {
      width: 64px;
      height: 20px;
      border-radius: 3px;
      background-color: #ffbb00;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: default;
      .title_vip{
        font-size: 12px;
        font-weight: bold;
        line-height: 1.2;
        color: #ffffff;
      }
    }
  }
 
  .counter-main {
    display: flex;
    flex-direction: column;
    flex: 1;
      .service, .counter{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
      .counter {
        margin: 5px 0 13px 0;
      }
      .service {
        margin: 0 0 37px 0;
      }
      .name{
        font-size: 11px;
        font-weight: bold;
        line-height: 1.26;
        color: #005993;
      }
      .number{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: #222222;
      }
      .f-cover{
        display: flex;
        flex: 1;
        align-items: baseline;
        justify-content: space-between;
        position: relative;
        .name{
          position: absolute;
          top: 0;
          left: 0;
        }
        .connect{
          position: absolute;
          right: 0;
          text-align: right;
          text-decoration: underline;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          color: #0062d7;
          cursor: pointer;
        }
        .ic_refresh_connect{
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
        }
        .sub-name{
          font-family: SVN-Gilroy;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.3;
          color: #979797;
          /* padding-top: 10px; */
        }
      }
  }
`
class TransactionCounterItem extends Component {


  onShowControl = (index,data) => {
    let moreBtn = document.getElementById('more_button_id_' + index);
    if (moreBtn) {
      let bound = moreBtn.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let topBound = bound.top + scrollTop
      let leftBound = bound.left + scrollLeft
      let offsetPopup = {
        top: topBound + bound.height / 2,
        left: leftBound - 78 + bound.width / 2
      };
      let dataNeed = {...data,currentPage:this.props.currentPage}
      this.props.onShowControl(offsetPopup, 'CounterTrans',dataNeed)
    }
  }
  n(n) {
    if (n > 0) {
      return n > 9 ? '' + n : '0' + n
    } else { return 0 }
  }
  render() {
    const { item, index } = this.props
    let desktopKey
    let tabletKey
    if(typeof item.tabletKey !== "undefined"){
      if(Object.keys(item.tabletKey).length!==0){
        tabletKey = item.tabletKey
      }
    }
    if(typeof item.desktopKey !== "undefined"){
      if(Object.keys(item.desktopKey).length!==0){
        desktopKey = item.desktopKey
      }
    }
    return (
      <Bound >
        <div className='counter-top'>
          {/* {item.IsVIP ?
            <div className='ic_star' style={{ backgroundImage: `url(${ic_star_yellow})` }}></div>
            : null} */}

          <h2 className='title_counter'>{item.CounterName}</h2>
          <div className='ic_more_gray'
            id={'more_button_id_' + index}
            onClick={() => this.onShowControl(index,item)}></div>
        </div>
        <div className='block_star'>
        {
          item.IsVIP ?
          <div  className='div_star'>
                <img className='ic_star' src={ic_star_yellow}  alt='star'></img>
                <p className='title_vip'>Ưu tiên</p>
              </div>
              : null
            }
        </div>
        <div className='counter-main'>
          {/* <div className="device-counter"> */}
            <div className="counter">
              <div className="name">SỐ QUẦY</div>
              <div className="number"> {this.n(item.CounterNumber)} </div>
            </div>
            <div className="service">
              <div className="name">DỊCH VỤ:</div>
              <div className="number">
                {item.ServAllService?this.n(this.props.listTypeCounterServices):this.n(item.ServiceList?item.ServiceList.length:0)}
              </div>
            </div>
          {/* </div> */}

          <div className='f-cover'>
            <div className='name'>
              <div className="main-name">THIẾT BỊ QUẦY</div>
              {
                desktopKey?
                <div className="sub-name">
                  {desktopKey.ConnectID}-{desktopKey.Pwd}
                </div>:
                null
              }
              
            </div>
            {
              desktopKey ?
                <div className="ic_refresh_connect"  onClick={() =>
                  this.props.genDesktopKey(item.ID, item.AtOfficeID)}>
                  <img src={ic_refresh} alt='ic_refresh_connect' />
                </div>
                :
                <div className="connect" onClick={() =>
                  this.props.genDesktopKey(item.ID, item.AtOfficeID)}>
                  <span>Connect ID</span>
                </div>
            }
          </div>
          <div className='f-cover'>
            <div className='name'>
              <div className="main-name">THIẾT BỊ HỖ TRỢ GDV</div>
              {
                tabletKey?
                <div className="sub-name">
                  {tabletKey.ConnectID}-{tabletKey.Pwd}
                </div>:
                null
              }
            </div>
            {
              tabletKey ?
                <div className="ic_refresh_connect"  onClick={() =>
                  this.props.genTabletKey(item.ID, item.AtOfficeID)}>
                  <img src={ic_refresh} alt='ic_refresh_connect' />
                </div>
                :
                <div className="connect" onClick={() =>
                  this.props.genTabletKey(item.ID, item.AtOfficeID)}>
                  <span>Connect ID</span>
                </div>
            }
          </div>
        </div>
      </Bound>
    );
  }
}

export default TransactionCounterItem;