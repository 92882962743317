import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import moment from "moment";

import LoginPage from './Login/LoginPage'
import MainPage from './Main/MainPage'
import { createBrowserHistory } from 'history';
// import PopupContainer from './Main/Popup/PopupContainer';
import PopupAlert from './Main/Popup/PopupAlert';
import { logoutUser, loginUser } from '../actions/userAction';
import * as types from "../actions/types"
import setAuth from '../helper/setAuth';
// import DataVietNam from '../data/vietNamData/DataVietNam';

const Bound = styled.div`
    width:100%;
    height:100%;
    display:flex;
    font-family:'SVN-Gilroy';
    font-weight:500;
`

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.popupAlert={
            content:''
          }
        this.state = {
            // isLogin: false,
            isShowAlert: false,
            err: ''
        }
    }

    // history = createBrowserHistory();

    // isLogin = (userName, fullName, email, permission, avatarImg, isLogin) => {
    //     let user = {
    //         userName: userName,
    //         fullName: fullName,
    //         email: email,
    //         permission: permission,
    //         avatarImg: avatarImg,
    //         isLogin: isLogin
    //     }
    //     localStorage.setItem('checkLogin', JSON.stringify(user));
    //     this.setState({
    //         isLogin: true
    //     })
    // }

    // isLogout = () => {
    //     localStorage.clear();
    //     this.setState({
    //         isLogin: false
    //     })
    // }

    componentWillMount() {
        // let data = JSON.parse(localStorage.getItem('checkLogin'))
        // if (localStorage.getItem('checkLogin') !== null) {
        //     this.setState({
        //         isLogin: data.isLogin
        //     });
        // }
        // console.log(this.props)
        let dataLogin = JSON.parse(localStorage.getItem("dataLogin"));
        if(dataLogin){
            setAuth(dataLogin)
            this.props.setDataLogin(dataLogin)
        }
    }

    alertPopup=(content)=>{
        this.popupAlert.content=content
        this.setState({isShowAlert:true})
      }
    onClosePopupAlert=()=>{
        this.popupAlert={}
        this.setState({isShowAlert:false})
      }

    render() {
        const { dataLogin, err} = this.props.userReducer;
        // console.log(dataLogin) 
        return (
            <Bound>
                {
                    Boolean(Object.keys(dataLogin).length) ?
                    <MainPage
                        // isLogout={this.isLogout}
                        logoutUser={this.props.logoutUser}
                        dataLogin={dataLogin}    
                    />
                    :
                    <LoginPage
                        alertPopup={this.alertPopup}
                        // isLogin={this.isLogin}
                        loginUser={this.props.loginUser}
                        error={err}
                    />
                }
                {
                this.state.isShowAlert&&
                <PopupAlert
                    data={this.popupAlert}
                    outPopup={() => {
                    this.onClosePopupAlert()
                    }}
                    />
                }
    {/* <DataVietNam/> */}
            </Bound>
        )
    }
}

const mapStateFromProps = (state) => ({
    userReducer: state.userReducer
})

const mapDispatchFromProps = (dispatch) => ({
    logoutUser: (id) => dispatch(logoutUser(id)),
    loginUser: (email, pass) => dispatch(loginUser(email, pass)),
    setDataLogin: (data) => {
        
        dispatch({
            type: types.LOGIN_USER,
            data
        })
    }
})

export default connect(mapStateFromProps, mapDispatchFromProps, null, { forwardRef: true })(MainContainer)
