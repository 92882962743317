import * as types from "../../actions/types";

const initialState = {
  success: false,
  err: ''
}

export default function setStatusAdsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_STATUS_ADS:
      return {
        ...state,
        success: action.success
      }
    case types.SET_STATUS_ADS_ERR:
      return {
        ...state,
        err: action.err
      }
    default:
      return state
  }
}