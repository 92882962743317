import React, { Component } from 'react'
import styled from 'styled-components'
import ButtonAdd from './GroupButton/ButtonAdd'
import ButtonMore from './GroupButton/ButtonMore'
import ButtonOn from './GroupButton/ButtonOn'


const Bound = styled.div`
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export default class GroupButtonControl extends Component {
    state={
        isShowRoom:this.props.isShowRoom
    }
    
    componentWillReceiveProps(n){
        this.setState({isShowRoom:n.isShowRoom})
    }
    render() {
        return (
            <Bound>
                <ButtonAdd 
                    showAddTransactionRoomPopup={()=>{this.props.showAddTransactionRoomPopup()}}/>
                <ButtonMore />
                <ButtonOn
                    getButtonOn={()=>this.props.getButtonOn()} 
                    isShowRoom={this.state.isShowRoom}/>
            </Bound>
        )
    }
}
