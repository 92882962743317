import React, { Component } from "react";
import styled from "styled-components";

import PopupNewImage from './PopupNewImage';
import PopupAdvertiseRoot from './PopupAdvertiseRoot';
import PopupAdvertiseBranch from './PopupAdvertiseBranch';
// import ApplicationLocation from "./ApplicationLocation";

const Bound = styled.div`
  padding: 15px;
  background-color: #fafafa;
  width: 555px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
`
const RANGE_BYTE = 1024;
const TYPE_IMGINFO = "imgInfo";
const TYPE_VIDEOINFO = "videoInfo";

export default class FlowAddAdvertisement extends Component {
  constructor(props) {
    super(props)
    this.state = {             
      step: 1,
      imgInfo: {
        url: "",
        name: "",
        width: 0,
        height: 0,
        size: 0,
        format: ""
      },
      videoInfo: {
        urlImgPreview: "",
        name: "",
        duration: "",
        size: 0
      },
      file: {}//content data of file use to submit form
    }

    // this.popupNewImage = React.createRef();
  }

  nextStep() {
    let { step } = this.state
    step++;
    this.setState({ step })
  }

  setInfo = (type) => (info, file) => {
    this.setState({ [type]: info, file })
  }

  formatSize = (info) => {
    const sizeToKb = Math.round(info.size / RANGE_BYTE);
    return sizeToKb >= RANGE_BYTE ?
      Math.round(sizeToKb / RANGE_BYTE) + " MBs"
      :
      sizeToKb + " KBs";
  }

  render() {
    let content = null
    if (this.props.typeAdvert === "KIOSK") {
      content = <PopupAdvertiseRoot
        title='Thêm mới quảng cáo'        
        titleConfirm='Tạo mới'
        outPopup={this.props.outPopup}
        imgInfo={this.state.imgInfo}
        formatSize={this.formatSize}
        fileData={this.state.file}
        handleOnSubmit={this.props.handleOnSubmit}
        handleReLoadData={this.props.handleReLoadData}
        typeAds="KIOSK"
      />     
    }
    else if (this.props.typeAdvert === "GDV") {
      content = <PopupAdvertiseBranch
        titleHeader='Thêm mới quảng cáo'
        outPopup={this.props.outPopup}
        videoInfo={this.state.videoInfo}
        formatSize={this.formatSize}
        fileData={this.state.file}
        handleOnSubmit={this.props.handleOnSubmit}
        typeAds="GDV"
      />
    }else {
      content = <PopupAdvertiseRoot
        title='Thêm mới quảng cáo'       
        titleConfirm='Xác nhận'
        outPopup={this.props.outPopup}
        imgInfo={this.state.imgInfo}
        formatSize={this.formatSize}
        fileData={this.state.file}
        handleOnSubmit={this.props.handleOnSubmit}
        handleReLoadData={this.props.handleReLoadData}
        typeAds="LCD"
      />  
    }
    // console.log(this.state.step)
    const setImgInfo = this.setInfo(TYPE_IMGINFO);
    const setVideoInfo = this.setInfo(TYPE_VIDEOINFO);
    return (
      <Bound>        
        {
          this.state.step === 1 &&
          <PopupNewImage
            title='Thêm mới quảng cáo'
            outPopup={this.props.outPopup}
            nextStep={() => this.nextStep()}
            setImgInfo={setImgInfo}
            setVideoInfo={setVideoInfo}
            typeAdvert={this.props.typeAdvert}
            ref={ref => this.popupNewImage = ref}
          />
        }
        {
          this.state.step === 2 &&
          content
        }
      </Bound>
    )
  }
}
