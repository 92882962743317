import * as types from "../../actions/types";

const initialState = {
  listAds: [],
  totalAds: 0,
  err: '',
  alert:''
}

export default function getAdsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ADS:
      return {
        ...state,
        listAds: action.listAds,
        totalAds: action.totalAds
      }
    case types.GET_ADS_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.ALERT_CONTENT:
      return {
        ...state,
        alert: action.alert
      }
    case types.RESET_ADS_REDUCER:
      return initialState;
    default:
      return state
  }
}