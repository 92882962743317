import React, {Component} from 'react'
import styled from 'styled-components'

// Images
import ic_close from '../../../images/ic_close_gray.svg';
// Components
import InputTextControl from '../../Main/MainScreen/Control/InputTextControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
// import PopupHeader from './PopupHeader'

const Bound = styled.div `
    padding: 14px 14px 24px 12px;
    background-color: #fafafa;
    width: 400px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .popupHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 27px;
        h4{
            font-family: SVN-Gilroy;
            font-size: 20px;
            font-weight: bold;
            color: #000000;
        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .group-input{
        margin-right: 5.8px;
        margin-left: 8px;
    }
    button{
        float: right;
        margin: 23px 5.8px 0 0;
    }
`

export default class PopupAddBranch extends Component {
    state = {
        data:''
    }
    getText(data){
        this.setState({data})
    }
    onClick(){
    console.log(this.props.dataNeedModify);
      if(this.props.idOffice && this.props.dataNeedModify){
            this.props.handleOnClick(this.props.dataNeedModify.ID,this.state.data,this.props.type,this.props.dataNeedModify.currentPage)
        }else if (this.state.data !== '') {
            this.props.handleOnClick(
                this.props.idOffice,
                this.state.data,
                this.props.type)
        }
        this.props.outPopup()
    }
    render() {
        // console.log("edit branch",this.props)
        // console.log('dataNeedModify',this.props.dataNeedModify)
        return (
            <Bound>
                <div className="popupHeader">                  
                    <h4 className='title'>{this.props.titleHeader}</h4>
                    <span onClick={() => this.props.outPopup()} className='ic_close'></span>
                </div>
                <div className = 'group-input'>
                    <InputTextControl
                        getText={(data) => this.getText(data)}
                        title = {this.props.title} 
                        placeHolder = {this.props.placeHolder}
                        defaultValue={this.props.dataNeedModify&&this.props.dataNeedModify.Name?this.props.dataNeedModify.Name:''}
                    />
                </div>
                <ButtonConfirmPopup 
                    onClick={()=>this.onClick()}
                    titleConfirm = 'hoàn tất'/>
            </Bound>
        )
    }
}