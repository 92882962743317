import React, { Component } from 'react'
import styled from 'styled-components'
import ic_more_white from './../../../../../images/ic_more_white.png'
const Bound = styled.button`
        border: none;
        background: transparent;
        cursor: pointer;
`

export default class ButtonMore extends Component {
    render() {
        return (
            <Bound>
                <img src={ic_more_white} alt="ic_more_white" />
            </Bound>
        )
    }
}
