import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { color } from '../../../data/store';

const Bound = styled.li`
    height: 60px;
    line-height: 60px;
    a{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        text-decoration: none;
        font-family: SVN-Gilroy;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.13;
        color: #fafafa;
        transition: opacity 0.5s;
        opacity: ${props=>props.isShow?1:0.7};
        cursor:unset;
        img{
            padding: 0 12px;
        }
        &:hover{
            background-color: ${props=>props.isShow?color.red49:'transparent'};
            transition: all .5s ease;
            cursor:${props=>props.isShow?'pointer':'unset'}
        }
        &.menu-item.active {
            background-color: #d71249;
            opacity:1;
        }
    }
`

class MenuItem extends Component {
    state = {
        pathActive: this.props.pathActive,
        isShow:this.props.isShow
    }
    onActiveMenu() {
        this.props.onActiveMenu(this.props.data.key)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            pathActive:nextProps.pathActive,
            isShow:nextProps.isShow
        })
    }

    render() {
        let {isShow} = this.state
        return (
            <Bound isShow={isShow}>
                <Link to = {isShow?this.props.data.key:document.location.pathname} onClick = {isShow?this.onActiveMenu.bind(this):()=>{}} 
                    className={this.state.pathActive === this.props.data.key ? 'menu-item active' : 'menu-item'}>
                    <img src = {this.props.data.icon} alt = {this.props.data.content} />
                    {this.props.data.content}
                </Link>
            </Bound>
        );
    }
}

export default MenuItem;