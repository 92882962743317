import React, { Component } from 'react';
import styled from 'styled-components';
//Image
import logo_vietinbank from '../../../images/logo_vietinbank.svg';
import ic_arrow_down_gray from '../../../images/ic_arrow_down_gray.svg';

const Bound = styled.div`
   display: flex;
    height:60px;
    background-color: #ffffff;
    padding: 0 29px 0 25px;
    .header-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;    
        flex: 1;
        font-family: SVN-Gilroy;
        .content-info{
            text-align: right;
            line-height: 1;
            p{
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                line-height: 1.13;
                color: #222222;
            }
            span{
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.42;
                color: #979797;
            }
        }
        img.img-info{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin: 0 18px 0 10px;
         }
        img.ic-down{
            cursor: pointer;
        }
    }

`

export default class HeaderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            avatarImg: '',
        }
    }
    
    componentWillMount() {
        // let data = JSON.parse(localStorage.getItem('checkLogin'))
        // if(localStorage.getItem('checkLogin') !== null) {
            this.props.dataLogin && this.setState({
                fullName: this.props.dataLogin.FullName,
                email: this.props.dataLogin.Email,
                avatarImg: "https://picsum.photos/200/300"
            });
        // }
    }
    render() {
        return (
            <Bound>
                <img src = {logo_vietinbank} alt = 'logo_vietinbank'/>
                <div className = 'header-right'>
                    <div className= 'content-info'>
                        <p>{this.state.fullName}</p>
                        <span>{this.state.email}</span>
                    </div>
                    <img src = {this.state.avatarImg} alt = 'avatarImg' className = 'img-info'/>
                    <img 
                        className = 'ic-down' 
                        src = {ic_arrow_down_gray} 
                        alt ='ic-arrow-down' 
                        // onClick={()=>this.props.isLogout()}
                        onClick={() => this.props.logoutUser(this.props.dataLogin.UID)}
                    />
                </div>
            </Bound>
        )
    }
}
