import * as types from "../../actions/types";

const initialState = {
  listUser: [],
  total: 0,
  success: false,
  dataLogin: {},
  err: '',
  alert: ''
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.ALERT_CONTENT:
      return {
        ...state,
        alert: action.alert
      }
    case types.ADD_USER:
      return {
        ...state,
        success: action.success,
        err: ''
      }
    case types.ADD_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.UPDATE_USER:
      return {
        ...state,
        err: ''
      }
    case types.UPDATE_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.DELETE_USER:
      return {
        ...state,
        success: action.success,
        err: ''
      }
    case types.DELETE_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.FILTER_USER:
      return {
        ...state,
        listUser: action.listUser,
        total: action.total,
        err: ''
      }
    case types.FILTER_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.LOGIN_USER:
      return {
        ...state,
        dataLogin: action.data,
        err: ''
      }
    case types.LOGIN_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.LOGOUT_USER:
      return {
        ...state,
        dataLogin: {},
        err: ''
      }
    case types.LOGIN_USER_ERR:
      return {
        ...state,
        err: action.err
      }
    default:
      return state
  }
}