import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
// Component
import SubHeaderControl from '../Control/SubHeaderControl'
import PagingControl from '../Control/PagningControl';
import EmptyControl from "../Control/EmptyControl";
import DeviceKiosItem from './DeviceKiosItem';
import DeviceLCDItem from './DeviceLCDItem';
// Data
import { TrandingRoomTabArr, emptyData } from '../../../../data/data';
import { getAllKiosk, generateKeyConnectKiosk } from '../../../../actions/kioskAction';
import { getAllLCD, generateKeyConnectLCD } from '../../../../actions/LCDAction';
import { async } from 'q';

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:hidden;
    min-width:1260px;
    .title {
        background-color:#fafafa;
        height: 48px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: flex-start;            
        .choise {
            display:flex;
            align-items:center;
            justify-content:center;
            width:120px;
            height:calc(100% - 3px);
            cursor: pointer;
        }
    }
    .ad-container{
        display: flex;
        flex: 1;
        overflow: hidden;  
        padding: 0 40px;
        flex-direction: column; 
        .title-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                color: #005993;
                font-size: 11px;
                text-transform: uppercase;
                margin: 26px 0 17px 0;
            }
        }
        .item {
            display: flex;
            flex-direction: column;
            overflow:hidden;
            p{
                text-transform: uppercase;
            }
            .scroll{
                overflow:auto;   				
				&::-webkit-scrollbar {
					width: 0px;
				}
        .item-child {
            display: grid;
            grid-template-columns: 280px 280px 280px 280px;
            justify-content: space-evenly;
            grid-gap: 20px;       
                  
            ::-webkit-scrollbar { 
            display: none; 
            }
          }    
        }
      }        
    }
`
const limitPerPage = 8
class TrandingRoomDevice extends Component {
  _isMounted = false;
  state = {
    tradingTab: [],
    typeSelected: TrandingRoomTabArr[0].key,
    subHeaderData: TrandingRoomTabArr[0].subHeaderData,
    data: this.props.data,
    currentPage: 1,
    startIndex: 0,
    endIndex: 10,
    // totalAds: 8,
    alertContent: '',
  }

  componentWillMount() {
      let tradingTab = JSON.parse(JSON.stringify(TrandingRoomTabArr));
      const { dataLogin} = this.props.userReducer;
      tradingTab = tradingTab.map(item => 
        ({
          ...item, 
          subHeaderData: {
            ...item.subHeaderData,
            mainTitle: dataLogin.OfficeName,
            subTitle: `${dataLogin.OfficeAddress}, phường ${dataLogin.OfficeWard}, Quận ${dataLogin.OfficeDistrict}, TP. ${dataLogin.OfficeProvince}`
          }
        }))
      this.setState({
        tradingTab,
        typeSelected: tradingTab[0].key,
        subHeaderData: tradingTab[0].subHeaderData
      })
  }
  
  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.pagnationPage();
    this._isMounted && this.props.getAllKiosk(
      this.props.userReducer.dataLogin.OfficeID, 8, 1
    )
    this._isMounted && this.props.getAllLCD(
      this.props.userReducer.dataLogin.OfficeID, 8, 1
    )
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.kioskReducer.kioskInfo !== this.props.kioskReducer.kioskInfo) {
      this.setState({ currentPage: 1 })
    }
    else if (prevProps.LCDReducer.LCDInfo !== this.props.LCDReducer.LCDInfo) {
      this.setState({ currentPage: 1 })
    }
  }

  setTypeSelected = async (tab) => {
    if (tab) {
      const { typeSelected } = this.state
      if (typeSelected === TrandingRoomTabArr[0].key) {
      await  this._isMounted && this.props.getAllKiosk(
          this.props.userReducer.dataLogin.OfficeID, 8, 1
        )
      }else {
        await  this.props.getAllLCD(
          this.props.userReducer.dataLogin.OfficeID, 8, 1
        )
      }
      this.setState({
        typeSelected: tab.key,
        subHeaderData: tab.subHeaderData,
        currentPage:1
      })
    }
  }
  pagnationPage() {
    let endIndex = this.state.currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ startIndex, endIndex })
  }
  onPageChange = (e) => {
    let currentPage = e
    let endIndex = currentPage * limitPerPage;
    let startIndex = endIndex - limitPerPage;
    this.setState({ currentPage, startIndex, endIndex });
    if (this.state.typeSelected === TrandingRoomTabArr[0].key) {
      this._isMounted && this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8, currentPage);
    }else {
      this._isMounted &&  this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8, currentPage);
    }
  }
  showPopupAddAdvert = () => {
    const { typeSelected } = this.state
    switch (typeSelected) {
      case TrandingRoomTabArr[0].key: {
        this.props.showAddTrandingRoomKioskPopup(this.state.currentPage)
        break;
      }
      case TrandingRoomTabArr[1].key: {
        this.props.showAddTrandingRoomLCDPopup()
        break;
      }
      default:
        break;
    }
  }
  renderTab() {
    return this.state.tradingTab.map((tab, i) => {
      return (
        <div className='choise' key={i}
          style={this.state.typeSelected === tab.key ?
            { color: tab.activeColor, borderBottom: tab.avtiveBorderBottom }
            :
            { color: tab.disActiveColor }}
          onClick={() => this.setTypeSelected(tab)}>
          {tab.label}
        </div>
      )
    })
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let { subHeaderData, typeSelected, currentPage } = this.state
    // let dataSlice = this.state.data.slice(startIndex, endIndex)
    // console.log(dataSlice);
    const { listKiosk, total } = this.props.kioskReducer;
    const { listLCD, total:totalLcd } = this.props.LCDReducer

    let totalPage;
    if (this.state.typeSelected === TrandingRoomTabArr[0].key) {
      totalPage = total
    } else {
      totalPage = totalLcd
    } 
    return (
      <Bound>
        <div className='title'>
          {this.renderTab()}
        </div>
        <div className='ad-container'>
          <SubHeaderControl
            data={subHeaderData}
            onAddMoreSubmit={this.showPopupAddAdvert} />
          {this._isMounted && totalPage > 0 ?
            (
              <div className="item">
                <div className='title-wrapper'>
                  <p>danh sách quảng cáo</p>
                  {
                   (totalPage > limitPerPage)  ?
                      <PagingControl
                        currentPage={currentPage}
                        totalItem={totalPage}
                        limitPerPage={limitPerPage}
                        onChangePage={page => this.onPageChange(page)} /> 
                        : null
                  }
                </div>
                <div className='scroll'>
                  <div className="item-child">
                    {
                      // this._isMounted && dataSlice.map((item, i) => {
                      this._isMounted && listKiosk.map((item, i) => {
                        switch (typeSelected) {
                          case TrandingRoomTabArr[0].key: {
                            return (
                              <DeviceKiosItem
                                currentPage={currentPage}
                                key={i}
                                keyValue={i}
                                data={item}
                                dataGenkey = {this.props.kioskReducer.dataGenKey}
                                onShowControl={(offset, from, data) => {
                                  this.props.onShowControl(offset, from, data);
                                }}
                                generateKeyConnectKiosk = {this.props.generateKeyConnectKiosk}
                              />
                            )
                          }
                          default:
                            break;
                        }
                        return null;
                      })
                    }
                    {
                      (() => {
                        return this._isMounted && listLCD.map((item, i) => {
                          switch (typeSelected) {
                            case TrandingRoomTabArr[1].key: {
                              return (
                                <DeviceLCDItem
                                  currentPage={currentPage}
                                  key={i}
                                  keyValue={i}
                                  data={item}
                                  dataGenkey = {this.props.LCDReducer.dataGenKey}
                                  onShowControl={(offset, from, data) => {
                                    this.props.onShowControl(offset, from, data, this.state.currentPage);
                                  }} 
                                  generateKeyConnectLCD = {this.props.generateKeyConnectLCD}
                                />
                              )
                            }
                            default:
                              break;
                          }
                          return null;
                        })
                      })()
                    }
                  </div>
                </div>
              </div>
            )
            : (
              <EmptyControl
                alertContent={this.state.alertContent}
                widthInput={400}
                content={emptyData.deviceKiosk} />)
          }
        </div>

      </Bound>
    )
  }
}

const mapStateToProps = state => ({
  kioskReducer: state.kioskReducer,
  userReducer: state.userReducer,
  LCDReducer:state.LCDReducer
})

const mapDispatchToProps = dispatch => ({
  getAllKiosk: (idOffice, pageSize, pageNumber) => dispatch(getAllKiosk(idOffice, pageSize, pageNumber)),
  getAllLCD:(idOffice, pageSize, pageNumber) => dispatch(getAllLCD(idOffice, pageSize, pageNumber)),
  generateKeyConnectKiosk: (idOffice, idKiosk) => dispatch(generateKeyConnectKiosk(idOffice, idKiosk)),
  generateKeyConnectLCD: (idOffice, idLCD) => dispatch(generateKeyConnectLCD(idOffice, idLCD)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TrandingRoomDevice);
