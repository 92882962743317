import React, { Component } from 'react';
import styled from 'styled-components';
import ic_close from '../../../../images/ic_close_gray.svg'
import UserInformation from './UserInformation';
import UserBiometric from './UserBiometric';
import ActionControlCheckBio from './Control/ActionControlCheckBio';
import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup';
import ic_edit from '../../../../images/Icons_Edit.svg'
import ic_checkBio from '../../../../images/Icons_CheckBiometric.svg'
import ic_history from '../../../../images/Icons_History.svg'

const Bound = styled.div`
  position: absolute;
  top: 50%;
  left:50%;
  transform:translate(-50%,-50%);
  width: 705px;
  height: 516px;
  border-radius: 10px;
  background-color: #fafafa;
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .header {
    padding: 16px 10px 0 20px;
    width: calc(100% - 30px);
    height: 68px;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #e3e3e3c4;
    .block_header_title {
      display: flex;
      justify-content:space-between;
      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .main_content{
    height: 352px;
  }
  .footer_check {
    margin: 20px 0 0 0;
    width: 705px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    .block_action{
      width:105px;
      display: flex;
      margin: 0 0 0 30px;
      justify-content:space-between;
    }
     .block_btn {
      margin: 0 20px 0 0;
      width: 170px;
      display: flex;
      align-items: center;
      justify-content:space-between;
    }
  }
`

export const TabLayout = styled.div`
  display: flex;
  justify-content:space-between;
  margin: 10px 0 0 0;
  .title_content {
    display: flex;
    .tab_title_single {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #005993;
      margin: 0 0 7px 20px;
    }
    .tab_title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #c8c8c8;
      margin: 0 13px 0 0;
      cursor: pointer;
      padding: 0px 8px 15px 8px;
    }
  }
  .title_info {
    display: flex;
    .cif {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #005993;
      margin: 0 25px 0 0;
      .text_cif{
        color: #222222;
      }
    }
    .status {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #005993;
      .text_status {
        color:#979797;
        margin: 0 15px 0 0;
      }
    }
  }
`

export default class PopupCheckBiometricCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      changeTab: 1,
      dataUser: this.props.dataUser ? this.props.dataUser : null,
      dataBiometric: this.props.dataBiometric ? this.props.dataBiometric : null
    }
  }

  changeTab = (changeTab) => {
    this.setState({
      changeTab
    })
  }


  render() {
    let { changeTab, dataUser, dataBiometric } = this.state;
    let content_tab_layout = null;
    if (changeTab === 1) {
      content_tab_layout = <UserInformation
      typeInternal='gdv'
      />
    } else if (changeTab === 2) {
      content_tab_layout = <UserBiometric
      />
    }
    return (
      <Bound>
        <div className="header">
          <div className="block_header_title">
            <div className="title">
              Thông tin dữ liệu
            </div>
            <img
              onClick={() => this.props.outPopup()}
              src={ic_close} alt="ic_close" />
          </div>
          <TabLayout>
            <div className="title_content">
              {
                dataUser && dataBiometric ?
                  <React.Fragment>
                    <p
                      onClick={() => this.changeTab(1)}
                      style={{ borderBottom: changeTab === 1 && '2.5px solid #005993', color: changeTab === 1 && '#222222' }}
                      className='tab_title'>Thông tin dữ liệu</p>
                    <p
                      onClick={() => this.changeTab(2)}
                      style={{ borderBottom: changeTab === 2 && '2.5px solid #005993', color: changeTab === 2 && '#222222' }}
                      className='tab_title'>Sinh trắc học</p>
                  </React.Fragment>
                  :
                  <p className='tab_title_single'>Thông tin dữ liệu</p>
              }
            </div>
            {dataUser &&
              <div className="title_info">
                <div className="cif">
                  CIF: <p className='text_cif'> &nbsp;{dataUser.CIF ? dataUser.CIF : null}</p>
                </div>
                <div className="status">
                  Trạng thái: <p className='text_status'> &nbsp; Chờ phê duyệt</p>
                </div>
              </div>
            }
          </TabLayout>
        </div>
        <div className="main_content">
          {content_tab_layout}
        </div>
        <div className="footer_check">
          <div className="block_action">
            <ActionControlCheckBio
              icon = {ic_edit}
              onSubmit={() => { }}
            />
            <ActionControlCheckBio
              icon = {ic_checkBio}
              onSubmit={() => { }}
            />
            <ActionControlCheckBio
              icon = {ic_history}
              onSubmit={() => { }}
            />
          </div>
          <div className="block_btn">
            <ButtonConfirmPopup
              width='80px'
              bg_color='#d71249'
              titleConfirm='Từ chối'
              onClick={() => { }}
            />
            <ButtonConfirmPopup
              width='80px'
              bg_color='#005993'
              titleConfirm='Đồng ý'
              onClick={() => { }}
            />
          </div>
        </div>
      </Bound>

    )
  }
}
