import React, {Component} from 'react';
import styled from 'styled-components';
import DataInfoCheckBiometric from './DataInfoCheckBiometric';
import DataInfoCheckCustomer from '../PopupCheckCustomer/DataInfoCheckCustomer';

const Bound = styled.div `
  padding: 16px 40px 20px 40px;
  width: calc(100% - 80px);
  height: calc(100% - 62px);
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .title_1 {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.26;
    color: #005993;
    text-transform:uppercase;
  }
  .userName {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.08;
    color: #222222;
    margin: 14px 0 0 0;
  }
  .block_info {
    margin: 16px 0 0 0;
    .name_type {
      .content {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25;
        color: #979797;
        margin: 14px 0 0 0;
        width: 232px;
        span {
          color: #222222;
        }
      }
    }
  }
`

export default class UserInformation extends Component {
    render() {
        return (
            <Bound>
                <p className='title_1'>thông tin khách hàng</p>
                <h3 className='userName'>Nguyen Ngoc Anh</h3>
                <div className="container block_info">
                    <div className="row">
                        <div className="col-7 name_type">
                            <div className="row content">
                                <p>Loại khách hàng: &nbsp;
                                    <span>KHUT Bạc</span>
                                </p>
                                {/* <p>Loại khách hàng: <span>{this.props.typeCustom}</span></p> */}
                            </div>
                            <div className="row content">
                                <p>CMND/CCCD/HC: &nbsp;
                                    <span>0945845126</span>
                                </p>
                                {/* <p>CMND/CCCD/HC: <span>{this.props.cmnd}</span></p> */}
                            </div>
                            <div className="row content">
                                <p>Địa chỉ: &nbsp;
                                    <span>376 Tân Phú, P.10, Quận Tân Phú, TP.HCM</span>
                                </p>
                                {/* <p>Địa chỉ: <span>{this.props.location}</span></p> */}
                            </div>
                        </div>
                        <div className="col-5 name_type block_col_2">
                            <div className="row content">
                                <p>Tên KH có dấu: &nbsp;
                                    <span>Nguyễn Ngọc Anh
                                    </span>
                                </p>
                                {/* <p>Tên KH có dấu: <span>{this.props.name}</span></p> */}
                            </div>
                            <div className="row content">
                                <p>Giới tính: &nbsp;
                                    <span>Nam</span>
                                </p>
                                {/* <p>Giới tính: <span>{this.props.gender}</span></p> */}
                            </div>
                            <div className="row content">
                                <p>Số điện thoại:&nbsp;
                                    <span>0366618162</span>
                                </p>
                                {/* <p>Số điện thoại: <span>{this.props.phone}</span></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                {
                  this.props.typeInternal === 'gdv' ? 
                  <DataInfoCheckBiometric/>
                  : this.props.typeInternal === 'ksv' &&
                  <DataInfoCheckCustomer/>
                }
            </Bound>
        )
    }
}
