import React, { Component } from 'react'
import styled from 'styled-components'
import icon from './../../../../images/ic_search_gray.png'
const Bound = styled.div`    
    display:flex;
    align-items: center;
    form{
        position: relative;
        padding: 0 10px;
        .btn-search{
            position: absolute;
            right: 12px;
            border: none;
            top: 3px;
            width: 30px;
            background: transparent;
            cursor: pointer;
            img{width:100%}
            outline:none;
        }
        input{
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            width: 320px;
            height: 36px;
            border-radius: 4px;
            background-color: #ffffff;
            border: none;
            padding: 0 35px 0 10px;
            outline:none;
            font-family:'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.68;
            letter-spacing: normal;
            color: #222222;
            &::placeholder{
                opacity:.25;
            }
        }
    }
`

export default class SearchControl extends Component {
    state = {
        // txtName:''
        txtText:''
    }
    onChange = e => {
        // let name = e.target.name
        let value = e.target.value
        this.setState({
            // [name]: value
            txtText: value
        })
    }
    onSubmit = event => {
        event.preventDefault();
        // this.props.searchFilter(this.state.txtName);
        this.props.searchFilter(this.state.txtText);
        this.setState({txtText: ''})
    }
    render() {
        return (
            <Bound>
               <form onSubmit={this.onSubmit}>
                   <input type="text" 
                   name="txtText"
                   value={this.state.txtText}
                   onChange={this.onChange}
                   placeholder={this.props.inputname} />
                   <button type="submit" className="btn-search">
                        <img src={icon} alt="ic_search" />
                   </button>
               </form>
            </Bound>
        )
    }
}
