import React, { Component } from 'react'
import styled from 'styled-components'

const Bound = styled.button`
    width:  ${props =>props.width || '100px'};
    height: 36px;
    border-radius: 18px;
    background-color: ${props =>props.bg_color || '#005993'};
    display: flex;
    justify-content: center;
    align-items: center;
    outline:none;
    border:none;
    cursor: pointer;
    .titleConfirm{
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export default class ButtonConfirmPopup extends Component {
    render() {
        // let {dataNeedModify} = this.props
        return (
            <Bound 
                width = {this.props.width}
                bg_color={this.props.bg_color} 
                onClick={()=>{                
                return this.props.onClick()
            }}>
                <div className='titleConfirm'>
                    {this.props.titleConfirm}
                </div>
            </Bound>
        )
    }
}
