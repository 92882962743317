import React, { Component } from 'react'
import styled from 'styled-components'
import ic_close from '../../../images/ic_close.svg'

const Bound = styled.div`
    z-index:99999;
    padding: 20px 45px;
    width: 210px;
    height: 168px;
    border-radius: 10px;
    background-color: #fafafa;
    display:flex;
    align-items: center;
    flex-direction:column;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    animation-name: example;
    animation-duration: 0.4s;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
    @keyframes example {
        0%   { top:-168px;}
        100%  { top:0;}
    }
    .ic_close{
        width: 52px;
        height: 52px;
        object-fit: contain;
    }
    .content {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.19;
        color: #222222;
        margin:22px 0 0 0;
        text-align: center;
        height: 40px;
    }
    .btn_ok {
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 22px 0 0 0;
        cursor: pointer;
    }
`

export default class PopupAlert extends Component {
    render() {
        return (
            <Bound>
                <div className="ic_close">
                    <img src={ic_close} alt="ic_close"/>
                </div>
                <div className="content">
                    {
                        this.props.title ? this.props.title:  this.props.data.content
                    }
                </div>
                <div className="btn_ok"
                    onClick = {this.props.outPopup}>
                    XÁC NHẬN
                </div>
            </Bound>
        )
    }
}
