import React, { Component } from 'react'
import styled from 'styled-components'
const Bound = styled.div`
    display: inline-flex;
    align-items: center;
    visibility: ${props => props.isActive};
    .icon-left, .icon-right{
        width: 24px;
        height: 24px;
        background-color: #ffffff;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .left{
        display: inline-block;
        width: 8px;
        height: 14px;
        background-color: #005993;
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
    .right{
        display: inline-block;
        width: 8px;
        height: 14px;
        background-color: #005993;
        clip-path: polygon(100% 50%, 0 0, 0 100%);
    }
    .center{
        color: #222222;
        font-size: 13px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
        color: #c8c8c8;
        margin-left: 4px;
        }
    }
`
export default class SortControl extends Component {
    state = {
        currentPage: this.props.currentPage,
        totalPage: 0,
        totalItem:this.props.totalItem
    };
    componentWillMount() {
        this.pagnationPage();
    }
    componentWillReceiveProps(n){
        if(n){
            this.setState({
                currentPage:n.currentPage,
                totalItem:n.totalItem
            },()=>{this.pagnationPage()})
        }
    }
    pagnationPage() {
        let totalPage = Math.ceil(this.state.totalItem / this.props.limitPerPage);
        this.setState({
            totalPage,
        })
    }
    n(n){
        return n > 9 ? "" + n: "0" + n;
    }
    render() {
        let { totalPage, currentPage} = this.state
        return (
            <Bound isActive = {this.props.isActive}>
                <div className="icon-left" onClick={ () => {
                    let page=currentPage-1;
                    if(page<=0){
                      page=1
                    }
                    this.props.onChangePage(page,totalPage)
                }}>
                    <div className="left"></div>
                </div>
                <div className="center"> {this.n(currentPage)} / <span>{this.n(totalPage)}</span>  </div>
                <div className="icon-right"  onClick={() => {
                    let page=currentPage+1;
                    if(page>totalPage){
                      page=totalPage
                    }
                    // console.log(page,totalPage)
                    this.props.onChangePage(page,totalPage)
                }}>
                    <i className="right"></i>
                </div>
            </Bound>
        )
    }
}
