import ic_service_white from "../images/Menu/ic_service_white.svg";
import ic_location_white from "../images/Menu/ic_location_white.svg";
import ic_advertising_white from "../images/Menu/ic_advertising_white.svg";
import ic_employee_white from "../images/Menu/ic_employee_white.svg";
import ic_setting_white from "../images/Menu/ic_setting_white.svg";
import ic_device_white from "../images/Menu/ic_device_white.svg";
import ic_ounter_white from "../images/Menu/ic_ounter_white.svg";
import ic_biometric from "../images/Menu/ic_biometric.svg"
import ic_checkbiometric from '../images/Menu/ic_checkbiometric.svg';
// import data
import * as userRole from './userRole';

export const webVersion = "1.0";

export const dataLoginDefault = {
  ADMIN: {
    email: "admin@vietinbank.vn",
    password: "1234"
  },
  OFFADMIN: {
    email: "admin_off@vietinbank.vn",
    password: "1234"
  }
}

export const mainMenu = [
  {
    key: "/danh-sach-giao-dich",
    icon: ic_service_white,
    content: "Danh sách GD",
    permissions: [userRole.ADMIN_ROLE]
  },
  {
    key: "/chi-nhanh-pgd",
    icon: ic_location_white,
    content: "Điểm giao dịch",
    permissions: [userRole.ADMIN_ROLE]
  },
  {
    key: "/quang-cao",
    icon: ic_advertising_white,
    content: "Quảng cáo",
    permissions: [userRole.ADMIN_ROLE]
  },
  {
    key: "/thiet-bi-kiosk",
    icon: ic_device_white,
    content: "Thiết bị PGD",
    permissions: [userRole.ADMIN_OFFICE_ROLE]
  },
  {
    key: "/quay-giao-dich",
    icon: ic_ounter_white,
    content: "Quầy giao dịch",
    permissions: [userRole.ADMIN_OFFICE_ROLE]
  },
  {
    key: "/quang-cao-pgd",
    icon: ic_advertising_white,
    content: "Quảng cáo PGD",
    permissions: [userRole.ADMIN_OFFICE_ROLE]
  },
  {
    key: "/phan-quyen",
    icon: ic_employee_white,
    content: "Phân quyền",
    permissions: [userRole.ADMIN_ROLE, userRole.ADMIN_OFFICE_ROLE]
  },
  {
    key: "/biometric-data",
    icon: ic_biometric,
    content: "Dữ liệu Biometric",
    permissions: [userRole.OFFEXSUPERVISOR, userRole.OFFINSUPERVISOR]
  },
  {
    key: "/customer-vertify",
    icon: ic_checkbiometric,
    content: "Xác thực KH",
    permissions: [userRole.OFFEXSUPERVISOR, userRole.OFFINSUPERVISOR]
  },
];

export const settingObj={
    key: '/setting',
    icon: ic_setting_white,
    content: 'Setting'
}

export const permission={
    'admin':{
        title:'Administrator',
        role:1
    },
    'mod':{
        title:'Quản trị viên PGD',
        role:2
    },
    'counter':{
        title:'Giao dịch viên',
        role:3
    }
}

export const emptyData={
  branch:'Ấn vào nút “Thêm CN” để tạo chi nhánh',
  advertisement:'Ấn vào nút “Thêm mới” để tạo quảng cáo',
  transaction:'Ấn vào nút “Thêm mới” để tạo quầy', 
  deviceKiosk:'Ấn vào nút “Thêm thiết bị” để tạo thiết bị cấu hình',
  permission:'Ấn vào nút “Thêm User” để tạo tài khoản',
  counter: "Ấn vào nút “Thêm quầy” để tạo quầy"
}

export const popupType={
    configAdvertiseKiosk:2,
    configAdvertiseTable:1,
    configTransaction:3,
    configConfirm:5,
    configAdvertiseNewImage: 7,
    configAddBranch: 4,
    configSuccess: 6,
    configAddPermission: 8,
    popupControlType:9,
    configAdvertisementPopup:10,
    configAccountPermission: 11,
    configDeletePermission: 12,
    configFlowCheckOffice: 13,
    configAddCounter:14,
    configOutOfLimit:15,
    configCheckBiometricCustomer: 16,
    configCheckCustomer: 17,
}

export const subHeaderData={
  transaction:{
    mainTitle:'Danh sách giao dịch',
    subTitle:'Giao dịch trong hệ thống Smart Digital Kiosk',
    buttonArr:{}
  },
  branch:{
    mainTitle:'Hệ thống chi nhánh/ PGD',
    subTitle:'Quản trị hệ thống chi nhánh/PGD',
    buttonArr:{
      search:{placeHolder:'Nhập phòng giao dịch'},
      filter:{},
      addMore:{title:'THÊM CN'}
    }
  },
  advertisementKiosk:{
    mainTitle:'Thiết bị Kiosk',
    subTitle:'Quảng cáo thiết bị Kiosk trong hệ thống',
    buttonArr:{
      search:{placeHolder:'Nhập tên quảng cáo'},
      addMore:{title:'THÊM QC'}
    }
  },
  advertisementTablet:{
    mainTitle:'Thiết bị hỗ trợ GDV',
    subTitle:'Quảng cáo thiết bị hỗ trợ giao dịch viên trong hệ thống',
    buttonArr:{
      search:{placeHolder:'Nhập tên quảng cáo'},
      addMore:{title:'THÊM QC'}
    }
  },
  advertisementTicket:{
    mainTitle:'Bảng hiển thị vé',
    subTitle:'Quảng cáo màn hình hiển thị vé trong hệ thống',
    buttonArr:{
      search:{placeHolder:'Nhập tên quảng cáo'},
      addMore:{title:'THÊM QC'}
    }
  },
  permission:{
    mainTitle:'Hệ thống tài khoản',
    subTitle:'Danh sách tài khoản cấu hình trong hệ thống',
    buttonArr:{
      search:{placeHolder:'Nhập tên nhân viên hoặc email'},
      filter:{},
      addMore:{title:'THÊM USER'}
    }
  },
  deviceKioskPGD:{
    mainTitle:'PGD Bến Thành',
    subTitle:'79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
    buttonArr:{
      addMore:{title:'THÊM THIẾT BỊ'}
    }
  },
  transactionRoomPGD:{
    mainTitle:'PGD Bến Thành',
    subTitle:'79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
    buttonArr:{
      addMore:{title:'THÊM QUẦY'}
    }
  },
  Biometric:{
    mainTitle:'PGD Bến Thành',
    subTitle:'79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
    buttonArr:{
      search:{placeHolder:'Nhập mã số CIF, tên khách hàng'},
      filter:{},
      dataType:{content:'Khách hàng nội bộ'},
      
    }
  },
  CustomerVertify:{
    mainTitle:'PGD Bến Thành',
    subTitle:'79A Hàm Nghi, phường Nguyễn Thái Bình, Quận 1, TP.HCM',
    buttonArr:{
      search:{placeHolder:'Nhập mã số CIF, tên khách hàng'},
      filter:{},
    }
  },
}

export const advertisementTabArr=[
  {
    key:'KIOSK',
    activeColor:'#222222',
    avtiveBorderBottom:'3px solid #005993',
    disActiveColor:'#e9e9e9',
    label:'Thiết bị Kiosk',
    subHeaderData:subHeaderData.advertisementKiosk
  },
  {
    key:'GDV',
    activeColor:'#222222',
    avtiveBorderBottom:'3px solid #005993',
    disActiveColor:'#e9e9e9',
    label:'Thiết bị GDV',
    subHeaderData:subHeaderData.advertisementTablet
  },
  {
    key:'LCD',
    activeColor:'#222222',
    avtiveBorderBottom:'3px solid #005993',
    disActiveColor:'#e9e9e9',
    label:'Màn hình LCD',
    subHeaderData:subHeaderData.advertisementTicket
  }
]

export const TrandingRoomTabArr=[
  {
    key:'KIOSK',
    activeColor:'#222222',
    avtiveBorderBottom:'3px solid #005993',
    disActiveColor:'#e9e9e9',
    label:'Thiết bị Kiosk',
    subHeaderData:subHeaderData.deviceKioskPGD
  },
  {
    key:'LCD',
    activeColor:'#222222',
    avtiveBorderBottom:'3px solid #005993',
    disActiveColor:'#e9e9e9',
    label:'Màn hình LCD',
    subHeaderData:subHeaderData.deviceKioskPGD
  },  
]
export const BiometricTabArr=[
  {
    key:'DS dữ liệu',
    activeColor:'#222222',
    avtiveBorderBottom:'3px solid #005993',
    disActiveColor:'#c8c8c8',
    label:'DS dữ liệu',
    subHeaderData:subHeaderData.Biometric
  },
  {
    key:'Lịch sử xác nhận',
    activeColor:'#222222',
    avtiveBorderBottom:'3px solid #005993',
    disActiveColor:'#c8c8c8',
    label:'Lịch sử xác nhận',
    subHeaderData:subHeaderData.Biometric
  },  
]

export const dataFunction = [
  {
    type: 1,
    content: 'Cập nhật'
  },
  {
    type: 2,
    content: 'Xóa'
  },
  {
    type: 3,
    content: 'Đăng ký mới'
  }
]