import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
// Images
import ic_close from '../../../images/ic_close_gray.svg';
// import banking from '../../../images/banking.png';
//Component
import InputTextControl from '../MainScreen/Control/InputTextControl';
import TextAreaControl from '../MainScreen/Control/TextAreaControl';
// import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
import CheckOnOffControl from '../MainScreen/Control/CheckOnOffControl';
import ApplicationLocation from '../Popup/ApplicationLocation';
// Data
// import {branchs} from '../../../data/dataBranch';
import {types} from '../../../data/dataBranch';

// import action
import { filterOffice } from '../../../actions/officeAction';
import { getAllOffice } from './../../../actions/officeAction';

const Bound = styled.div`    
    background-color: #fff;
    width: 730px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    .popupHeader{
        padding: 15px 14px 24px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4{
            font-family: SVN-Gilroy;
            font-size: 20px;
            font-weight: bold;
            color: #000000;
        }
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .menu { 
        font-family: 'SVN-Gilroy';
        font-size: 12px;
        font-weight: 500; 
        color:#222222;
        position: relative;
        padding-left: 20px; 
        width: 100vw;             
        height: 26px;            
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        border-bottom: 3px solid #e9e9e9;
        margin-bottom: 20px;
        .menu-choise-left {            
            display:flex;
            align-items:center;
            justify-content:center;
            width:90px;
            height: 30px;
            cursor: pointer;
            position: absolute;
        }
        .menu-choise-right {            
            display:flex;
            align-items:center;
            justify-content:center;
            width:90px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            left: 120px;
        }
    }     
    .popup-container{
        padding: 0px 25px 0 40px;
        .main {
            display: flex;
            height: 303px;
            .popup-aside{
                width: 168px;
                min-width: 168px;
                height: 299px;
                margin-right: 32px;
                background-image: ${props => "url(" + props.urlImg + ")"};
                background-repeat: no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                position: relative;
                &:hover{
                    .overlay{
                        background-color: rgba(0,0,0,0.5);
                        transition: all .5s ease;
                        /* opacity: 1; */
                    }
                    .textChange{
                        opacity: 1;
                    }
                }
                .overlay{
                    width: 100%;
                    height: 100%;
                    /* opacity: 0; */
                    background-color: transparent;
                }
                .textChange{
                    font-family: SVN-Gilroy;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.68;
                    letter-spacing: normal;
                    color: #ffffff;
                    position:absolute;
                    left: 50%;
                    top:50%;
                    transform:translate(-50%,-50%);
                    opacity: 0;
                }
            }
            .popup-main{
                width: 100%;
                height: 299px;
                .dropdown-control{
                    cursor: pointer;
                }
            }
        }
    }
    .bottom{
        width: 100%;
        padding-left: 40px;
        font-family: SVN-Gilroy;
        .nameFile, .size, .ram{ 
            display: flex;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.42;
            color: #222222;
            p{
                font-weight: bold !important;;
            }


            span{
                padding-left: 2px;
            }      
        }
        button{
            float: right;
            margin: 0 80px 24px 0;
        }
    }
    span.error {
        position: absolute;
        left: 38px;
        bottom: 15px;
        font-family: SVN-Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.42;
        color: #f31919;
    }
`
const AD_TITLE = "adTitle";
const AD_DESC = "adDesc";

const MAX_WIDTH = 1920;
const MAX_HEIGHT = 1080;
class PopupAdvertiseRoot extends Component {
  state = {
    fileNotMatch: false,
    adTitle: 
      this.props.dataNeedModify && this.props.dataNeedModify.Title ? this.props.dataNeedModify.Title: "",
    adDesc: 
      this.props.dataNeedModify && this.props.dataNeedModify.Description ? this.props.dataNeedModify.Description: "",
    step: 1,
    active: 
      this.props.dataNeedModify && this.props.dataNeedModify.IsActive ? this.props.dataNeedModify.IsActive: false,
    imgInfo: this.props.imgInfo ? this.props.imgInfo:{},
    typeSelected: 'Thông tin QC',
    lstbranchsChecked: [],
    fileData: ''
  }  
  handleEventOnchangeInputFile = {}

  // handleUpdateAds = (dataNeedModify) => {
  //   let {ID,TypeAds} = dataNeedModify
  //   if (ID && TypeAds) {
  //     let id = ID;
  //     let dataChange = {
  //         title: this.state.adTitle,
  //         type: '',
  //         desc:this.state.adDesc,
  //         status:this.state.active
  //       }
  //       if (TypeAds === 'KIOSK') {
  //         dataChange.type = 'KIOSK'
  //       }else if(TypeAds === 'GDV') {
  //         dataChange.type = 'GDV'
  //       }else {
  //         dataChange.type = 'LCD'
  //       }
  //       this.props.updateKiosK(id, dataChange)
  //   }else {
  //     return;
  //   }
  // }

  /*
  * TODO: need update file field in update api
  * TODO: need select field was change to update 
  */
  onClickBtn = async () => {
    console.log("abc")
    const { adTitle, adDesc, active, fileData } = this.state;
    const applyAtOffice = this.state.lstbranchsChecked.map(item => item.ID)
    // console.log(applyAtOffice)
    // console.log(this.state)
    const { dataNeedModify} = this.props;
    if (dataNeedModify){
      // console.log(dataNeedModify)
      // console.log("edit")
      const dataSubmit = {}
      dataSubmit["title"] = adTitle
      dataSubmit["type"] = dataNeedModify.TypeAds
      dataSubmit["desc"] = adDesc
      if (fileData) dataSubmit["upload"] = fileData
      dataSubmit["applyAtOffice"] = applyAtOffice
      dataSubmit["status"] = active
      
      // console.log(dataSubmit)
      await this.props.handleOnSubmit(
        dataNeedModify.ID, 
        { 
          ...dataSubmit
        }
      )
      await this.props.handleReLoadData(dataNeedModify.TypeAds, 10, 1);
    }
    else{      
      // console.log("add new")
      const { fileData, typeAds } = this.props;
      await this.props.handleOnSubmit(adTitle, adDesc, fileData, typeAds, active, applyAtOffice);
      await this.props.handleReLoadData(typeAds, 10, 1);
    }
    
    this.props.outPopup();

      // Hàm update
      // this.handleUpdateAds(dataNeedModify);

 	}
  getText = (type) => (text) => {
    this.setState({
       [type]: text 
      })
  }
  setTypeSelected = (value) => {
    this.setState({
      typeSelected: value
    }, () => {
        if (this.state.typeSelected === "Vị trí áp dụng"){
            // console.log("vi tri ap dun")
            this.props.getAllOffice();
            // this.props.filterOffice({}, this.props.filterOfficeReducer.total, 1);
        }
    })
  }

  getStatus = (status) => {
    this.setState({ active: status })
  }

  getLstBranchChecked = (lst) => {
    this.setState({ lstbranchsChecked: lst})
  }

  buildFileSelector = () =>{
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type','file');
    // fileSelector.setAttribute('multiple', 'multiple');
    // console.dir(fileSelector);
    
    return fileSelector;
  }
  componentDidMount(){
    this.fileSelector = this.buildFileSelector();
    // console.log('b2',this.fileSelector);
    const that = this;
    this.fileSelector.onchange = function(e){
      // console.dir(this.files[0])
      const file = this.files[0]      

      const imageType = /image\/(png|jpg|jpeg)/;
      if (!file.type.match(imageType)) {
        that.setState({ fileNotMatch: true });
        return;
      }
      const ImgFormat = file.type.split("/")[1];
      var reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {

          // validate width and height image
          if (this.width > MAX_WIDTH || this.height > MAX_HEIGHT) {
            that.setState({ fileNotMatch: true });
            return;
          }

          that.setState({ 
            imgInfo: {
              url: e.target.result,
              name: file.name,
              width: this.width,
              height: this.height,
              size: file.size,
              format: ImgFormat
            },
            fileData: file, 
            fileNotMatch: false})
          document.querySelector(".popup-container .main .popup-aside").style.backgroundImage = `url(${e.target.result})`
        }
      }
      reader.readAsDataURL(file);
    }
    // this.handleEventOnchangeInputFile = function (e) {
    //   console.dir(e)
    //   console.log(this)
    // }
    // this.fileSelector.addEventListener("change", this.handleEventOnchangeInputFile)

  }
  handleFileSelect = (e) => {
    e.preventDefault();
    this.fileSelector.click();
  }

  componentWillUnmount() {
    // this.fileSelector.removeEventListener("change", this.handleEventOnchangeInputFile)
  }
  
  


  render() {
    // console.log(this.props.dataNeedModify)
    // console.log(this.props.filterOfficeReducer.listOffice)
    // console.log(this.props)
    const { imgInfo } = this.state;
    const getTextAdTitle = this.getText(AD_TITLE);
    const getTextAdDesc = this.getText(AD_DESC);
    // const getTextSingleChoice = this.getText("branch");
    // let content = null;
    // if (this.state.step === 1) {

    // } else if (this.state.step === 2) {

    // }
    return (
      <Bound urlImg={this.props.imgInfo.url}>
        <div className="popupHeader">
          <h4 className='title'>{this.props.title}</h4>
          <span
            onClick={() => this.props.outPopup()}
            className='ic_close'></span>
        </div>
        <div className='menu'>
          <div className='menu-choise-left'
            style={this.state.typeSelected === "Thông tin QC" ?
              { color: '#222222', borderBottom: '3px solid #005993', width: '100px' }
              :
              { color: '#e9e9e9', borderBottom: '3px solid #e9e9e9' }}
            onClick={() => this.setTypeSelected("Thông tin QC")}>
            Thông tin QC
                    </div>
          <div className='menu-choise-right'
            style={this.state.typeSelected === "Vị trí áp dụng" ?
              { color: '#222222', borderBottom: '3px solid #005993', width: '100px' }
              :
              { color: '#e9e9e9', borderBottom: '3px solid #e9e9e9' }}
            onClick={() => this.setTypeSelected("Vị trí áp dụng")}>
            Vị trí áp dụng
                    </div>
        </div>
        {
          this.state.typeSelected === "Thông tin QC" ?
            (
              <React.Fragment>
                <div className='popup-container'>
                  <div className='main'>
                    <div className='popup-aside'
                    onClick={this.handleFileSelect}
                    >
                      <div className='overlay'
                      ></div>
                      <p className='textChange'>Thay đổi</p>
                    </div>
                    <div className='popup-main'>
                      <InputTextControl
                        defaultValue={this.state.adTitle}
                        title='tên quảng cáo'
                        placeHolder='Nhập tên quảng cáo'
                        getText={getTextAdTitle}
                      />
                      <TextAreaControl
                        title='mô tả'
                        defaultValue={this.state.adDesc}
                        placeHolder={this.props.descTitle}
                        getText={getTextAdDesc}
                      />
                      <CheckOnOffControl 
												defaulVal={this.state.active}
												getStatus={this.getStatus} 
											/>                      
                    </div>
                  </div>
								</div>
                <div className='bottom'>
                  <div className='nameFile'>
                    <p>Tên file: </p>
                    <span>{imgInfo.name}</span>
                  </div>
                  <div className='size'>
                    <p>Kích thước: </p>
                    <span>{`${imgInfo.width} x ${imgInfo.height} px`}</span>
                  </div>
                  <div className='ram'>
                    <p>Dung lượng: </p>
                    <span>{this.props.formatSize(imgInfo)}</span>
                  </div>
                  <ButtonConfirmPopup
                    titleConfirm={this.props.titleConfirm}
                    onClick={this.onClickBtn}
                    
                  />
                </div>
                {this.state.fileNotMatch && <span className='error'>Định dạng file không phù hợp</span>}
              </React.Fragment>
            )
            : 
            <ApplicationLocation
              data={this.props.filterOfficeReducer.listOffice}
              types={types}
              getLstBranchChecked={this.getLstBranchChecked}
              ApplyAtOffice={
                this.props.dataNeedModify && this.props.dataNeedModify.ApplyAtOffice?
                this.props.dataNeedModify.ApplyAtOffice
                :
                []                
              }
              onHandleClick={this.onClickBtn}
              titleConfirm={this.props.titleConfirm}
            />
        }
      </Bound>
    );
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (prevState.typeSelected !== this.state.typeSelected){
  //         if (this.state.typeSelected === "Thông tin QC"){
  //             document.querySelector(".popup-container .main .popup-aside").style.backgroundImage = `url(${this.props.imgInfo.url})`
  //         }
  //     }
  // }


  // componentDidMount() {
  //     document.querySelector(".popup-container .main .popup-aside").style.backgroundImage = `url(${this.props.imgInfo.url})`

  //     // console.log(this.props.imgInfo);
  // }
}


const mapStateToProps = (state) => ({
    filterOfficeReducer: state.filterOfficeReducer
})

const mapDispatchToProps = (dispatch) => ({
  filterOffice: (dataFilter, pageSize, pageNumber) => dispatch(filterOffice(dataFilter, pageSize, pageNumber)),
  getAllOffice: () => dispatch(getAllOffice())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {forwardRef: true}
)(PopupAdvertiseRoot);