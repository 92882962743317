import React, { Component } from "react";
import styled from "styled-components";

// import image-icon
import icMoreGray from "../../../../images/ic_more_gray.svg";
import icDeviceGreen from "../../../../images/ic_device_green.svg";
import icDeviceGrey from "../../../../images/ic_device_grey.svg";
import ic_refresh from '../../../../images/ic_refresh.svg';


const StyledDeviceKiosItem = styled.div`
  width: 280px;
  height: 140px;
  position: relative;
  background: #ffffff;  
  font-family: SVN-Gilroy;   
  &:last-child{
    margin-bottom:40px;
  }
  .kiosk-item__title {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding-left: 12px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .ic_more {
      margin: 0 12px 0 0;
    }
    div {
      flex-grow: 1;
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      margin-left: 4px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .kiosk-active__content{
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;     
    margin-left: 20px;    
    margin-top: 15px;
    .kiosk-item__status{
      color: #979797;
    }
    .kiosk-item__id-number {         
      color: #222222; 
           
    }
  }
  .kiosk-item__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    font-family: SVN-Gilroy; 
    .connect{
      position: absolute;
      right: 0;
      bottom: 20px;
      text-align: right;
      margin: 0 14px 0 0;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: #1477ec;
      cursor: pointer;
    }
    .ic_refresh_connect{
      cursor: pointer;
      position: absolute;
      right: 10px;
      bottom: 12px;
    }
    .sub-name{
      position: absolute;
      left: 20px;
      bottom: 18px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      color: #222222;
    }   
  }
`;

const DEVICE_STATUS ='Chưa kết nối...';
export default class DeviceKiosItem extends Component {
  _isMounted = false;
  state = {
    data: this.props.data,
    isHover: false,    
    dataGenkey: {}
  }

  onShowControl(index, data) {    
    let moreBtn = document.getElementById('more_button_id_' + index);
    if (moreBtn) {
      let bound = moreBtn.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let topBound= bound.top + scrollTop
      let leftBound= bound.left + scrollLeft
      let offsetPopup = {
        top: topBound + bound.height / 2,
        left: leftBound - 78 + bound.width / 2
      };
      let dataNeed = {...data,type:'DeviceKiosk',currentPage:this.props.currentPage}
      console.log(dataNeed);
      this.props.onShowControl(offsetPopup, 'DeviceKioskPGD', dataNeed)
    }
  }

  async onConnect() {
    const { data } = this.props;
    await this.props.generateKeyConnectKiosk(data.AtOffice, data.ID)
    this.setState({
      dataGenkey: {...this.props.dataGenkey,ID:this.props.data.ID}
    });
  }
  
  render() {
    const { IsActive, Name, ID } = this.props.data;
    const { keyValue } = this.props;

    let {dataGenkey} = this.state
    
    return (
      <StyledDeviceKiosItem>
        {/* title */}
        <div className="kiosk-item__title">
          <img
            src={!IsActive ? icDeviceGrey : icDeviceGreen}
            alt="status"/>
          <div>{Name}</div>
          <img className='ic_more' src={icMoreGray} alt="more" id={'more_button_id_' + this.props.keyValue}
            onClick={() => this.onShowControl(keyValue, this.props.data)}
          />
        </div>
         {/* content */}
        <div className='kiosk-active__content'>         
          {!IsActive ? 
          <div className='kiosk-item__status'>{DEVICE_STATUS}</div> 
          : 
          <div className="kiosk-item__id-number"></div>
          }
        </div>       
        {/* footer */}
        <div className="kiosk-item__footer">
          {
            Object.keys(this.state.dataGenkey).length >0 && dataGenkey.ID === ID  ?
              <div className="sub-name">
                {this.state.dataGenkey.ConnectID}-{this.state.dataGenkey.Pwd}
              </div>
            :
            null
          }
          {
            Object.keys(this.state.dataGenkey).length > 0 && dataGenkey.ID === ID ? 
            <div className="ic_refresh_connect"  onClick={this.onConnect.bind(this)}>
              <img src={ic_refresh} alt='ic_refresh_connect' />
            </div>
            :
            <div className = 'connect' onClick={this.onConnect.bind(this)}>              
                Connect ID              
            </div>         
          }
        </div>
      </StyledDeviceKiosItem>
    );
  }
}
