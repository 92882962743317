import React, { Component } from 'react';
import styled from "styled-components";
import { subHeaderData } from '../../../../data/data';
import SubHeaderControl from '../Control/SubHeaderControl';
import CustomerTable from './CustomerTable'

const Bound = styled.div`
    padding: 0 40px;
    height: 100%;
`

class CustomerVertifyContainer extends Component {
    render() {
        return (
            <Bound>
                <div className='sub-header'>
                    <SubHeaderControl 
                        data={subHeaderData.CustomerVertify}
                        toggleFilter={() => { this.props.toggleBiometricFilter() }}
                    />
                    <CustomerTable 
                    showPopupCheckCustomer = {this.props.showPopupCheckCustomer}
                    />
                </div>
            </Bound>
        );
    }
}

export default CustomerVertifyContainer;