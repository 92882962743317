import React, { Component } from 'react';
import styled from 'styled-components';
import moment from "moment";
import { connect } from "react-redux";

//Component
import TableControl from './TableControl'
import SubHeaderControl from '../Control/SubHeaderControl'
import EmptyControl from '../Control/EmptyControl'

//Data
import {emptyData, subHeaderData} from '../../../../data/data'
import { filterUser  } from '../../../../actions/userAction';

//Tool
import {formatDayAndTime} from '../../../../tools';

const Bound = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:hidden;
    padding:0 40px;
`

class PermissionContainer extends Component {
    constructor(props){
        super(props)
        this.state={
            users:{}
        };
    }

    setData = () => {
        let titleArr = ['Stt', 'họ và tên', 'EMAIL', 'tài khoản', 'phòng giao dịch', 'ngày cập nhật'
        ]
        let rowArr = this.props.userReducer.listUser.map((item) => [
            item.ID,
            item.FullName,
            item.Email,
            item.Role,
            item.AtOfficeName,
            formatDayAndTime(moment(item.CreatedAt)).getFullDate
        ])
        let users = {
            title: titleArr,
            content: rowArr
        }
        this.setState({ users })
    }

    searchFilter = (data) => {
        // console.log(data)
        const dataFilter = {
            Search: data
        }
        this.props.filterUser(dataFilter, 10, 1)
    }
    
    componentDidMount(){
        this.props.filterUser({}, 10, 1)
        this.setData()
    }
    
    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.userReducer) !== JSON.stringify(this.props.userReducer)){
            this.setData()
        }
    }

    render() {
        const { alert } = this.props.userReducer;
        return (
            <Bound>
                {/* Header */}
                <SubHeaderControl 
                    searchFilter={this.searchFilter}
                    data={subHeaderData.permission}
                    toggleFilter={() => { this.props.togglePermissionFilter() }}
                    onAddMoreSubmit={()=>{this.props.showAddUserPopup()}}/>
                {/* Table User */}
                {
                    this.state.users.content && this.state.users.content.length>0?
                    <TableControl 
                        filterUserReducer = {this.props.userReducer}
                        data={this.state.users}
                        onShowControl={this.props.onShowControl}
                        filterUser = {this.props.filterUser}
                        />
                    :
                    <EmptyControl 
                        alertContent={alert}
                        content={emptyData.permission}/>
                }
            </Bound>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch,) => {
    return {
        filterUser: (dataFilter, pageSize, pageNumber) => dispatch(filterUser(dataFilter, pageSize, pageNumber)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PermissionContainer)