import imagekiosk from '../images/kiosk_advertising.jpg'
import imagetablet from '../images/tablet_advertising.svg'

export const branchData = [
    {
        index: '0',
        value: 'PGD Bến Thành',
    },
    {
        index: '1',
        value: 'PGD Trần Hưng Đạo',        
    },
    {
        index: '2',
        value: 'PGD Bến Nghé'
    },
    {
        index: '3',
        value: 'PGD Hóc Môn'
    }
   
]
export const typeAds = [
    {
        type: 'KIOSK'
    },
    {
        type: 'GDV'
    },
    {
        type: 'LCD'
    }
]
export const deviceKioskContainer = [
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 4/2019',
        'apply' : 'Tất cả PGD',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh uoc mo',
        'describe' : 'Chiến dịch truyền thông tháng 5/2019',
        'apply' : 'PGD Hóc Môn',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 4/2019',
        'apply' : 'Tất cả PGD',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh uoc mo',
        'describe' : 'Chiến dịch truyền thông tháng 5/2019',
        'apply' : 'PGD Hóc Môn',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh uoc mo',
        'describe' : 'Chiến dịch truyền thông tháng 5/2019',
        'apply' : 'PGD Hóc Môn',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 4/2019',
        'apply' : 'Tất cả PGD',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh uoc mo',
        'describe' : 'Chiến dịch truyền thông tháng 5/2019',
        'apply' : 'PGD Hóc Môn',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagekiosk,
        'name' : 'Cất cánh dễ dàng',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
]

export const deviceTabletContainer = [
    {
        'image' : imagetablet,
        'name' : '0% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 4/2019',
        'apply' : 'Tất cả PGD',
    },
    {
        'image' : imagetablet,
        'name' : '3% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 5/2019',
        'apply' : 'PGD Hóc Môn',
    },
    {
        'image' : imagetablet,
        'name' : '5% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagetablet,
        'name' : '7% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagetablet,
        'name' : '9% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagetablet,
        'name' : '11% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagetablet,
        'name' : '0% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 4/2019',
        'apply' : 'Tất cả PGD',
    },
    {
        'image' : imagetablet,
        'name' : '3% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 5/2019',
        'apply' : 'PGD Hóc Môn',
    },
    {
        'image' : imagetablet,
        'name' : '5% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagetablet,
        'name' : '7% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagetablet,
        'name' : '9% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 6/2019',
        'apply' : 'PGD Trần Hưng Đạo',
    },
    {
        'image' : imagetablet,
        'name' : '11% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagetablet,
        'name' : '11% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 7/2019',
        'apply' : 'PGD Bến Nghé',
    },
    {
        'image' : imagetablet,
        'name' : '0% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 4/2019',
        'apply' : 'Tất cả PGD',
    },
    {
        'image' : imagetablet,
        'name' : '3% Lãi suất trả góp tại Wall Street',
        'describe' : 'Chiến dịch truyền thông tháng 5/2019',
        'apply' : 'PGD Hóc Môn',
    }
]
