import React, { Component } from 'react'
import styled from 'styled-components';

import icon_down from '../../../../images/ic_arrow_down_gray.svg'
const BoundStyle = styled.div`
    display:flex;
    flex-direction:column;
    /* width: 100%; */
    /* margin-bottom: 20px; */
    position: relative;
    .title-text {     
        font-family: 'SVN-Gilroy';   
        font-size: 11px;
        font-weight: bold;        
        color: #005993;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    .dropdown-control {   
        outline: none; 
        font-family: 'SVN-Gilroy';
        padding: 0 35px 0 10px;        
        height: 44px;
        background-color: #ffffff;
        border: solid 0.5px #c8c8c8;
        box-sizing: border-box;        
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;  
        text-align: left; ;
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-start;
        -moz-appearance: none;
        -webkit-appearance: none;
        white-space: nowrap;
        background-image:url(${icon_down});
        background-repeat: no-repeat;
        background-position: top 20px right 17px;
        position:relative;
        .titleChoise{
        cursor: default;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .content {
    position: absolute;
    min-height:80px;
    max-height: 160px;
    overflow:auto;
    top: 100%;
    width: 100%;
    margin-top:2px;
    border-radius: 4px;
    border: solid 0.5px #c8c8c8;
    left: 0;
    border-radius: 4px;
    background-color: #ffffff;
    z-index:999;
    box-shadow: 0px 0px 2px #fafafa;
    .item{
        padding: 10px;
        cursor: default;
        &:hover{
            background-color: #005993;
            color:#fff;
        }
    }
    }

    }
    .dropdown-menu{  
        font-family: 'SVN-Gilroy';
        display: flex;  
        flex:1;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;  
        border-radius: 4px;
        background-color: #ffffff;
        width: 100%;
        padding: 10px 0;       
        .menu {
            font-family: 'SVN-Gilroy';
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #000000;  
            cursor: pointer;          
        }
    }  
    select:required:invalid {
        color: gray;
    }
    option[value=""][disabled] {
        display: none;
    }
`;
export default class SingleChoiseDropDownControl extends Component {
  state = {
    data: this.props.data,
    item: this.props.defaultValue ? this.props.defaultValue : ''
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.setState({ data })
    }
  }
  isMouseEnter = false;
  handleChange = (e) => { }
  choiseText = (item) => {
    if (typeof item === 'object' && item !== null) {
      this.setState({ item: item.name })
    } else {
      this.setState({ item: item })
    }
    this.props.getText(item)
  }

  checkShow = () => this.props.idShow === this.props.id

  onClick = () => {
    if (this.checkShow()) {
      this.props.setIdShow("")
    }
    else {
      this.props.setIdShow(this.props.id)
    }
  }
  render() {
    return (
      <BoundStyle className = 'singlechoise'>
        <p className='title-text'>{this.props.title}</p>
        <React.Fragment>
          <div
            className="dropdown-control"
            onClick={() => this.onClick()}
            tabIndex="0"
            onBlur={() => this.props.setIdShow("")}
          >
            <div className="titleChoise">
              {this.state.item ? this.state.item : this.props.titleChoise}
            </div>
            {
              this.state.data && this.state.data.length > 0 &&
            <div className='block_content'
            >
              {
                this.props.idShow === this.props.id &&
                <div className="content">
                  {this.state.data && this.state.data.length > 0
                    ? this.state.data.map((item, index) => {
                      if (typeof item === 'object' && item !== null)
                        return (
                          <div onClick={() => this.choiseText(item)}
                            className='item' key={index}>
                            {item.name}
                          </div>
                        )
                      return (
                        <div onClick={() => this.choiseText(item)}
                          className='item' key={index}>
                          {item}
                        </div>
                      )
                    })
                    : null
                  }
                </div>
              }
            </div>
            }
          </div>
        </React.Fragment>
      </BoundStyle>
    )
  }
}
