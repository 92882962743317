import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import MainContainer from './ui/MainContainer'

class App extends React.Component {

  render() {
    return (
      <Router>
        <MainContainer/>
      </Router>
    );
  }

}


export default App