import React, { Component } from "react";
import styled from "styled-components";
import icon from "./../../../../images/ic_add_white.svg";

const Bound = styled.div`
  display: flex;
  align-items: center;
  .add {
    padding: 0 20px 0 10px;
    height: 36px;
    border-radius: 18px;
    background-color: #005993;
    border: none;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    font-family: SVN-Gilroy;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    .text{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default class AddMore extends Component {
  render() {
    const { onSubmit } = this.props;
    return (
      <Bound>
        <button
          type="button"
          className="add"
            onClick={onSubmit ? onSubmit : () => {}}
          >
          <img src={icon} alt="add" />
          <p className='text'>
            {this.props.namebtn}
          </p>
        </button>
      </Bound>
    );
  }
}
