import React, { Component } from 'react';
import styled from 'styled-components';

//Images
import bg_finger from '../../../../images/bg_finger.svg';
import default_user from '../../../../images/image-1.png';
import { dataFunction } from '../../../../data/data';

const Bound = styled.div`
     display: flex;
    width: 100%;
    .biometric-face{
        flex: 1;
        padding: 24px 14px 12px 20px;
        position: relative;
        p{
            padding-top:12px;
            font-family: SVN-Gilroy;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            color: #979797;
        }
        .face-id{
            width: 140px;
            height: 140px;
            border-radius: 5px;
            border: solid 2px #ffffff;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 5px;
            }
        }
        .update-last{
            position: absolute;
            bottom: 12px;
            font-family: SVN-Gilroy;
            font-size: 12px;
            font-weight: 500;
            p{
                color: #222222;
                font-size: 13px;
            }
            span{
                color: #979797;
            }
        }
    }
    .biometric-finger{
        font-family: SVN-Gilroy;
        flex: 4;
        position: relative;
        height: 352px;
        .show-finger{
            .finger{
                position: absolute;
                bottom: 12px;
                left: 12px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                p{
                    font-family: SVN-Gilroy;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.3;
                    opacity: 0.5;
                    color: #ffffff;
                }
                p:nth-child(2){
                    padding-right: 31px;
                }
            }
            .status{
                display: flex;
                position: absolute;
                top: 13px;
                right: 20px;
                .update{
                    display: flex;
                    align-items: center;
                    &:not(:nth-child(1)){
                        padding-left: 10px
                    }
                }
                .circle{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
                p{
                    padding-left: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.3;
                    color: #ffffff;
                }
            }
        }
    }
`

export default class UserBiometric extends Component {
    render() {
        return (
            <Bound>
                <div className = 'biometric-face'>
                    <div className = 'face-id'>
                        <img src={default_user} alt="faceID"/>
                    </div>
                    <p>(*) Hình ảnh dùng để nhận diện khách hàng tại thiết bị Kiosk</p>
                    <div className = 'update-last'>
                        <p>Cập nhật lần cuối:</p>
                        <span>08:36 18/06/19</span>
                    </div>
                </div>
                <div className = 'biometric-finger'>
                    <div className = 'show-finger'>
                        <img src = {bg_finger} alt = 'bg_finger' className = 'bg_finger'/>
                        <div className = 'finger'>
                            <p>Bàn tay trái</p>
                            <p>Bàn tay phải</p>
                        </div>
                        <div className = 'status'>
                            {
                                dataFunction.map((functon, i) => {
                                    return (
                                        <div className = 'update' key ={i}>
                                            <div className = 'circle'
                                            style = {
                                                    (()=>{
                                                        if(functon.type === 1){
                                                            return {backgroundColor: '#1477ec'}
                                                        }else if(functon.type === 2){
                                                            return {backgroundColor: '#f31919'}
                                                        }else if(functon.type === 3){
                                                            return {backgroundColor: '#0fb11f'}
                                                        }
                                                    })()
                                                }
                                            ></div>
                                            <p>{functon.content}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Bound>
        )
    }
}
