import Axios from 'axios';

import * as types from './types';
import { host } from "../host";

// api
const apiCreateKiosk = host + "admin/kiosk/create";
const apiUpdateKiosk = host + "admin/kiosk/update-name";
const apiGetAllKiosk = host + "admin/kiosk/getAll";
const apiConnectKiosk = host + "admin/kiosk/connect";
const apiDisconnectKiosk = host + "admin/kiosk/disconnect";
const apiGenerateKeyKiosk = host + "admin/kiosk/generate-keys";
const apiDeleteKiosk = host + "admin/kiosk/delete";

/**
 * 
 * @param {string*} idOffice: if of office
 * @param {string*} nameKiosk: name of new kiosk
 */
export const createKiosk = (idOffice, nameKiosk) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    AtOffice: idOffice,
    Name: nameKiosk
  })

  try {
    const res = await Axios.post(apiCreateKiosk, queryDataFormat);

    console.log(res);
    const {data} = res;

    dispatch({
      type: types.CREATE_KIOSK,
      data
    })

  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.CREATE_KIOSK_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string*} idKiosk id of kiosk need update
 * @param {string*} nameKiosk new name of kiosk
 */
export const updateKiosk = (idKiosk, nameKiosk) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    ID: idKiosk,
    Name: nameKiosk
  });

  try {
    const res = await Axios.post(apiUpdateKiosk, queryDataFormat);

    console.log(res);
    const {data} = res;

    dispatch({
      type: types.UPDATE_KIOSK,
      data
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.UPDATE_ADS_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string} idOffice id of office
 * @param {number} pageSize size of a page
 * @param {number} pageNumber number of page
 */
export const getAllKiosk = (idOffice, pageSize, pageNumber) => async dispatch => {
  const formatQueryData = JSON.stringify({
    AtOffice: idOffice,
    PageSize: pageSize,
    PageNumber: pageNumber
  })

  try {
    const res = await Axios.post(apiGetAllKiosk, formatQueryData);
    console.log(res);
    const {data} = res;
    dispatch({
      type: types.GET_ALL_KIOSK,
      listKiosk: data.ListKiosk ? data.ListKiosk: [],
      total: data.Total
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.GET_ALL_KIOSK_ERR,
      err
    })
  }
}
// /**
//  * 
//  * @param {{
//  * Username: string,
//  * Password: string,
//  * Name: string, - name of kiosk need connect
//  * Identity: string - id of kiosk need connect
//  * }} dataConnect 
//  */
// export const connectKiosk = (dataConnect) => async dispatch => {
//   const queryDataFormat = JSON.stringify({
//     ...dataConnect
//   })

//   try {
//     const res = await Axios.post(apiConnectKiosk, queryDataFormat);

//     console.log(res);
//     const {data}  = res;

//     dispatch({
//       type: types.CONNECT_KIOSK,
//       data
//     })
//   }
//   catch(err){
//     console.log(err);
//     dispatch({
//       type: types.CONNECT_KIOSK_ERR,
//       err
//     })
//   }
// }

// export const disconnectKiosk = (idKiosk) => async dispatch => {
//   const queryDataFormat = JSON.stringify({
//     ID: idKiosk
//   })
//   try{
//     const res = await Axios.post(apiDisconnectKiosk, queryDataFormat);
    
//     console.log(res);
//     dispatch({
//       type: types.DISCONNECT_KIOSK
//     })
//   }
//   catch(err){
//     console.log(err);
//     dispatch({
//       type: types.DISCONNECT_KIOSK_ERR,
//       err
//     })
//   }

// }

/**
 * 
 * @param {string} idOffice id of office contain kiosk
 * @param {string} idKiosk id of kiosk need generate key
 */
export const generateKeyConnectKiosk = (idOffice, idKiosk) => async dispatch => {
  const formatQueryData = JSON.stringify({
    OfficeID: idOffice,
    KioskID: idKiosk
  })

  try {
    const res = await Axios.post(apiGenerateKeyKiosk, formatQueryData);
    // console.log(res);
    
    const {data} = res;

    dispatch({
      type: types.GENERATE_KEY_KIOSK,
      data
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.GENERATE_KEY_KIOSK_ERR,
      err
    })
  }
}

export const deleteKiosk = (idKiosk) => async dispatch => {
  const formatQueryData = JSON.stringify({
    KioskID: idKiosk
  })

  try {
    const res = await Axios.post(apiDeleteKiosk, formatQueryData);
    console.log(res);
    const {data} = res;

    dispatch({
      type: types.DELETE_KIOSK,
      data
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.DELETE_KIOSK_ERR,
      err
    })
  }

}