import React, { Component } from 'react';
import styled from 'styled-components';
import icon_check from '../../../../images/check.svg'
import icon_down from '../../../../images/ic_arrow_down_gray.svg'

const heightControl=44;

const Bound = styled.div`
  display:flex;
  flex-direction:column;
  position: relative;
  font-family: 'SVN-Gilroy';
  font-size: 14px;
  font-weight: 500;
  height:${heightControl}px;
  .titleChoise{
    display: flex;
    flex:1;
    height:100%;
    align-items:center;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    border: solid 0.5px #c8c8c8;
    background-color: #ffffff;
    cursor: default;
    padding: 0 45px 0 10px;
    background-image:url(${icon_down});
    background-repeat:no-repeat;
    background-size:10px 6px;
    background-position:center right 17px;
  }
  .dropdown-menu{  
    display: flex;
    flex:1;
    flex-direction: column;
    border-radius: 4px;
    background-color: #ffffff;
    position:absolute;
    top:46px;
    left:0;
    border-radius: 4px;
    border: solid 0.5px #c8c8c8;
    background-color: #ffffff;
    z-index:10;
    width:100%;
    .item {
      display:flex;
      align-items:center;
      height:44px;
      padding: 0 10px;
      color: #000000;  
      cursor: pointer;
      position:relative;
      img{
        position:absolute;
        right:17px;
      }       
    }
  }
  .dropdown-menu-sub{  
    display: flex;
    flex:1;
    flex-direction: column;
    border-radius: 4px;
    background-color: #ffffff;
    position:absolute;
    top:46px;
    right:-245px;
    border-radius: 4px;
    border: solid 0.5px #c8c8c8;
    background-color: #ffffff;
    z-index:10;
    width:240px;
    max-height:calc(${heightControl}px * 8);
    overflow:hidden;
    .scroll{
      display:flex;
      flex:1;
      width:240px;
      /* padding-right:20px; */
      flex-direction:column;
      /* overflow:auto; */
      .item-sub {
        display:flex;
        align-items:center;
        height:44px;
        padding: 0 35px 0 8px;
        color: #000000;  
        cursor: pointer;
        position:relative;
        .text_multi {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        img{
          position:absolute;
          right:17px;
        }  
      }    
    }
  }
`

const data=[
  {
    Name:'Tất cả giao dịch',
    ID:-1
  }
]

export default class MultichoiceDropDown extends Component {
  onMouseEnter=false;
  state = {
    mainData: data,
    subData:{},
    choiceTitle:this.props.valueDefault ? this.props.valueDefault : data[0].name,
    indexChoice:0,
    subChoice:[],
    isShowDropdown:false,
    isShowDropdownSub:false,
  }
  componentDidMount(){
    let {data, dataChoice} = this.props
    // console.log('huhuhu',dataChoice);
    
    if(data){
      let {mainData} = this.state
      data.forEach(item => {
        let temp=mainData.find(d=>d.ID===item.ID)
        // console.log(temp)
        if(!temp){
          mainData.push(item)
        }
        // console.log(mainData)
      });
    }
    if(dataChoice){
      this.setState({
        subChoice:dataChoice
      })
    }
    else{
      this.checkAll()
    }
    document.addEventListener('click',this.handleClick)
  }
  componentWillUnmount(){
    document.removeEventListener('click',this.handleClick)
  }
  handleClick=()=>{
    if(!this.onMouseEnter){
      this.setState({isShowDropdown:false,isShowDropdownSub:false})
    }
  }
  checkAll(){
    let {mainData, subChoice} = this.state
    this.setState({indexChoice:0, choiceTitle:mainData[0].Name},()=>{
      subChoice=[]
      if(mainData&&mainData.length>1){
        for(let i=1;i<mainData.length;i++){
          if(mainData[i].sub){
            let sub=[]
            // console.log(mainData[i])
            for(let j=0;j<mainData[i].sub.length;j++){
              sub.push(mainData[i].sub[j].ID)
            }
            subChoice.push({ ID:i, sub })
          }
        }
      }
      this.setState({
        subChoice,
        isShowDropdown:false,
        isShowDropdownSub:false
      },()=>{
        
      })
    })
  }
  onClick(){
    let {isShowDropdown} = this.state
    if(isShowDropdown){
      this.setState({isShowDropdown:false, isShowDropdownSub:false})
    }
    else{
      this.setState({isShowDropdown:true})
    }
  }
  renderDropdown(){
    let {mainData, subChoice} = this.state
    if(mainData && mainData.length>0){
      return mainData.map((item, index) => {
        let count=-1;
        if(subChoice){
          let choice=subChoice.find(sub=>sub.ID===item.ID)
          if(choice){
            count=choice.sub.length
          }
        }
        return (
          <div onClick={() => this.choiseItem(index)}
            className='item' key={index}>
            { item.Name }
            {
              count>=0&&
              <p style={{marginLeft:'4px'}}>({count})</p>
            }
            {
              count>=0&&
              <img className='icDown' src={icon_down} alt='arrow'/>
            }
          </div>
        )
      })
    }
  }
  choiseItem(index){
    let {mainData, isShowDropdownSub, subChoice, choiceTitle} = this.state
    if(index===0){
      this.checkAll()
    }
    else{
      if(mainData && mainData[index]){
        this.setState({indexChoice:index},()=>{
          if(mainData[index].sub&&mainData[index].sub.length>0){
            if(isShowDropdownSub){
              this.setState({
                isShowDropdownSub:false
              })
            }
            else{
              let count=-1;
              let choice=subChoice.find(sub=>sub.ID===mainData[index].ID)
              if(choice){
                count=choice.sub.length
              }
              choiceTitle=mainData[index].Name +' ('+count+')'
              this.setState({
                choiceTitle,
                subData:mainData[index],
                isShowDropdownSub:true
              })
            }
          }
        })
      }
      else{
        this.setState({
          isShowDropdownSub:false
        })
      }
    } 
  }
  renderDropdownSub(){
    let {subData, subChoice} = this.state
    if(subData && subData.sub && subData.sub.length>0){
      let choice=subChoice.find(sub=>sub.ID===subData.ID)
      return subData.sub.map((item, index) => {
        return (
          <div onClick={() => this.choiseSubItem(subData.ID, item.ID, subData.Name)}
            className='item-sub' key={index}>
              <p className='text_multi'>
            {item.Name}
              </p>
            {
              choice.sub.indexOf(item.ID)>=0&&
              <img src={icon_check} alt='arrow'/>
            }
          </div>
        )
      })
    }
  }
  choiseSubItem(mainID, subID, Name){
    let {subChoice, choiceTitle} = this.state
    let mainChoice=subChoice.find(sub=>sub.ID===mainID) 
    let i = mainChoice.sub.indexOf(subID)
    if(i>=0){
      mainChoice.sub.splice(i,1)
    }
    else{
      mainChoice.sub.push(subID)
    }
    choiceTitle=Name +' ('+mainChoice.sub.length+')'
    this.props.onChangeListChoice(subChoice)
    this.setState({subChoice, choiceTitle})
  }
  render() {
    let {choiceTitle, isShowDropdown, isShowDropdownSub} = this.state
    
    return (
      <Bound
        onMouseEnter={()=>this.onMouseEnter=true}
        onMouseLeave={()=>this.onMouseEnter=false}>
        <div className="titleChoise"
          onClick={() => this.onClick()}>
          {choiceTitle}
        </div>
        {
          isShowDropdown&&
          <div className="dropdown-menu">
            {this.renderDropdown()}
          </div>
        }
        {
          isShowDropdownSub&&
          <div className="dropdown-menu-sub">
            <div style={{overflow:'auto', width:'240px', paddingRight:'20px'}}>
              <div className='scroll'>
                {this.renderDropdownSub()}
              </div>
            </div>
          </div>
        }
      </Bound>
    )
  }
}