import * as types from "../../actions/types";

const initialState = {
  listOffice: [],
  total: 0,
  err: '',
  dataFilter: {},
  alert:''
}

export default function filterOfficeReducer(state = initialState, action) {
  switch (action.type) {
    case types.FILTER_OFFICE:
      return {
        ...state,
        listOffice: action.listOffice,
        total: action.total,
        dataFilter: action.dataFilter,
      }
    case types.FILTER_OFFICE_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.ALERT_CONTENT:
      return {
        ...state,
        alert: action.alert
      }
    default:
      return state
  }
}