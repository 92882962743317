import React, { Component } from 'react'
import styled from 'styled-components'
import GroupButtonControl from './GroupButtonControl'

const Bound = styled.div`
    height: 52px;
    border-radius: 4px;
    background-color: #005993;
    color: #fff;
    display: flex;
    padding: 0 20px;
    align-items: center;
    .subname{
        display: flex;
        flex: 1;
    }
  
`

export default class BlockSubName extends Component {
    state={
        isShowRoom:this.props.isShowRoom
    }
    
    componentWillReceiveProps(n){
        this.setState({isShowRoom:n.isShowRoom})
    }
    render() {
        return (
            <Bound>
                <div className="subname">
                    {this.props.subname}
                </div>
                { this.props.isShowButtonControl &&
                    <GroupButtonControl
                        isShowRoom={this.state.isShowRoom}
                        showAddTransactionRoomPopup={()=>{this.props.showAddTransactionRoomPopup()}}
                        getButtonOn={()=>this.props.getButtonOn()}/>
                }
            </Bound>
        )
    }
}
