import React, { Component } from 'react'
import styled from "styled-components"

// import image
import icClose from "../../../../images/ic_close_gray.svg"

const StyledPopupShowFingerInfoCustomer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: ${props => props.isRegister? "383px": "272px"}; */
  /* height: 248px; */
  background: #ffffff;
  padding: 16px 20px;

  .popup-title {
    font-family: "SVN-Gilroy";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
    color: #000000;
  }

  & > img {
    position: absolute;
    top: 10px;
    right: 10px;

    :hover {
      cursor: pointer;
    }
  }
  

  .popup-finger-detail {
    display: flex;
    align-items: center;
    margin-top: 11px;
    font-family: "SVN-Gilroy";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    color: #000000;
    img {
      width: 18px;
      height: 18px;
      margin-right: 6px;
      background: var(--peacock-blue);
      padding: 5px;
      border-radius: 50%;
    }
  }

  .popup-main {
    display: flex;
    justify-content: space-between;
    margin-top: 19px;

    & h3 {
      margin-bottom: 2px;
      font-family: "SVN-Gilroy";
      font-size: 11px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.26;
      color: var(--peacock-blue);
      text-transform: uppercase;
    }

    & img {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      border: solid 0.5px var(--pinkish-grey);
    }

    &-registration{

      .image-registration{
        img:not(:first-child) {
          margin-left: 8px;
        }
      }
    }

    &-authentication{
      margin-left: 35px;
    }
  }

  .popup-footer {
    font-family: "SVN-Gilroy";
    margin: 8px auto;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.3;
    color: #979797;
  }
`;

class PopupShowFingerInfoCustomer extends Component {

  state = {
    isLeave: true
  }

  render() {
    return (
      <div 
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}

        onMouseDown={() => {
          if(this.state.isLeave){
            this.props.onClosePopup();
          }
        }}
      >
        <StyledPopupShowFingerInfoCustomer
          onMouseLeave={() => this.setState({isLeave: true})}
          onMouseEnter={() => this.setState({isLeave: false})}
        >
          <div className="popup-title">
            Thông tin vân tay
          </div>
          <img src={icClose} alt="close" onClick={this.props.onClosePopup}/>
          <div className="popup-finger-detail">
            <img src="https://source.unsplash.com/random" alt=""/>
            {this.props.fingerDetail}
          </div>
          <div className="popup-main">
            <div className="popup-main-registration">
              <h3>Đăng ký</h3>
              <div className="image-registration">
                {
                  this.props.imgRegister.map((img,key) => 
                    <img src={img} alt="finger" key={key} />              
                  )
                }
              </div>
            </div>
            {
              this.props.imgAuthenticate?
              <div className="popup-main-authentication">
                <h3>Xác thực</h3>
                <img src={this.props.imgAuthenticate} alt="auth" />
              </div>
              :null
            }
          </div>
          <div className="popup-footer">
            {`Cập nhật gần nhất: ${this.props.lastUpdate}`}
          </div>
        </StyledPopupShowFingerInfoCustomer>
      </div>
    )
  }
}

/**
 * props = {
 *  onClosePopup*: function,
 *  fingerDetail*: string, //type of finger - type of hand
 *  imgRegister*: array(string), // array of link finger image registration
 *  imgAuthenticate: string, //link finger image authentication
 *  lastUpdate*: string, //datetime last update
 * }
 */

export default PopupShowFingerInfoCustomer;
