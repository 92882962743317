import * as types from "../../actions/types";

const initialState = {
  kioskInfo: {},//data reponse when create kiosk
  dataUpdate: {},//data reponse when update kiosk
  listKiosk: [], //data reponse when get all kiosk
  total: 0, //data reponse when get all kiosk
  dataConnect: {}, //data reponse when connect kiosk
  dataGenKey: {}, //data reponse when generate key connect
  resDel: {},//data reponse when delete kiosk
  err: ''
}

export default function kioskReducer(state = initialState, action) {
  switch (action.type) {

    case types.CREATE_KIOSK:
      return {
        ...state,
        kioskInfo: action.data,
        err: ''
      }
    case types.CREATE_KIOSK_ERR:
      return {
        ...state,
        err: action.err
      }

    case types.UPDATE_KIOSK:
      return {
        ...state,
        dataUpdate: action.data,
        err: ''
      }
    case types.UPDATE_KIOSK_ERR:
      return {
        ...state,
        err: action.err
      }

    case types.GET_ALL_KIOSK:
      return {
        ...state,
        listKiosk: action.listKiosk,
        total: action.total,
        err: ''
      }
    case types.GET_ALL_KIOSK_ERR:
      return {
        ...state,
        err: action.err
      }

    case types.CONNECT_KIOSK:
      return {
        ...state,
        dataConnect: action.data,
        err: ''
      }
    case types.CONNECT_KIOSK_ERR:
      return {
        ...state,
        err: action.err
      }

    case types.DISCONNECT_KIOSK:
      return {
        ...state,
        err: ''
      }
    case types.DISCONNECT_KIOSK_ERR:
      return {
        ...state,
        err: action.err
      }

    case types.GENERATE_KEY_KIOSK:
      return {
        ...state,
        dataGenKey: action.data,
        err: ''
      }
    case types.GENERATE_KEY_KIOSK_ERR:
      return {
        ...state,
        err: action.err
      }
    
    case types.DELETE_KIOSK:
      return {
        ...state,
        resDel: action.data,
        err:''
      }
    
    case types.DELETE_KIOSK_ERR:
      return {
        ...state,
        err: action.err
      }

    default:
      return state
  }
}