import { combineReducers } from 'redux'
import { adsReducer } from './adsReducer';
import { officeReducer } from './officeReducer';
import userReducer from './userReducer/userReducer';
import counterReducer from './counterReducer/counterReducer';
import kioskReducer from './kioskReducer/kioskReducer';
import LCDReducer from "./LCDReducer/LCDReducer";
import * as types from '../actions/types'

const appReducer = combineReducers({
  ...adsReducer,
  ...officeReducer,
  userReducer,
  counterReducer,
  kioskReducer,
  LCDReducer
})


const rootReducer = (state, action) => {
  // console.log(appReducer)
  if(action.type === types.LOGOUT_USER){
    localStorage.removeItem("dataLogin")
    state = undefined;
    // console.log("logout")
  }
  return appReducer(state, action);
}



export default rootReducer