import getAdsReducer from './getAdsReducer';
import createAdsReducer from './createAdsReducer';
import updateAdsReducer from './updateAdsReducer';
import deleteAdsReducer from './deleteAdsReducer';
import setStatusAdsReducer from './setStatusAdsReducer';
import getFileAdsReducer from './getFileAdsReducer';

export const adsReducer = {
  getAdsReducer,
  createAdsReducer,
  updateAdsReducer,
  deleteAdsReducer,
  setStatusAdsReducer,
  getFileAdsReducer
}