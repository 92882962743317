const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}
const n=n=>{
    return n>9?n:'0'+n
}
export const getRandomTime=()=>{
    return n(randomInteger(0,2)) + ' : ' + n(randomInteger(0,60)) + ' : ' + n(randomInteger(0,60))
}
export const dataCustomerAccurate = [
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'NotAuth',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Unsuccess',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Success',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'NotAuth',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Unsuccess',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Success',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'NotAuth',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Unsuccess',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Success',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Unsuccess',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'NotAuth',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'NotAuth',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'Success',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataType:'NotAuth',timeAuth:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},

]
export const dataBiometric = [
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'NewReg',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'NotReg',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Update',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Del',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Vàng',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Lock',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Unlock',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'NewReg',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Update',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'NotReg',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Unlock',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Unlock',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Del',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Unlock',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'NewReg',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:true},
    {CIFcode:126342788,name:'Trần Văn A',typeCustom:'KHUT Bạc',registorGDV:'Vũ Thị Lan Hương',dataStatus:'Lock',timeUpdate:'14:14 18/08/2019',timeApprove: getRandomTime() ,moreInfo:'Xem thông tin',isLook:false},
]
export const dataType = [
    {type:'NewReg',status:'Đăng ký mới',color:'#0fb11f'},
    {type:'NotReg',status:'Không đăng ký',color:'#f31919'},
    {type:'Update',status:'Cập nhập dữ liệu',color:'#0fb11f'},
    {type:'Del',status:'Xóa dữ liệu',color:'#f31919'},
    {type:'Lock',status:'Khóa dữ liệu',color:'#f31919'},
    {type:'Unlock',status:'Mở khóa dữ liệu',color:'#0fb11f'},
    {type:'NotAuth',status:'Không xác thực',color:'#f31919'},
    {type:'Success',status:'Thành công',color:'#0fb11f'},
    {type:'Unsuccess',status:'Không thành công',color:'#f31919'},
]
export const dataStatus = [
    {type:'Wait' , status: 'Chờ phê duyệt'},
    {type:'Agree' , status: 'Đã phê duyệt'},
]
export const dataCustomer = [
    {typeCustom: 'KHUT Đồng'},
    {typeCustom: 'KHUT Bạc'},
    {typeCustom: 'KHUT Vàng'},
    {typeCustom: 'KHUT Kim Cương'}
]