import Axios from 'axios';

import * as types from './types';
import {host} from "../host";

// api
const apiCreateLCD = host + "admin/lcd/create";
const apiUpdateLCD = host + "admin/lcd/update-name";
const apiGetAllLCD = host + "admin/lcd/getAll";
const apiDeleteLCD = host + "admin/lcd/delete";
const apiGenKeyLCD = host + "admin/lcd/generate-keys";

/**
 * 
 * @param {string} idOffice id of office which create LCD
 * @param {string} nameLCD name of new LCD
 */
export const createLCD = (idOffice, nameLCD) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    AtOffice: idOffice,
    Name: nameLCD
  })

  try {
    const res = await Axios.post(apiCreateLCD, queryDataFormat);
    // console.log(res);
    const {data} = res;
    dispatch({
      type: types.CREATE_LCD,
      data
    })
  }
  catch(err){
    console.log(err)
    dispatch({
      type: types.CREATE_LCD_ERR,
      err
    })
  }
}


/**
 * 
 * @param {string} idLCD id of LCD need update
 * @param {string} nameLCD new name of LCD
 */
export const updateLCD = (idLCD, nameLCD) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    ID: idLCD,
    Name: nameLCD
  })

  try {
    const res = await Axios.post(apiUpdateLCD, queryDataFormat);
    console.log(res);

    const {data} = res;

    dispatch({
      type: types.UPDATE_LCD,
      data
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.UPDATE_LCD_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string} idOffice id of office need get all LCD
 * @param {number} pageSize quantity element in one page
 * @param {number} pageNumber index of page
 */
export const getAllLCD = (idOffice, pageSize, pageNumber) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    AtOffice: idOffice,
    PageSize: pageSize,
    PageNumber: pageNumber
  })

  try {
    const res = await Axios.post(apiGetAllLCD, queryDataFormat);
    console.log(res);

    const {data} = res;

    dispatch({
      type: types.GET_ALL_LCD,
      listLCD: data.ListLCD? data.ListLCD : [],
      total: data.Total
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.GET_ALL_LCD_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string} idLCD id of LCD need delete
 */
export const deleteLCD = (idLCD) => async dispatch => {
  const queryDataFormat = JSON.stringify({
    KioskID: idLCD
  })

  try {
    const res = await Axios.post(apiDeleteLCD, queryDataFormat);
    console.log(res);
    const {data} = res;

    dispatch({
      type: types.DELETE_LCD,
      data
    })
  }
  catch(err){
    console.log(err);
    dispatch({
      type: types.DELETE_LCD_ERR,
      err
    })
  }
}

/**
 * 
 * @param {string} idOffice id of office contain LCD
 * @param {string} idLCD id of LCD need generate key
 */
export const generateKeyConnectLCD = (idOffice, idLCD) => async dispatch => {
  const formatQueryData = JSON.stringify({
    OfficeID: idOffice,
    LcdID: idLCD
  })

  try {
    const res = await Axios.post(apiGenKeyLCD, formatQueryData);
    console.log(res);

    const { data } = res;

    dispatch({
      type: types.GENERATE_KEY_LCD,
      data
    })
  }
  catch (err) {
    console.log(err)
    dispatch({
      type: types.GENERATE_KEY_LCD_ERR,
      err
    })
  }
}