import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

import PopupAdvertiseRoot from './PopupAdvertiseRoot';

import { popupType } from '../../../data/data'
import PopupTransaction from './PopupTransaction';
import PopupConfirm from './PopupConfirm';
import PopupNewImage from './PopupNewImage';
import PopupAddBranch from './PopupAddBranch';
import PopupSuccess from './PopupSuccess';
import PopupAddPermission from './PopupAddPermission';
import PopupControl from './PopupControl';

import FlowAddAdvertisement from './FlowAddAdvertisement';

/* import action */
import { createOffice, updateOffice, deleteOffice, getAllOffice, filterOffice } from '../../../actions/officeAction';
import { createAds, getAds, updateAds, deleteAds, setStatusAds } from '../../../actions/adsAction';
import { deleteUser, filterUser, addUser, updateUser } from '../../../actions/userAction';
import PopupAccountPermission from './PopupAccountPermission';
import PopupDeletePermission from './PopupDeletePermission';
import { formatSize } from '../../../tools';
import FlowOfficeCheckNumber from './FlowOfficeCheckNumber';
import PopupAddCounter from './PopupAddCounter';
import { typeAds } from '../../../data/advertisementData';
// import { types } from '../../../data/dataBranch';
import { createKiosk, getAllKiosk, deleteKiosk, updateKiosk } from '../../../actions/kioskAction';
import { createCounter, updateCounter, deleteCounter, getCounterByOffice } from '../../../actions/counterAction';
import { getAllLCD, createLCD, updateLCD, deleteLCD } from '../../../actions/LCDAction';
// import { UPDATE_LCD } from '../../../actions/types';
// import PopupOutOfLimit from './PopupOutOfLimit';
import PopupAlert from './PopupAlert';
import PopupCheckBiometricCustomer from './PopupCheckBiometric/PopupCheckBiometricCustomer';
import PopupCheckCustomer from './PopupCheckCustomer/PopupCheckCustomer';
// import PopupAlert from './PopupAlert';

const Bound = styled.div`
    width:100%;
    min-height:100vh;
    background-color:${props => props.nonOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.6)'} ;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
`

class PopupContainer extends Component {
  constructor(props) {
    super(props)
    this.isMouseEnterPopup = false;
    // this.nameData=null
    this.state = {
      nonOverlay: false,
      content: null,
    }
  }
  onClosePopup = () => {
    this.props.closePopup()
  }
  componentDidMount() {
    this.getPopupContent(this.props.popupRef);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) { }
  }
  getData = data => {
    let Data = { data }
    Object.assign(this.props.popupRef.data, Data)
    this.props.getData(this.props.popupRef)
  }
   
  // ----------- User
  deleteUser = (id) => {
    this.props.deleteUser(id)
    this.props.filterUser({}, 10, 1)
    this.onClosePopup()
  }
  
  createUser = (data) => {
    this.props.createUser(data)
    this.props.filterUser({}, 10, 1)
  }

  updateUser = (dataUpdate) => {
    this.props.updateUser(dataUpdate)
    this.props.filterUser({}, 10, 1)
  }
// -----------

// delete office + advertisement + deviceKiosk + deviceLcd + CounterOffAdmin
  deleteItem = async (dataNeedDelete) => {
    // console.log(dataNeedDelete);
    if (dataNeedDelete.ID && dataNeedDelete.TypeAds) {
       this.props.deleteAds(dataNeedDelete.ID);      
      let checkTypeAds = typeAds.find(item => item.type === dataNeedDelete.TypeAds)
      if (dataNeedDelete.TypeAds === checkTypeAds.type) {
         this.props.getAds(checkTypeAds.type, 10, 1);
      } else if (dataNeedDelete.TypeAds === checkTypeAds.type){
         this.props.getAds(checkTypeAds.type, 10, 1);
      } else {
       this.props.getAds(checkTypeAds.type, 10, 1);
      }
    }
    // delete office in admin
    else if (dataNeedDelete.ID && dataNeedDelete.OfficeType) {
      if (dataNeedDelete.NumOfCounter > 0 && dataNeedDelete.NumOfKiosk > 0) {
      await  this.props.onShowRemoveBranchError({dataNeedDelete})
      }else {
        await  this.props.deleteOffice(dataNeedDelete.ID);
        await  this.props.filterOffice({},10,dataNeedDelete.currentPage)
      }
    }
    // delete kiosk device + lcd in branch
    else if (dataNeedDelete.ID && dataNeedDelete.type){
      // console.log(dataNeedDelete.type)  
      if (dataNeedDelete.type === "DeviceLcd") {
      await  this.props.deleteLCD(dataNeedDelete.ID )
      await  this.props.getAllLCD(dataNeedDelete.AtOffice, 8, dataNeedDelete.currentPage)
      }else if(dataNeedDelete.type === "DeviceKiosk")
      await this.props.deleteKiosk(dataNeedDelete.ID)
      await this.props.getAllKiosk(dataNeedDelete.AtOffice, 8, dataNeedDelete.currentPage)
    }
    else if(dataNeedDelete.ID && dataNeedDelete.CounterName){
      await this.props.deleteCounter(dataNeedDelete.ID)
      await this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID,8,dataNeedDelete.currentPage)
    }
    this.onClosePopup();
  }  
  // updateAds kiosk
  updateKiosK = (id, dataChange) => {
    if (id && dataChange) {
      this.props.updateAds(id, dataChange)
      let checkTypeAds = typeAds.find(item => item.type === dataChange.TypeAds)
    if (dataChange.type === checkTypeAds.type) {
      this.props.getAds(checkTypeAds.type, 20, 1);
    }else if(dataChange.type === checkTypeAds.type){
      this.props.getAds(checkTypeAds.type, 20, 1);
    }else {
      this.props.getAds(checkTypeAds.type, 20, 1);
    }
      this.onClosePopup();
    }
  }
  updateOffice = async (data,currentPage) => {
    let dataUpdate = {
      ID: data.id,
      Name: data.NameTransactionRoom,
      Address: data.AddressTransactionRoom,
      Province:data.city,
      District:data.district,
      Ward:data.ward,
      OfficeType:data.typeTrans
    }
  await  this.props.updateOffice(dataUpdate);
  await  this.props.filterOffice({},10,currentPage)
  }

  createOffice = async (name, address, province, district, ward, officeType) => {
    await this.props.createOffice(name, address, province, district, ward, officeType);
  }

  /// Counter
  acceptActionCounter = async (data,dataModify) => {
    if(dataModify){
      await this.props.updateCounter(data)
      await this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID,8,dataModify.currentPage)
    }else{
      await this.props.createCounter(data)
      await this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID,8,1)
    }
  }


  /// Create + update TradingRoom (Kiosk + LCD)
  acceptActionTradingRoom = async (id,name,type,currentPage) => {
    if (id === this.props.userReducer.dataLogin.OfficeID) {
      if (type === 'DeviceKiosk') {
        await this.props.createKiosk(id,name)
        await this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8,1)
        // Promise.all([this.props.createKiosk(id,name), this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8,1)])
      }else{
        await this.props.createLCD(id,name)
        await this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8,1)
        // Promise.all([this.props.createLCD(id,name), this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8,1)])
      }
      }else {
        if (type === 'DeviceKiosk') {
          await this.props.updateKiosk(id,name)
          await this.props.getAllKiosk(this.props.userReducer.dataLogin.OfficeID, 8,currentPage)
          // Promise.all([this.props.updateKiosk(id,name), this.props.getAllKiosk (this.props.userReducer.dataLogin.OfficeID, 8,currentPage)])
        } else if(type === 'DeviceLcd') {
          await this.props.updateLCD(id,name)
          await this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8,currentPage)
          // Promise.all([this.props.updateLCD(id,name), this.props.getAllLCD(this.props.userReducer.dataLogin.OfficeID, 8,currentPage)])
        }
    }
  }

  getPopupContent(popupRef) {
    let content = null;
    let nameData
    if(popupRef.data){
      if(popupRef.data.nameLCD){
        nameData=popupRef.data.nameLCD
      }else if(popupRef.data.dataNeedDelete){
        if(popupRef.data.dataNeedDelete.Name){
          nameData=popupRef.data.dataNeedDelete.Name
        }else{
          nameData=popupRef.data.dataNeedDelete.CounterName
        }
      }
      else{
        nameData='Chưa được đặt tên'
      }
    }
    switch (popupRef.type) {
      case popupType.configAdvertiseTable:
        {
          content = <PopupAdvertiseRoot
            title={popupRef.data.title}
            titleHeader='Thêm mới quảng cáo'
            outPopup={() => {
              this.onClosePopup()
            }} />
          break;
        }
      case popupType.configTransaction:
        {
          content = <PopupTransaction
            alertPopup = {this.props.alertPopup}
            titleHeader={popupRef.data.title}
            dataNeedModify={popupRef.data.dataNeedModify}
            outPopup={() => {
              this.onClosePopup()
            }}
            handleOnClick={
              popupRef.data.dataNeedModify
              ? this.updateOffice
              : this.createOffice
            }
          />
          break;
        }
      case popupType.configAddBranch: {
        content = <PopupAddBranch
          // typeAdvert={popupRef.data.typeAdvert}
          titleHeader={popupRef.data.titleHeader}
          title = {popupRef.data.title}
          type={popupRef.data.type}
          placeHolder = {popupRef.data.placeHolder}
          dataNeedModify={popupRef.data.dataNeedModify}   outPopup={() => {
            this.onClosePopup()
          }}
          // getData={(data) => this.getData(data)}
          idOffice={this.props.userReducer.dataLogin.OfficeID}
        handleOnClick={this.acceptActionTradingRoom}         />
        break;
      }
      case popupType.configSuccess: {
        content = <PopupSuccess
          titleHeader='Tạo tài khoản thành công'
          outPopup={() => {
            this.onClosePopup()
          }}
        />
        break;
      }
      case popupType.configAddPermission: {
        content = <PopupAddPermission
          titleHeader='Thêm mới tài khoản'
          contentTitle='Tìm kiếm tài khoản Vietinbank'
          outPopup={() => {
            this.onClosePopup()
          }}
          addPermissionUser = {this.createUser}
        />
        break;
      }
      case popupType.configConfirm:
        {
          content = <PopupConfirm
            title={popupRef.data.title}
            dataNeedDelete={popupRef.data.dataNeedDelete} 
            nameData={nameData}           
            // nameData={popupRef.data.dataNeedDelete.Name}
            // nameKiosk={popupRef.data.dataNeedDelete.Name}
            // nameLCD={popupRef.data.nameLCD}
            contentDelete={popupRef.data.contentDelete}
            width={popupRef.data.widthPopup}
            acceptAction={this.deleteItem}
            cancelAction={() => {this.onClosePopup()}}
          />
          break;
        }
      case popupType.configAdvertiseNewImage:
        {
          content = <PopupNewImage
            title='Thêm mới quảng cáo'
            outPopup={() => {
              this.onClosePopup()
            }} />
          break;
        }
      case popupType.popupControlType:
        {
          content = <PopupControl
            offsetPopup={popupRef.data.offsetPopup}
            from={popupRef.data.from}
            dataReceive={popupRef.data.dataReceive}
            onModify={this.props.onModify}
            onRemove={
              this.props.onRemoveConfirm
            } />
          this.setState({ nonOverlay: true })
          break;
        }
      case popupType.configAdvertisementPopup:
        {
          content = <FlowAddAdvertisement
            typeAdvert={popupRef.data.typeAdvert}
            outPopup={() => this.onClosePopup()}
            handleOnSubmit={this.props.createAds}
            handleReLoadData={this.props.getAds}
          />
          break;
        }
      case popupType.configFlowCheckOffice:
        {
          content = <FlowOfficeCheckNumber
            // typeAdvert={popupRef.data.typeAdvert}
            contentDelete={popupRef.data.contentDelete}
            branchName={popupRef.data.dataShow.dataNeedDelete.Name}
            NumOfCounter={popupRef.data.dataShow.dataNeedDelete.NumOfCounter}
            NumOfKiosk={popupRef.data.dataShow.dataNeedDelete.NumOfKiosk}
            outPopup={() => this.onClosePopup()}
          />
          break;
        }
      case popupType.configAccountPermission:
        {
          content = <PopupAccountPermission
            from={popupRef.data.form}
            titleHeader='Thông tin tài khoản'
            outPopup={() => this.onClosePopup()}
            dataNeedModify={popupRef.data.dataNeedModify}
            dataNeedDelete={popupRef.data.dataNeedDelete}
            handleOnClick={
              popupRef.data.dataNeedModify ? () => { } : this.props.createOffice
            }
            // updatePermissionUser = {this.updateUser}
            updatePermissionUser={
              popupRef.data.dataNeedModify ?
                (data) => { 
                  this.updateUser(data)
                } : ''
            }
            />
          break;
        }
      case popupType.configDeletePermission:
        {
          content = <PopupDeletePermission
              from = {popupRef.data.form}
              title={popupRef.data.title}
              dataNeedDelete={popupRef.data.dataNeedDelete}
              cancelAction = {() => this.onClosePopup()}
              titleHeader = 'Thông tin tài khoản'
              outPopup={() => this.onClosePopup()}
              dataNeedModify = {popupRef.data.dataNeedModify}
              handleOnClick = {
                  popupRef.data.dataNeedModify ? () => {} : this.props.createOffice
              }
              acceptAction={(id, role) => this.deleteUser(id, role)}
            />
          break;
        }
      case popupType.configAdvertiseKiosk:
        {
          // console.log(popupRef.data.dataNeedModify)
          content = <PopupAdvertiseRoot
            title={popupRef.data.dataNeedModify.titlePopup}
            TitleAds={popupRef.data.dataNeedModify.titleAds}
            descTitle={popupRef.data.dataNeedModify.descTitle}
            titleConfirm={popupRef.data.titleConfirm}
            formatSize={formatSize}
            imgInfo={
              {
                url: popupRef.data.dataNeedModify.fileSrc,
                name: popupRef.data.dataNeedModify.FileName,
                width: popupRef.data.dataNeedModify.width,
                height: popupRef.data.dataNeedModify.height,
                size: popupRef.data.dataNeedModify.fileSize,
              }
            }
            handleOnSubmit={this.props.updateAds}
            handleReLoadData={this.props.getAds}
            dataNeedModify={popupRef.data.dataNeedModify}
            outPopup={() => {
              this.onClosePopup()
            }}
            />
          break;
        }
        case popupType.configAddCounter:
          {
            content = <PopupAddCounter
            outPopup={() => this.onClosePopup()}
            dataNeedModify = {popupRef.data.dataNeedModify}
            acceptActionCounter = {(data)=>this.acceptActionCounter(data,popupRef.data.dataNeedModify)}
            titleHeader={popupRef.data.titleHeader}
              />
            break;
          }
        case popupType.configOutOfLimit:
          {
            content = <PopupAlert
              title={popupRef.data.title}
              outPopup={() => {
                this.onClosePopup()
              }} />
            break;
          }
        case popupType.configCheckBiometricCustomer:
          {
            content = <PopupCheckBiometricCustomer
              dataUser = {popupRef.data.dataUser}
              dataBiometric = {popupRef.data.dataBiometric ? popupRef.data.dataBiometric : null}
              outPopup={() => {
                this.onClosePopup()
              }} 
              />
            break;
          }
        case popupType.configCheckCustomer:
          {
            content = <PopupCheckCustomer
              outPopup={() => {
                this.onClosePopup()
              }} 
              />
            break;
          }
        default:
          break;
    }
    this.setState({ content })
  }
  render() {
    return (
      <Bound nonOverlay={this.state.nonOverlay}

        onMouseDown={() => {
          if (!this.isMouseEnterPopup) {
            this.onClosePopup();
          }
        }}
      >
        <div
          onMouseEnter={() => {
            return this.isMouseEnterPopup = true
          }}
          onMouseLeave={() => {
            return this.isMouseEnterPopup = false
          }}
        >
          {this.state.content}
        </div>
      </Bound>
    )
  }
}
const mapStateToProps = (state) => ({
  userReducer: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
  //// office
  createOffice: (name, address, province, district, ward, officeType) => dispatch(createOffice(name, address, province, district, ward, officeType)),
  filterOffice:(datafilter,pageSize,pageNumber)=>dispatch(filterOffice(datafilter,pageSize,pageNumber)),
  getAllOffice :() => dispatch(getAllOffice()),
  updateOffice: (dataUpdate) => dispatch(updateOffice(dataUpdate)),
  deleteOffice:(id) => dispatch(deleteOffice(id)),
  //// ads
  createAds: (title, desc, upload, typeAds, active, applyOffice = undefined) => dispatch(createAds(title, desc, upload, typeAds, active, applyOffice)),
  getAds: (typeAds, pageSize, pageNumber) => dispatch(getAds(typeAds, pageSize, pageNumber)),
  updateAds: (id, dataChange) => dispatch(updateAds(id, dataChange)),
  deleteAds: (id) => dispatch(deleteAds(id)),
  setStatusAds: (id, status) => dispatch(setStatusAds(id, status)),
  //// user
  deleteUser: (id) => dispatch(deleteUser(id)),
  filterUser: (dataFilter, pageSize, pageNumber) => dispatch(filterUser(dataFilter, pageSize, pageNumber)),
  createUser: (email, fullName, role, atOffice, bankID) => dispatch(addUser(email, fullName, role, atOffice, bankID)),
  updateUser: (id, dataChange) => dispatch(updateUser(id, dataChange)),
  /// TrandingRoom
  createKiosk: (idOffice, nameKiosk) => dispatch(createKiosk(idOffice, nameKiosk)),
  updateKiosk: (idKiosk, nameKiosk) => dispatch(updateKiosk(idKiosk, nameKiosk)),
  getAllKiosk: (idOffice, pageSize, pageNumber) =>dispatch(getAllKiosk(idOffice, pageSize, pageNumber)),
  deleteKiosk: (idKiosk) => dispatch(deleteKiosk(idKiosk)),
  createLCD:(idOffice, nameLCD)=>dispatch(createLCD(idOffice, nameLCD)),
  updateLCD:(idLCD, nameLCD)=>dispatch(updateLCD(idLCD, nameLCD)),
  deleteLCD:(idLCD)=>dispatch(deleteLCD(idLCD)),
  getAllLCD:(idOffice, pageSize, pageNumber)=>dispatch(getAllLCD(idOffice, pageSize, pageNumber)),
  //// counter
  createCounter: (dataCreate) => dispatch(createCounter(dataCreate)),
  updateCounter: (dataUpdate) => dispatch(updateCounter(dataUpdate)),
  deleteCounter: (counterId) => dispatch(deleteCounter(counterId)),
  getCounterByOffice: (officeId, pageSize, pageNumber) => dispatch(getCounterByOffice(officeId, pageSize, pageNumber))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupContainer);