import React, { Component } from 'react'
import styled from 'styled-components'

import HeaderContainer from './Header/HeaderContainer'
import MenuContainer from './Menu/MenuContainer'
import MainScreenContainer from './MainScreen/MainScreenContainer'
// import { Redirect } from 'react-router-dom';
// import Test from '../../Test';
import PopupShowBiometricHistory from './Popup/PopupCheckBiometric/PopupShowBiometricHistory';
import PopupShowFingerInfoCustomer from './Popup/PopupCheckBiometric/PopupShowFingerInfoCustomer';


const Bound = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    position:relative;
    .main-page{
        display:flex;
        flex:1;
        flex-direction:row;
        overflow:hidden;
    }
`
export default class MainPage extends Component {
    state = {
        // redirect: true
    }
    render() {
        // if (this.state.redirect) return <Redirect to="/" />
        return (
            <Bound>
                <HeaderContainer 
                    dataLogin={this.props.dataLogin} 
                    // isLogout={() => this.props.isLogout()} 
                    logoutUser={this.props.logoutUser}
                />
                <div className='main-page'>
                    <MenuContainer dataLogin={this.props.dataLogin} />
                    <MainScreenContainer dataLogin={this.props.dataLogin} />
                </div>
                {/* <Test /> */}
                {/* <PopupShowBiometricHistory /> */}
                {/* <PopupShowFingerInfoCustomer
                    fingerDetail="Ngón cái - bàn tay phải"
                    imgRegister={[
                        "https://source.unsplash.com/random",
                        "https://source.unsplash.com/random"
                    ]}
                    imgAuthenticate="https://source.unsplash.com/random"
                    lastUpdate="09:45 18/06/19"
                /> */}
            </Bound>
        )
    }

    componentDidMount() {
        // this.setState({ redirect: false });
        
    }

}
