
import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
// Img
import ic_close from '../../../images/ic_close_gray.svg';
// Component
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl'
// Data
import { dataStatus, dataCustomer, dataType } from '../../../data/dataBiometric';

const Bound = styled.div `
    width:100%;
    min-height:100vh;
    position:absolute;
    top:0;
    left:0;
    z-index:10;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    .popup-filter-bound{
        display:flex;
        flex-direction:column;
        width:200px;
        padding: 30px 20px 0 20px;
        height: calc(100vh - 60px - 80px - 82px);
        background-color:white;
        position:absolute;
        bottom:0;
        right:-240px;
        transition:right 0.5s;
        align-items:center;
        .div_check{
            width:200px;
            margin: 0 0 20px 0;
        }
        .div_header{
            width:100%;
            margin: 0 0 20px 0;
            display:flex;
            justify-content:space-between;
            align-items: center;
            .title{
                font-size: 14px;
                font-weight: bold;
                color: #000000;
                width: 64px;
                height: 36px;
                border-radius: 18px;
                background-color: #005993;
                display:flex;
                justify-content: center;
                align-items: center;
                color:#ffffff;
                cursor: pointer;
            }
            .close-btn{
                width:20px;
                height:20px;
                cursor: pointer;
            }
        }
    }
`
export default class PopupBiometricFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idShow: "",
            dataStatus: dataStatus,
            dataCustomer: dataCustomer,
            dataType: dataType, 
            Status: '',
            Customer: '',
            Type: '',           
        }
    }

    isMouseEnter = false;

    getDataStatus = (type) => text => {
        this.setState({[type]: text})
    }
    
    getDataCustomer = (type) => text => {
        this.setState({[type]: text})
    }

    getDataType = (type) => text => {
        this.setState({[type]: text})
    }
   
    setIdShow = (id) => {
        this.setState({idShow: id})
    }

    onSubmit = () => {
        // const { dataStatus, dataCustomer, dataType } = this.state
        // let dataFilter = {}
        // if(dataStatus){
        //     dataFilter['Status'] = dataStatus.type
        //     if(dataStatus === 'Chờ phê duyệt'){
        //         dataFilter.dataStatus = 'Wait'
        //     }else if(dataStatus === 'Đã phê duyệt'){
        //         dataFilter.StatdataStatusus = 'Agree'
        //     }
        // }
        // if(dataCustomer) {
        //     dataFilter['Customer'] = dataCustomer.id
        //     dataFilter.dataCustomer = dataCustomer.id
        // }
        // if(dataType) {
        //     dataFilter['Type'] = dataType.id
        //     dataFilter.dataType = dataType.id
        // }else{
        //     this.props.onClose()
        //     return
        // }       
    }
    
    componentDidMount() {
        // this.props.getAllOffice()
    }

    render() {
        let dataStatus = this.state.dataStatus;
        dataStatus = dataStatus.map(item => ({            
            name: item.status
        }))
        
        let dataCustomer = this.state.dataCustomer
        dataCustomer = dataCustomer.map(item => ({            
            name: item.typeCustom
        }))

        let dataType = this.state.dataType
        dataType = dataType.map(item => ({            
            name: item.status
        }))

        const Status = this.getDataStatus("Status");
        const Customer = this.getDataCustomer("Customer");
        const Type = this.getDataType("Type")
        return (
            <Bound
                onMouseDown={() => {
                    if (!this.isMouseEnter) {
                        this.props.onClose()
                    }
                }}>
                <div
                    className='popup-filter-bound'
                    id='filter_biometric_id'
                    onMouseEnter={() => {
                        this.isMouseEnter = true
                    }}
                    onMouseLeave={() => {
                        this.isMouseEnter = false
                }}>
                    <div className="div_header">
                        <img
                            src={ic_close}
                            alt='close'
                            className='close-btn'
                            onClick={() => {
                            this.props.onClose()
                        }}/>
                        <div onClick={() => this.onSubmit()} className='title'>FILTER</div>
                    </div>
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectDataStatus"
                            getText={Status}
                            data={dataStatus}
                            title='trạng thái dữ liệu'
                            titleChoise='Chờ phê duyệt'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div>
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectCustomer"                            
                            getText={Customer}
                            data={dataCustomer}
                            title='loại khách hàng'
                            titleChoise='Tất cả khách hàng'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div> 
                    <div className="div_check">
                        <SingleChoiseDropDownControl
                            id="selectDataType"                            
                            getText={Type}
                            data={dataType}
                            title='loại dữ liệu'
                            titleChoise='Tất cả loại dữ liệu'                            
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}/>
                    </div>                     
                </div>
            </Bound>
        )
    }
}

