import React, { Component } from 'react';
import styled from 'styled-components';
import ic_close from '../../../../images/ic_close_gray.svg'
import {TabLayout} from '../PopupCheckBiometric/PopupCheckBiometricCustomer'
import ButtonConfirmPopup from '../../MainScreen/Control/ButtonConfirmPopup';
import UserInformation from '../PopupCheckBiometric/UserInformation';

const Bound = styled.div`
  width:613px;
  height: fit-content;
  background-color: #fafafa;
  position: absolute;
  top: 50%;
  left:50%;
  transform:translate(-50%,-50%);
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  border-radius: 10px;
  .header {
    padding: 16px 10px 0 20px;
    width: calc(100% - 30px);
    height: 68px;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #e3e3e3c4;
    .block_header_title {
      display: flex;
      justify-content:space-between;
      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
  }
`

const Header = styled.div`
  width: 100%;
  height: 84px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
`
const Footer = styled.div`
    width: 100%;
    height: 80px;
    background-color: #fafafa;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  .block_btn {
    margin: 0 20px 0 0;
  }
`

export default class PopupCheckCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataUser: this.props.dataUser ? this.props.dataUser : null,
      dataBiometric: this.props.dataBiometric ? this.props.dataBiometric : null
    }
  }
  

  render() {
    let { dataUser } = this.state;
    return (
      <Bound>
        <Header>
        <div className="header">
          <div className="block_header_title">
            <div className="title">
              Thông tin dữ liệu
            </div>
            {/* <img
              onClick={() => this.props.outPopup()}
              src={ic_close} alt="ic_close" /> */}
          </div>
          <TabLayout>
            <div className="title_content">
                <p className='tab_title_single'>Thông tin dữ liệu</p>
            </div>
            {dataUser &&
              <div className="title_info">
                <div className="cif">
                  CIF: <p className='text_cif'> &nbsp;{dataUser.CIF ? dataUser.CIF : null}</p>
                </div>
                <div className="status">
                  Trạng thái: <p className='text_status'> &nbsp;{dataUser.status ? dataUser.status : null}</p>
                </div>
              </div>
            }
              <div className="title_info">
                <div className="cif">
                  CIF: <p className='text_cif'> &nbsp; 02032050560</p>
                </div>
                <div className="status">
                  Trạng thái: <p className='text_status'> &nbsp; Chờ phê duyệt</p>
                </div>
              </div>
          </TabLayout>
        </div>
        </Header>
        <div className="main_container">
            <UserInformation
            typeInternal='ksv'
            />
        </div>
        <Footer>
            <div className="block_btn">
            <ButtonConfirmPopup
              width='80px'
              bg_color='#005993'
              titleConfirm='Đã xem'
              onClick={() => { }}
            />
            </div>
        </Footer>
      </Bound>
    )
  }
}
