import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Images
import ic_close from '../../../images/ic_close_gray.svg';

import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
import { color, users } from '../../../data/store';
import { setPermissionSingleChoise } from '../../../data/singleChoiseData';
import * as userRole from '../../../data/userRole';

//Office
import { getAllOffice } from '../../../actions/officeAction';

const Bound = styled.div`
    padding: 15px 14px 24px 14px;
    background-color: #fafafa;
    width: 440px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .popupHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4{
            font-family: SVN-Gilroy;
            font-size: 20px;
            font-weight: bold;
            color: #000000;    
        } 
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .popupMainBody{
        display:flex;
        padding: 32px 28px 0 28px;
        justify-content: space-between;
        .account {
            width: 100%;
            p.title-account {
                font-family: SVN-Gilroy;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                color: #222222;
            }
            .d-flex{
                display: flex;
                flex-direction: column;
                padding: 5px 0 32px 0;
                label {
                    font-family: SVN-Gilroy;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1.2;
                    padding-top: 10px;
                    span{
                        color: #005993;
                    }
                    span.show-data{
                        padding-left: 7px;
                        color: #222222;
                    }
                }
            }
            .pt-11{
                margin-top: 16px;
                > .singlechoise:nth-child(2){
                    margin-top: 21px;
                }
            }
        }
        .col_input{
            width:49%;
        }
    }
    .col_infoImage{
        float: right;
        padding-right: 26px;
    }
`

class PopupAccountPermission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: setPermissionSingleChoise,
            idShow: '',
            Role: '',
            AtOfficeID: '',
            UID: this.props.dataNeedModify[0]
        }
    }

    setIdShow = (id) => {
        this.setState({ idShow: id })
    }

    onSubmit = () => {
        let { Role, AtOfficeID, UID } = this.state
        if(Role !== '' && AtOfficeID === ''){
            let data = {Role: Role.type, UID: UID}
            this.props.updatePermissionUser(data, UID)
        }else if(AtOfficeID !== '' && Role === ''){
            let data = {AtOfficeID: AtOfficeID.id, UID: UID}
            this.props.updatePermissionUser(data, UID)
        }else if(Role !== '' && AtOfficeID !== ''){
            let data = {Role: Role.type, AtOfficeID: AtOfficeID.id, UID: UID}
            this.props.updatePermissionUser(data, UID)
        }else{
            this.props.outPopup()
            return
        }
        this.props.outPopup()
    }

    getText = (type) => text => {
        this.setState({[type]: text})
    }
    
    getAtOffice = (type) => text => {
        this.setState({[type]: text})
    }
    render() {
        const { dataNeedModify } = this.props
        const Role = this.getText("Role");
        const AtOfficeID = this.getAtOffice("AtOfficeID");
        
        let {listOffice} = this.props.filterOfficeReducer;
          listOffice = listOffice.map((item) => ({
              id: item.ID,
              name: item.Name
          }))
          
        let role = this.state.data.rank
        let listRole = this.state.data.rank
        
        const getDropdownRole = permission => object => 
        object.permissions.includes(permission)
        
        // get Role Permisson have role: OFFADMIN
        const getDropdownRoleOffAdmin = getDropdownRole(userRole.ADMIN_OFFICE_ROLE)
        console.log(userRole.ADMIN_OFFICE_ROLE)
        if(this.props.userReducer.dataLogin.Role === userRole.ADMIN_OFFICE_ROLE){
        role = listRole.filter(getDropdownRoleOffAdmin)
        role.map(item => ({
            type: item.type,
            name: item.name,
            permissions: item.permissions
        }))
        }else{
            role.map(item => ({
                type: item.type,
                name: item.name,
                permissions: item.permissions
            }))
        }

        return (
            <Bound>
                <div className="popupHeader">
                    <h4 className='title'>{this.props.titleHeader}</h4>
                    <span onClick={() => this.props.outPopup()} className='ic_close'></span>
                </div>
                <div className="popupMainBody">
                    <div className='account'>
                        <p className='title-account'>Tài khoản Vietinbank</p>
                        <div className='d-flex'>
                            <label>
                                <span>Họ và tên:</span>
                                <span className='show-data'>{dataNeedModify[1]}</span>
                            </label>
                            <label>
                                <span>Email:</span>
                                <span className='show-data'>{dataNeedModify[2]}</span>
                            </label>
                            <label>
                                <span>Địa điểm công tác:</span>
                                <span className='show-data'>{dataNeedModify[4]}</span>
                            </label>
                        </div>
                        <p className='title-account'>Phân quyền hệ thống</p>
                        <div className='d-flex pt-11'>
                            <SingleChoiseDropDownControl
                                id="selectRole"
                                getText={Role}
                                data={role}
                                title='tài khoản'
                                titleChoise='Chọn loại tài khoản'                            
                                idShow={this.state.idShow}
                                setIdShow={this.setIdShow}
                                defaultValue={dataNeedModify ? dataNeedModify[3] : ''}  

                            />
                            <SingleChoiseDropDownControl
                                id="selectAtOffice"
                                getText={AtOfficeID}
                                data={listOffice}
                                title='điểm giao dịch'
                                titleChoise='Chọn điểm giao dịch'                            
                                idShow={this.state.idShow}
                                setIdShow={this.setIdShow}
                                defaultValue={dataNeedModify ? dataNeedModify[4] : ''}  
                            />
                        </div>
                    </div>

                </div>
                <div className="col_infoImage">
                    <div className='confirmPopup'>
                        <ButtonConfirmPopup
                            titleConfirm='xác nhận'
                            bg_color={color.blue93}
                            onClick={() => this.onSubmit()}
                            dataNeedDelete={this.props.dataNeedDelete}
                        />
                    </div>
                </div>
            </Bound>
        );
    }
    componentDidMount() {
        this.props.getAllOffice()
    }
}

const mapStateToProps = (state) => {
    return {
        filterOfficeReducer: state.filterOfficeReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllOffice: () => dispatch(getAllOffice())
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PopupAccountPermission)

