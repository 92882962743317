import React, { Component } from 'react';
import styled from 'styled-components';
import ic_warning from '../../../../images/Icons_Ticket_Warning.svg';

const Bound = styled.div`
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
   .title {
        font-size: 11px;
        font-weight: bold;
        line-height: 1.26;
        color: #005993;
        text-transform:uppercase;
        margin: 26px 0 14px 0;
    }
    .content {
        font-size: 14px;
        font-weight: 500;
        color: #979797;
        span {
            color:#222222;
        }
        .type_status {
            color: ${props => props.color_type_status};
        }
    }
    .bloc_warning {
      display: flex;
      align-items: center;
      margin: 14px 0 0 0;
      img {
        margin: 0 5px 0 0;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25;
        color: var(--vermillion);
      }
    }
 `

const DataAuth = styled.div`
    margin: 16px 0 0 0;
    .data_auth {
      margin: 0 40px 0 0;
    }
    .data_register {
      .block_img:last-child {
        margin: 0 0 0 5px;
      }
    }
    .block_img {
      box-shadow: 1px solid red;
      width: 70px;
      height:70px;
      padding: 5px;
      background-color: #ffffff;
      border-radius: 5px;
      border:solid 0.5px  ${props => props.color_border || '#c8c8c8' };
      img {
        width: 70px;
        height: 70px;
        object-fit:contain;
      }
    }
  `

const CustomerDuplicate = styled.div`
  .row .content:last-child {
    margin: 16px 0 0 0;
}
 `

export default class DataInfoCheckCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // let dataStatus;

    return (
      <Bound

      >
        <p className='title'>thông tin dữ liệu</p>
        <div className="block_status">
          <div className="row">
            <div className="col-7">
              <div className="row content">
                <p>Trạng thái:&nbsp;
                  <span className='type_status'>Xác thực thành công
                  </span>
                </p>
                {/* <p>GDV yêu cầu: <span>{this.props.registorGDV} </span></p> */}
              </div>
            </div>
            <div className="col-5">
              <div className="row content">
                <p>GDV yêu cầu:&nbsp;
                  <span>Võ Thị Lan Hương
                  </span>
                </p>
                {/* <p>GDV yêu cầu: <span>{this.props.registorGDV} </span></p> */}
              </div>
            </div>
          </div>
        </div>
        <DataAuth>
          <div className="col-7">
            <div className="row">
              <div className="data_auth">
                <div className="block_img auth">
                  {/* <img src={this.props.dataAuth} alt="auth"/> */}
                  <img src='https://picsum.photos/70/70' alt="auth" />
                </div>
                <p className='content'>Xác thực</p>
              </div>
              <div className="data_register">
                <div className="row">
                  <div className="block_img">
                    {/* <img src={this.props.dataRgister} alt="register"/> */}
                    <img src='https://picsum.photos/70/70' alt="register" />
                  </div>
                  <div className="block_img">
                    {/* <img src={this.props.dataRgister} alt="register"/> */}
                    <img src='https://picsum.photos/70/70' alt="register" />
                  </div>
                </div>
                <p className='content'>Đăng ký</p>
              </div>
            </div>
          </div>
        </DataAuth>
        <div className="bloc_warning">
          <img src={ic_warning} alt="ic_warning" />
          <p>Hệ thống ghi nhận vân tay đang trùng khớp với dữ liệu trong hệ thống</p>
        </div>
        <CustomerDuplicate>
          <p className='title'>thông tin khách hàng trùng khớp</p>
          <div className="row">
            <div className="col-7">
              <div className="row content">
                <p>Tên khách hàng:&nbsp;
                  <span className='type_status'>Tran Quang Minh
                  </span>
                </p>
                {/* <p>Tên khách hàng: <span>{this.props.name} </span></p> */}
              </div>
              <div className="row content">
                <p>ID:&nbsp;
                  <span className='type_status'>78462146212
                  </span>
                </p>
                {/* <p>ID: <span>{this.props.ID} </span></p> */}
              </div>
            </div>
            <div className="col-5">
              <div className="row content">
                <p>Số CIF: &nbsp;
                  <span>8402621
                  </span>
                </p>
                {/* <p>Số CIF: <span>{this.props.CIF} </span></p> */}
              </div>
              <div className="row content">
                <p>SĐT: &nbsp;
                  <span>0912596321
                  </span>
                </p>
                {/* <p>SĐT: <span>{this.props.phone} </span></p> */}
              </div>
            </div>
          </div>
        </CustomerDuplicate>
      </Bound>
    )
  }
}
