import React, { Component } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';

//Component
import TransactionCounter from './TransactionCounter';
import EmptyControl from '../Control/EmptyControl'
import SubHeaderControl from '../Control/SubHeaderControl'
//Data
import { emptyData, subHeaderData } from '../../../../data/data'

// action
import { getCounterByOffice, getListTypeCounterServices, genDesktopKey, genTabletKey } from '../../../../actions/counterAction';
import { counterNumber } from '../../../../data/counterNumber';


const Bound = styled.div`
    display:flex;
    flex:1;
    flex-direction:column;
    overflow:auto;
    padding:0 40px;
`

class TransactionPGDContainer extends Component {
  state = {
    subHeaderData: subHeaderData.transactionRoomPGD,
    alertContent: ''
  }

  componentWillMount() {
    let {subHeaderData} = this.state;
    const {dataLogin} = this.props.userReducer;
    subHeaderData = {
      ...subHeaderData,
      mainTitle: dataLogin.OfficeName,
      subTitle: `${dataLogin.OfficeAddress}, phường ${dataLogin.OfficeWard}, Quận ${dataLogin.OfficeDistrict}, TP. ${dataLogin.OfficeProvince}`
    }

    this.setState({subHeaderData})
  }
  

  render() {
    const { total, listCounterNumber } = this.props.counterReducer;
    let elmNumberCounter;
    if (total === counterNumber.length ) {
      elmNumberCounter = this.props.onShowOutOfLimit
    }else {
      elmNumberCounter = this.props.showPopupAddCounter
    }
    return (
      <Bound>
        <SubHeaderControl
          onAddMoreSubmit={elmNumberCounter}
          // onAddMoreSubmit={this.props.showPopupAddCounter}
          data={this.state.subHeaderData}
          counterRemain={counterNumber.length - listCounterNumber.length}
          />
        {
          this.props.counterReducer.listCounter.length>0 ?
            <TransactionCounter
              getCounterByOffice={this.props.getCounterByOffice}
              onShowControl={(offset, from, dataReceive) => {
                this.props.onShowControl(offset, from, dataReceive)
              }}
              data={this.props.counterReducer}
              userReducer={this.props.userReducer}
              genDesktopKey={this.props.genDesktopKey}
              genTabletKey={this.props.genTabletKey}
              />
            :
            <EmptyControl 
                alertContent={this.state.alertContent}
                widthInput={400}
                content={emptyData.counter} />
        }

      </Bound>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.counterReducer.total !== this.props.counterReducer.total){
      // console.log(this.props.counterReducer.total)
      this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID,this.props.counterReducer.total,1, false)
    }
  }

  async componentDidMount() {
    this.props.getCounterByOffice(this.props.userReducer.dataLogin.OfficeID,8,1)
    this.props.getListTypeCounterServices(this.props.userReducer.dataLogin.UID)
  }
}
const mapStateToProps = state => ({
    userReducer: state.userReducer,
    counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
  getCounterByOffice: (officeId,pageSize,pageNumber, isLoad=true) => dispatch(getCounterByOffice(officeId,pageSize,pageNumber, isLoad)),
  getListTypeCounterServices: (userId) => dispatch(getListTypeCounterServices(userId)),
  genDesktopKey: (counterId, officeId) => dispatch(genDesktopKey(counterId, officeId)),
  genTabletKey : (counterId, officeId) => dispatch(genTabletKey(counterId, officeId)),
  
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwarRef: true })(TransactionPGDContainer);