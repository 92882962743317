import React, { Component } from 'react'
import styled from 'styled-components'

// Images
import ic_close from '../../../images/ic_close_gray.svg'
// Components
import InputTextControl from '../../Main/MainScreen/Control/InputTextControl';
import TextAreaControl from '../../Main/MainScreen/Control/TextAreaControl';
import SingleChoiseDropDownControl from '../MainScreen/Control/SingleChoiseDropDownControl';
import ButtonConfirmPopup from '../MainScreen/Control/ButtonConfirmPopup';
import { color } from '../../../data/store';
// import PopupHeader from './PopupHeader'

const Bound = styled.div`
    padding: 15px 14px 24px 14px;
    background-color: #fafafa;
    width: 730px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .popupHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4{
            font-family: SVN-Gilroy;
            font-size: 20px;
            font-weight: bold;
            color: #000000;    
        } 
        .ic_close{
            background-image: url(${ic_close});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width:26px;
            height: 26px;
            cursor: pointer;
        }
    }
    .popupMainBody{
        display:flex;
        padding: 25px;
        justify-content: space-between;
        .col_input{
            width:49%;
        }
        .col_infoImage{
            width:49%;
            position:relative;
            .advertise_img{
            width: 100%;
            height:170px;
            background-image: url('https://picsum.photos/300/200');
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            cursor: pointer;
            display:flex;
            justify-content: center;
            align-items: center;
            transition: .3s all;
            position: relative;
            &::before{
                content: '';
                display: block;
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                transition: .3s all;
            }
            .change{
                font-family: SVN-Gilroy;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                line-height: 1.68;
                color: #ffffff;
                opacity: 0;
                z-index:99;
            }
            &:hover{
                &::before{
                    background-color: rgba(0,0,0,0.5);
                }
                .change{
                    opacity:1;
                }
            }
            }
            .info_img{
                margin: 10px 0 0 0;
                font-family: SVN-Gilroy;
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.42;
                letter-spacing: normal;
                color: #222222;
                .error{
                    margin: 8px 0 0 0;
                }
            }
            .confirmPopup{
                position:absolute;
                bottom:0;
                right:0;
            }
        }
    }
`


const AD_TITLE = "adTitle";
const AD_DESC = "adDesc";
const AD_APPLY = "branch";

const data = [
    "Phòng giao dịch AVBc",
    "tất cả phòng giao dịch"
]

const MILISECOND_TIME = 1000;
export default class PopupAdvertiseBranch extends Component {
    state = {
        adTitle: "",
        adDesc: "",
        branch: "",
        error:''
    }

    formatDuration = (videoInfo) => {
        return new Date(videoInfo.duration * MILISECOND_TIME).toISOString().substr(11, 8);
    }

    getText = (type) => (text) => {
        this.setState({ [type]: text })
    }

    onClickBtn = () => {
        // const formData = new FormData();
        // // Advert name
        // formData.append("name", this.state.adTitle);

        // // Advert description
        // formData.append("desc", this.state.adDesc)

        // // Advert apply
        // formData.append("apply", this.state.branch)

        // // Advert img info
        // formData.append("video", this.props.videoInfo)

        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }

        // const { adTitle, adDesc} = this.state;
        // const {fileData} = this.props;

        // this.props.handleOnSubmit(adTitle, adDesc, fileData, "GDV", true)
    }

    render() {
        const { videoInfo } = this.props;
        const getTextAdTitle = this.getText(AD_TITLE);
        const getTextAdDesc = this.getText(AD_DESC);
        const getTextSingleChoice = this.getText(AD_APPLY);
        return (
            <Bound>
                <div className="popupHeader">
                    <h4 className='title'>{this.props.titleHeader}</h4>
                    <span onClick={() => this.props.outPopup()} className='ic_close'></span>
                </div>

                {/* <PopupHeader
                titleHeader={this.props.titleHeader}
                outPopup={this.props.outPopup()}
                /> */}

                <div className="popupMainBody">
                    <div className="col_input">
                        <div className='input_title'>
                            <InputTextControl
                                title='TÊN QUẢNG CÁO'
                                placeHolder='0% Lãi suất trả góp tại Wall Street'
                                getText={getTextAdTitle} />
                            <TextAreaControl
                                title='MÔ TẢ'
                                placeHolder='Chiến dịch truyền thông tháng 4/2019'
                                getText={getTextAdDesc} />
                            <SingleChoiseDropDownControl
                                titleChoise="Chọn chi nhánh/PGD"
                                data={data}
                                getText={getTextSingleChoice}
                            />
                        </div>
                    </div>
                    <div className="col_infoImage">
                        <div className='advertise_img'>
                            <span className='change'>
                                Thay đổi
                            </span>
                        </div>
                        <div className="info_img">
                            <p className='file_name'>
                                <b>Tên file: </b>
                                {videoInfo.name}</p>
                            <p className='file_time'>
                                <b>Thời lượng:
                                </b>{this.formatDuration(videoInfo)}</p>
                            <p className='file_memory'>
                                <b>Dung lượng: </b>{" " + this.props.formatSize(videoInfo)}
                            </p>
                            <p className='error'>asdasdadadasdadas</p>
                        </div>
                        <div className='confirmPopup'>
                            <ButtonConfirmPopup
                                titleConfirm='TẠO MỚI'
                                bg_color={color.blue93}
                                onClick={this.onClickBtn} />
                        </div>
                    </div>
                </div>
            </Bound>
        )
    }

    componentDidMount() {
        // console.log(this.props.videoInfo)
        document.querySelector(".advertise_img").style.backgroundImage = `url(${this.props.videoInfo.urlImgPreview})`
    }

}