import * as types from "../../actions/types";

const initialState = {
  listCounter: [],
  listTypeCounterServices: [],
  listCounterNumber: [],
  desktopKey: {},
  tabletKey: {},
  err: '',
}

export default function counterReducer(state = initialState, action) {
  switch (action.type) {

    case types.CREATE_COUNTER:
      return {
        ...state,
        err: ''
      }
    case types.CREATE_COUNTER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.UPDATE_COUNTER:
      return {
        ...state,
        err: ''
      }
    case types.UPDATE_COUNTER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GET_COUNTER_BY_OFFICE:
      return {
        ...state,
        listCounter: action.listCounter,
        total: action.total,
        err: ''
      }
    case types.GET_COUNTER_BY_OFFICE_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.DELETE_COUNTER:
      return {
        ...state,
        err: ''
      }
    case types.DELETE_COUNTER_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GEN_DESKTOP_KEY:
      return {
        ...state,
        desktopKey: action.data,
        err: ''
      }
    case types.GEN_DESKTOP_KEY_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GEN_TABLET_KEY:
      return {
        ...state,
        tabletKey: action.data,
        err: ''
      }
    case types.GEN_TABLET_KEY_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GET_LIST_TYPE_COUNTER_SERVICES:
      return {
        ...state,
        listTypeCounterServices: action.listService,
        err: ''
      }
    case types.GET_LIST_TYPE_COUNTER_SERVICES_ERR:
      return {
        ...state,
        err: action.err
      }
    case types.GET_LIST_COUNTER_NUMBER:
      return {
        ...state,
        listCounterNumber: action.listCounterNumber,
        err: ''
      }
    case types.GET_LIST_COUNTER_NUMBER_ERR:
      return {
        ...state,
        err: action.err
      }
    default:
      return state
  }
}